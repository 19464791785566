<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_distributionDistributionInventoryConfirmationByProcess" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="searchParam.suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="getSupplierName"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
                :rules="[rules.supplierRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 商品コード/品名-->
            <div class="search-autocomplete">
              <v-autocomplete
                v-model="searchParam.itemStandardSid"
                item-value="value"
                item-text="text"
                :items="productList"
                :hint="productName"
                persistent-hint
                maxlength="100"
                :search-input.sync="itemCdInputText"
                dense
                :disabled="
                  searchParam.suppliersSelected == null || searchParam.suppliersSelected == ''
                "
                :label="$t('label.lbl_productCnCd')"
              >
                <option disabled value="itemStandardSid"></option>
                <!-- アイテム一覧の表示  -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- ロット -->
            <div class="lot-width">
              <v-text-field
                outlined
                dense
                v-model="searchParam.lotNo"
                maxlength="20"
                :label="$t('label.lbl_lot')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- S/N -->
            <div class="lot-width">
              <v-text-field
                outlined
                dense
                v-model="searchParam.serial"
                maxlength="32"
                :label="$t('label.lbl_serial')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 期限日 -->
            <div class="lot-width">
              <v-text-field
                outlined
                dense
                v-model="searchParam.dueDate"
                maxlength="8"
                :label="$t('label.lbl_dueDate')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 付属キー1 -->
            <div class="lot-width">
              <v-text-field
                outlined
                dense
                v-model="searchParam.lotSubkey1"
                maxlength="20"
                :label="$t('label.lbl_lotSubkey1')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 付属キー2 -->
            <div class="lot-width">
              <v-text-field
                outlined
                dense
                v-model="searchParam.lotSubkey2"
                maxlength="20"
                :label="$t('label.lbl_lotSubkey2')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
          </v-row>

          <v-row>
            <!-- 在庫プルダウン -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="searchParam.inventoryDiv"
                item-value="value"
                item-text="text"
                :items="inventoryList"
                :label="$t('label.lbl_strageQty')"
                :hint="inventory"
                class="txt-singles"
                persistent-hint
                :search-input.sync="inventoryInputText"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 警告表示 -->
            <div class="serch-textbox-arrivalNo">
              <v-autocomplete
                dense
                v-model="searchParam.alert"
                item-value="value"
                item-text="text"
                :items="alertList"
                :label="$t('label.lbl_alert')"
                :hint="alertMessage"
                class="txt-singles"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn class="get-btn" @click="getList()">{{ $t("btn.btn_search") }}</v-btn>

                <!--クリアボタン-->
                <v-btn class="get-btn" @click="openClearDialog()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-search-area" style="float: right">
                <!--発注点警告CSV出力ボタン-->
                <v-btn
                  class="other-btn-nosize get-btn-csv"
                  @click="download()"
                  :disabled="isCanDownloadFlg"
                  >{{ $t("btn.btn_material_order_csv_output") }}</v-btn
                >
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :items-per-page="-1"
          :hide-default-footer="true"
          disable-pagination
          disable-filtering
          disable-sort
          height="620px"
          class="table-style"
        >
          <!-- 品番カラム -->
          <template v-slot:[`item.itemCd`]="{ item }">
            <td class="data-row-align-left">
              {{ item.itemCd }}
              <br />
              {{ item.itemName }}
            </td>
          </template>

          <!-- 警告カラム -->
          <template v-slot:[`item.alert`]="{ item }">
            <td class="alert-message">{{ item.alert }}</td>
          </template>

          <!-- 各工程 -->
          <template v-for="proc in procDataList" v-slot:[`item.stock_${proc}`]="{ item }">
            <div :key="proc">
              <template v-if="item['stock_' + proc] > 0">
                <a @click="showDialog(proc, item.itemStandardSid)">
                  <div>{{ formatToCurrency(item["stock_" + proc]) }}</div>
                </a>
              </template>
              <template v-else>
                <div class="quentity-zero">
                  {{ formatToCurrency(item["stock_" + proc]) }}
                </div>
              </template>
            </div>
          </template>
        </v-data-table>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />

      <!-- 進捗情報詳細モーダル-->
      <v-dialog v-model="detailDialog" persistent :max-width="detailDialogSize">
        <v-card class="dialog-card">
          <v-card-title class="title-ber dialog">
            <span class="text-h5">詳細</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-data-table
                  id="detailDialogListData"
                  fixed-header
                  :items-per-page="-1"
                  :hide-default-footer="true"
                  disable-pagination
                  disable-filtering
                  disable-sort
                  :headers="detailDialogHeader"
                  :items="detailDialogData"
                  height="300px"
                  class="table-style"
                >
                  <!-- ヘッダー -->
                  <template v-slot:[`header.lotNo`]>
                    {{ $t("label.lbl_lot") + "／" + $t("label.lbl_serial") }}<br />
                    {{
                      $t("label.lbl_dueDate") +
                      "／" +
                      $t("label.lbl_lotSubkey1") +
                      "／" +
                      $t("label.lbl_lotSubkey2")
                    }}<br />
                  </template>

                  <!-- 明細 -->
                  <!-- ロット -->
                  <template v-slot:[`item.lotNo`]="{ item }">
                    <td>
                      <!-- ロットを表示 -->
                      <div v-if="item.lotNo.length > 15 || item.dueDate.length > 15">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="text-truncate"
                              style="max-width: 350px"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ item.lotNo }}<br />
                              {{ item.dueDate }}
                            </div>
                          </template>
                          <span>{{ item.lotNo }}<br />{{ item.dueDate }}</span>
                        </v-tooltip>
                      </div>
                      <!-- ロットを表示 -->
                      <div v-else>
                        <span>{{ item.lotNo }}<br />{{ item.dueDate }}</span>
                      </div>
                    </td>
                  </template>
                </v-data-table>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="dialog-get-btn" text @click="closeDetailDialog">
              {{ $t("btn.btn_close") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import ConfirmDialog from "@/components/ConfirmDialog";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";

export default {
  name: appConfig.SCREEN_ID.P_TMP_913,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ヘッダ
    // 検索ヘッダー
    searchParam: {
      suppliersSelected: "",
      itemStandardSid: "",
      inventoryDiv: "",
      lotNo: "",
      serial: "",
      dueDate: "",
      lotSubkey1: "",
      lotSubkey2: "",
      alert: "",
    },

    // 取引先
    supplierList: [],

    // 品番
    itemCdInputText: "",
    productList: [],

    // ロット
    lotNo: "",

    // 在庫プルダウン
    inventoryList: [],
    inventoryInputText: "",

    // 警告表示
    alertList: [],

    // 一覧表示用
    totalCount: 0,
    headerItems: [],
    inputList: [],
    headerRespose: [],

    // バリデーション
    rules: {
      supplierRequired: (value) => !!value || "取引先を入力してください",
    },

    // その他ダイアログ
    detailDialog: false,
    detailDialogHeader: [],
    detailDialogData: [],
    detailTotalCount: 0,
    detailDialogSize: 0,

    // 確認メッセージ
    ConfirmDialog: {
      isOpen: false,
      message: "",
      okAction: () => {},
      redMessage: "",
      screenFlag: false,
    },

    openMenu: false,
    loadingCounter: 0,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
  }),
  methods: {
    /** 初期化処理 */
    init() {
      if (this.$route.params.searchParam) {
        this.searchParam = this.$route.params.searchParam;
        this.getSupplier();
        this.getList();
        return;
      }
      this.searchParam.suppliersSelected = "";
      this.searchParam.inventoryDiv = "";
      this.getMstCode();
      this.getSupplier();
      this.getList(true);
    },

    /** データテーブル関連 */
    /** 表示用データ取得 */
    getList(headerOnlyFlg = false) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // バリデーションチェック
      // 明細行を取得するパターンでかつ、バリデーションエラーの場合は処理しない
      if (!headerOnlyFlg && !this.$refs.form.validate()) {
        this.loadingCounter = 0;
        return;
      }

      // API接続用のconfigを取得
      const config = this.$httpClient.createGetApiRequestConfig();

      // パラメータ設定
      this.setParam(config, headerOnlyFlg);

      // 各工程の予定、実績を取得
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.DST_INVENTORY_PROCESS_CONFIRM_LIST, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == "000") {
              // ヘッダー作成用
              this.createHeader(jsonData.resIdv.procCdList);
              // データテーブル用
              if (jsonData.resIdv.orderPointList) {
                this.totalCount = 0;
                this.inputList = [];

                this.inputList = jsonData.resIdv.orderPointList.map((row) => {
                  let stock = {};
                  stock.itemStandardSid = row.itemStandardSid;
                  stock.itemCd = row.itemCd;
                  stock.itemName = row.itemName;
                  stock.orderPoint = row.orderPoint
                    ? this.formatToCurrency(Number(row.orderPoint))
                    : "";
                  stock.alert = row.alert;
                  stock.safetyStockQuantity = row.safetyStockQuantity
                    ? this.formatToCurrency(Number(row.safetyStockQuantity))
                    : "";
                  let procStockList = {};
                  row.stockList.forEach((procData) => {
                    procStockList["stock_" + procData.procCd] = procData.stockQuantity;
                  });
                  stock = {
                    ...stock,
                    ...procStockList,
                  };
                  return stock;
                });
              }
              this.totalCount = jsonData.resCom.resComCount;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getList() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /** パラメータ設定 */
    setParam(config, headerOnlyFlg = false) {
      config.params.clientSid = this.searchParam.suppliersSelected; // 取引先
      if (this.searchParam.itemStandardSid) {
        config.params.itemStandardSid = this.searchParam.itemStandardSid; // 品番
      }
      if (this.checkLot(this.searchParam)) {
        config.params.lotNo = this.createLot(this.searchParam); // ロットNo
      }
      if (this.searchParam.inventoryDiv) {
        config.params.inventoryDiv = this.searchParam.inventoryDiv; // 在庫区分
      }
      if (this.searchParam.alert) {
        config.params.alertDiv = this.searchParam.alert; // 警告
      }
      if (headerOnlyFlg) {
        config.params.headerOnlyFlg = headerOnlyFlg; // ヘッダーのみ取得フラグ
      }
    },

    /** 画面クリアダイアログオープン */
    openClearDialog() {
      // 確認ダイアログを表示
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.okAction = this.clear;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
    },

    /** クリアボタン */
    clear() {
      this.searchParam.suppliersSelected = "";
      this.searchParam.inventoryDiv = "";
      this.searchParam.itemStandardSid = "";
      this.searchParam.lotNo = "";
      this.searchParam.serial = "";
      this.searchParam.dueDate = "";
      this.searchParam.lotSubkey1 = "";
      this.searchParam.lotSubkey2 = "";
      this.searchParam.alert = "";
      this.itemCdInputText = "";
      this.inventoryInputText = "";
      this.productList = [];
      this.totalCount = 0;
      this.inputList = [];
      this.$refs.form.resetValidation();
    },

    /** ヘッダー作成 */
    createHeader(headerRespose) {
      this.headerItems = [
        // 品番
        {
          text: i18n.tc("label.lbl_productCnCd"),
          value: "itemCd",
          width: "20%",
          align: "left",
          sortable: true,
        },
        // 発注点
        {
          text: i18n.tc("label.lbl_orderingPoint"),
          value: "orderPoint",
          width: "8%",
          align: "right",
          sortable: true,
        },
        // 警告
        {
          text: i18n.tc("label.lbl_alert"),
          value: "alert",
          width: "7%",
          align: "left",
          sortable: true,
        },
        // 推奨発注数
        {
          text: i18n.tc("label.lbl_safetyStock"),
          value: "safetyStockQuantity",
          width: "12%",
          align: "right",
          sortable: true,
        },
      ];

      this.headerRespose = headerRespose;
      headerRespose.forEach((proc) => {
        this.headerItems.push({
          text: proc.procNameShort,
          value: "stock_" + proc.procCd,
          width: "70px",
          align: "right",
          sortable: false,
        });
      });
    },

    /** 取引先API接続 */
    getSupplier() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getSupplier() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  compSid: row.compSid,
                });
              });
              this.supplierList = list;
              // 取引先が1件の場合、取引先を自動選択する
              this.supplierList.length == 1
                ? (this.searchParam.suppliersSelected = this.supplierList[0].value)
                : "";
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getSupplier() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /** 詳細ダイアログ表示処理 */
    showDialog(procCd, itemStandardSid) {
      this.detailDialog = true;
      this.detailDialogHeader = [
        // 伝票No
        {
          text: i18n.tc("label.lbl_billingSlipNo"),
          value: "listNo",
          width: "15%",
          align: "left",
          sortable: true,
        },
        // 明細No
        {
          text: i18n.tc("label.lbl_detailNo"),
          value: "listSubNo",
          width: "11%",
          align: "right",
          sortable: true,
        },
        // 品質区分
        {
          text: i18n.tc("label.lbl_arrivalStatus"),
          value: "qualityDiv",
          width: "11%",
          align: "left",
          sortable: true,
        },
        // ロット
        {
          value: "lotNo",
          width: "20%",
          align: "left",
          sortable: true,
        },
        // 管理No
        {
          text: i18n.tc("label.lbl_managementNo"),
          value: "itemManageNo",
          width: "10%",
          align: "left",
          sortable: true,
        },
        // 数量
        {
          text: i18n.tc("label.lbl_quantity"),
          value: "quantity",
          width: "8%",
          align: "right",
          sortable: true,
        },
        // 入庫日
        {
          text: i18n.tc("label.lbl_enteringDate"),
          value: "receivingStockDate",
          width: "10%",
          align: "left",
          sortable: true,
        },
      ];
      if (procCd == 300) {
        this.detailDialogHeader = [
          ...this.detailDialogHeader,
          // 倉庫
          {
            text: i18n.tc("label.lbl_warehouse"),
            value: "warehouse",
            width: "15%",
            align: "left",
            sortable: true,
          },
          // ロケNo
          {
            text: i18n.tc("label.lbl_locationNo"),
            value: "locationNo",
            width: "13%",
            align: "left",
            sortable: true,
          },
        ];
        this.detailDialogHeader.splice(0, 2);
      }

      this.getDetailList(procCd, itemStandardSid);
    },

    /** 詳細ダイアログ表示データ取得 */
    getDetailList(procCd, itemStandardSid) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // dialogのサイズを設定
      if (procCd == 300) {
        this.detailDialogSize = 1500;
      } else {
        this.detailDialogSize = 1400;
      }

      // パラメータ設定
      const config = this.$httpClient.createGetApiRequestConfig();
      this.setDetailParam(config, procCd, itemStandardSid);

      // 各工程の予定、実績を取得
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.DST_INVENTORY_PROCESS_CONFIRM_DETAIL_LIST, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == "000") {
              // データテーブル用
              if (jsonData.resIdv.stockDetailList.length == 0) {
                this.detailTotalCount = 0;
                this.detailDialogData = [];
                return;
              }

              this.detailDialogData = jsonData.resIdv.stockDetailList.map((v) => {
                const lot = commonUtil.convertLotNo(v.lotNo);
                return {
                  qualityDiv: v.qualityDiv,
                  lotNo: lot.lot,
                  dueDate: lot.dueDate,
                  itemManageNo: v.itemManageNo,
                  quantity: this.formatToCurrency(Number(v.quantity)),
                  listNo: v.listNo,
                  listSubNo: v.listSubNo,
                  receivingStockDate: v.receivingStockDate,
                  warehouse: v.warehouse,
                  locationNo: v.locationNo,
                };
              });
              this.detailTotalCount = jsonData.resCom.resComCount;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getList() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /** パラメータ設定 */
    setDetailParam(config, procCd, itemStandardSid) {
      config.params.procCd = procCd; // 工程コード
      config.params.itemStandardSid = itemStandardSid; // 品番
      if (this.checkLot(this.searchParam)) {
        config.params.lotNo = this.createLot(this.searchParam); // ロット
      }
    },

    /** ダイアログ閉じる */
    closeDetailDialog() {
      this.detailDialog = false;
      this.detailDialogData = [];
      this.detailTotalCount = 0;
    },

    /** CSV出力 */
    download(headerOnlyFlg = false) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // バリデーションチェック
      // 明細行を取得するパターンでかつ、バリデーションエラーの場合は処理しない
      if (!headerOnlyFlg && !this.$refs.form.validate()) {
        this.loadingCounter = 0;
        return;
      }
      // パラメータ設定
      const body = this.setPostParam();

      // 各工程の予定、実績を取得
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.DST_ORDER_POINT_WARNING_LIST_DOWNLOAD, body)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == "000") {
              // ダウンロードURLを取得
              window.location.href = jsonData.resIdv.downloadUrl;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getList() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /** Postパラメータセット */
    setPostParam() {
      const body = this.$httpClient.createRequestBodyConfig();
      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TMP_913;
      body.reqIdv.clientSid = this.searchParam.suppliersSelected; // 取引先
      if (this.searchParam.itemStandardSid) {
        body.reqIdv.itemStandardSid = this.searchParam.itemStandardSid; // 品番
      }
      if (this.checkLot(this.searchParam)) {
        body.reqIdv.lotNo = this.createLot(this.searchParam); // ロットNo
      }

      return body;
    },

    /**
     * lotNoの内容が一部でも入力されているかチェック
     */
    checkLot(param) {
      if (param.lotNo || param.serial || param.dueDate || param.lotSubkey1 || param.lotSubkey2) {
        return true;
      }
      return false;
    },

    /**
     * 分割されているlotNoの構築
     */
    createLot(item) {
      return commonUtil.createLotNo(
        item.lotNo,
        item.serial,
        item.dueDate,
        item.lotSubkey1,
        item.lotSubkey2
      );
    },

    /** マスタ取得 */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 在庫区分
      const inventoryDiv = getParameter.getCodeMst(appConfig.CODETYPE.INVENTORY_DIV);
      // 警告
      const alertDiv = getParameter.getCodeMst(appConfig.CODETYPE.ALERT_DIV);

      Promise.all([inventoryDiv, alertDiv])
        .then((result) => {
          // 画面の初期値を設定します。
          this.inventoryList = result[0];
          this.alertList = result[1];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /** フォーマット */
    formatToCurrency(value) {
      return commonUtil.formatToCurrency(value);
    },
  },

  watch: {
    /**
     * 品番入力
     */
    itemCdInputText(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (
          this.searchParam.suppliersSelected == null ||
          this.searchParam.suppliersSelected == ""
        ) {
          return;
        }
        // 表示内容が選択した内容と一致している場合は、確定した内容なので変更しない
        let product = this.productList.find((v) => v.value == this.searchParam.itemStandardSid);
        if (product != null && val == product.text) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.searchParam.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.productList = list.concat(result[0]);
              this.productList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.productList.length = 0;
        }
      }
    },
  },
  computed: {
    // 取引先名
    getSupplierName() {
      let supplier = this.supplierList.find((v) => v.value === this.searchParam.suppliersSelected);
      if (supplier) {
        return supplier.name;
      }
      return "";
    },
    // 在庫
    inventory() {
      let inventory = this.inventoryList.find((v) => v.value === this.searchParam.inventoryDiv);
      if (inventory) {
        return inventory.text;
      }
      return "";
    },
    // 品番
    productName() {
      if (this.searchParam.suppliersSelected == null || this.searchParam.suppliersSelected == "") {
        return "取引先が選択されていません";
      }

      let prodct = this.productList.find((v) => v.value === this.searchParam.itemStandardSid);
      if (prodct) {
        return prodct.name;
      }
      return "";
    },
    alertMessage() {
      let alert = this.alertList.find((v) => v.value === this.searchParam.alert);
      if (alert) {
        return alert.text;
      }
      return "";
    },
    /** 各工程の工程コードを動的に変更するための工程リストを取得 */
    procDataList() {
      let keys = this.inputList.flatMap((v) => Object.keys(v));
      let keySet = new Set(keys);
      return [...keySet].filter((v) => v.startsWith("stock_")).map((v) => v.replace("stock_", ""));
    },
    isCanDownloadFlg() {
      const isCanDownloadFlg = this.inputList.length != 0 && this.searchParam.inventoryDiv != "02";
      return !isCanDownloadFlg;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}

.table-style {
  overflow-x: auto;
}

.proc-width {
  width: 100px;
}

.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}
.marginRightNone {
  margin-right: 0px;
}

.title-ber.dialog {
  background-color: #dee6f3;
  font-weight: bold;
}

//データテーブル
#detailDialogListData::v-deep {
  width: 100%;
  .v-data-table__wrapper {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }
    //テーブルデータ部分
    td {
      font-size: large;
    }
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2.5rem !important;
    }
  }
}

.data-row-align-right {
  text-align: right;
  padding-right: 3em;
}

.dialog-card > *:not(:last-child) {
  margin-bottom: 5px;
}

.dialog-get-btn::v-deep {
  margin: 0 0 0 12px;
  width: 7rem;
  background-color: #1976d2;
  color: #ffffff;
}

.alert-message {
  color: red;
  font-weight: bold;
}

.lot-width {
  width: 15rem;
}

.quentity-zero {
  color: rgb(158, 158, 158);
}

.digalog {
  max-width: 1500px;
}

.get-btn-csv {
  width: 10rem;
}
</style>
