import { appConfig } from "../assets/scripts/js/AppConfig";
import { i18n } from "../lang/lang.js";

export default {
  data: () => ({
    // 現在の検索条件
    searchFilter: {},
    // 前画面の検索条件
    previousSearchFilter: {},
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      isOpen: false,
      screenFlag: false,
      redMessage: "",
      title: "",
      okAction: () => {},
    },
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      screenFlag: false,
      changeFlag: false,
      homePageFlag: false,
      firstPageFlag: false,
      rcvSchListFlg: false,
      rcvSchAddFlg: false,
      shpSchListFlg: false,
      shpSchAddFlg: false,
      itemsMstFlg: false,
      belongMstFlg: false,
      outsideClickNotCloseFlg: false,
      sevenPageFlag: false,
      locaMstFlgg: false,
      simpleDialog: false,
      shipTruckNumAddPageFlag: false,
    },
  }),
  methods: {
    importExcel(importFile) {
      return new Promise((resolve, reject) => {
        // 選択したファイルを読み込む
        const excelFile = importFile;

        // 拡張子チェック
        let fileType = excelFile.type;
        if (
          fileType != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
          fileType != "application/vnd.ms-excel"
        ) {
          this.infoDialog.message = i18n.tc("check.chk_limitExcelFileType");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }

        // ファイル名の桁数をチェック
        if (excelFile.name.substring(0, excelFile.name.indexOf(".")).length > 31) {
          this.infoDialog.message = i18n.tc("check.chk_limitFileName");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }
        // サイズチェック
        if (importFile.size <= 0) {
          this.infoDialog.message = i18n.tc("check.chk_limitFileSizeMin");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }
        if (importFile.size >= 3000000) {
          this.infoDialog.message = i18n.tc("check.chk_limitFileSizeMax");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }

        const xlsx = require("xlsx");
        var reader = new FileReader();
        reader.readAsArrayBuffer(excelFile);

        reader.onload = (event) => {
          var lines = event.target.result;
          var wb;
          var arr = fixdata(lines);
          let chengeArrangement = {};
          var dataList = [];
          var excelDataList = [];
          wb = xlsx.read(btoa(arr), {
            type: "base64",
            cellDates: true,
          });

          // シート数の取得
          this.sheetNum = wb.SheetNames.length;
          // シート名の取得
          this.sheetInfo = wb.SheetNames;
          // シート領域の取得
          this.sheetClmNum = wb.Sheets;
          for (let i = 0; i < wb.SheetNames.length; i++) {
            var sheet = wb.SheetNames;
            // REVIEW:データ領域が取得できない場合
            var range = wb.Sheets[sheet[i]]["!ref"];
            // if (range == undefined) {
            //   // サイズチェック
            //  checkImportFile();
            //   return;
            // }

            // REVIEW:書式が入っている場合!refの参照が入っている場合があるため最初セルのプロパティの存在確認をする
            // var firstCell = range.substr(0, range.indexOf(":"));
            // if (wb.Sheets[sheet[i]][firstCell] == undefined) {
            //   checkImportFile(excelDataList);
            //   return;
            // }

            // REVIEW：データ領域の取得
            var endCel = range.substr(range.indexOf(":") + 1);
            const cellInfo = xlsx.utils.decode_cell(endCel);
            const endRow = cellInfo.r;
            const endClm = cellInfo.c;

            for (let row = 0; row <= endRow; row++) {
              for (let clm = 0; clm <= endClm; clm++) {
                const address = xlsx.utils.encode_cell({ r: row, c: clm });
                const cell = wb.Sheets[wb.SheetNames[i]][address];
                // 空白の場合は、ブランクを設定
                if (!cell) {
                  wb.Sheets[wb.SheetNames[i]][address] = {
                    t: "s",
                    v: "",
                    r: '<t></t><phoneticPr fontId="1"/>',
                    h: "",
                    w: "",
                  };
                } else if (cell.w == "#VALUE!") {
                  wb.Sheets[wb.SheetNames[i]][address] = {
                    t: "s",
                    v: "#VALUE!",
                    r: '<t>#VALUE!</t><phoneticPr fontId="1"/>',
                    h: "#VALUE!",
                    w: "#VALUE!",
                  };
                } else if (cell.t != "s") {
                  // 書式チェックに文字列以外の場合はエラー
                  this.infoDialog.message = i18n.tc("check.chk_excelFileCellTypeCheck");
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  this.infoDialog.firstPageFlag = true;
                  return;
                }
              }
            }

            this.output = to_json(wb); // JSON形式のデータを格納
          }

          // データ解析
          for (let i = 0; i < this.sheetNum; i++) {
            for (let j = 0; j < this.output[this.sheetInfo[i]].length; j++) {
              chengeArrangement = this.output[this.sheetInfo[i]][j];
              dataList = Object.values(chengeArrangement);
              excelDataList.push(dataList);
            }
          }

          // ファイル取込件数チェック
          if (excelDataList.length == 0) {
            this.infoDialog.message = i18n.tc("check.chk_emptyFile");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }

          resolve(excelDataList);
        };

        // データ読込
        function fixdata(lines) {
          var o = "",
            l = 0,
            w = 10240;
          for (; l < lines.byteLength / w; ++l) {
            o += String.fromCharCode.apply(null, new Uint8Array(lines.slice(l * w, l * w + w)));
          }
          o += String.fromCharCode.apply(null, new Uint8Array(lines.slice(l * w)));
          return o;
        }

        // JSON形式のデータに変換する
        function to_json(data) {
          var result = {};
          data.SheetNames.forEach(function (sheetName) {
            var roa = xlsx.utils.sheet_to_json(data.Sheets[sheetName], {
              raw: true,
            });
            if (roa.length >= 0) {
              result[sheetName] = roa;
            }
          });
          return result;
        }
        // すでに取込したエクセルファイルを変更してそのまま取込した場合
        reader.onerror = (e) => {
          console.log("reader.error: " + reader.error);
          if (e.target.error != null) {
            this.infoDialog.message = i18n.tc("check.chk_reSelectExcel");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            this.importfile = null;
          }
          reject();
        };
      });
    },
  },
};
