<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_distributionInstructionsCreation" />
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-autocomplete-supplier first-search-item" style="width: 500px">
              <!-- 取引先-->
              <v-text-field
                dense
                id="get-supplier"
                v-model="suppliersSelectedName"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                persistent-hint
                readonly
              ></v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="allocationUnit"
                :items="allocationUnitList"
                :label="$t('label.lbl_allocation_unit')"
                :hint="allocationUnitName"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
                :rules="[rules.supplierRequired]"
                @change="allAllocationUnitChange"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="allocationOrder"
                :items="allocationOrderList"
                :label="$t('label.lbl_allocation_order')"
                :hint="allocationOrderName"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
                :rules="[rules.supplierRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
          </v-row>

          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--戻るボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_back')">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>
              <div class="btn-area">
                <!--登録ボタン-->
                <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_insert')">{{
                  $t("btn.btn_insert")
                }}</v-btn>
              </div>
            </div>
          </v-row>

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>
              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!-- 表示件数切り替えボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :ripple="false"
          disable-filtering
          :hide-default-footer="true"
          height="600px"
          :sort-by.sync="sortBy"
          :page.sync="page"
          :items-per-page="itemsPerPage"
        >
          <!-- 計画日 or 入荷予定日 or 出荷予定日 -->
          <template v-slot:[`header.processingDate`]="{}">
            <span v-text="processingDateLabel"></span>
          </template>

          <!-- ロットNoヘッダー -->
          <template v-slot:[`header.lotNo`]>
            {{ $t("label.lbl_lot") + "／" + $t("label.lbl_serial") }}<br />
            {{
              $t("label.lbl_dueDate") +
              "／" +
              $t("label.lbl_lotSubkey1") +
              "／" +
              $t("label.lbl_lotSubkey2")
            }}<br />
          </template>

          <!-- 作業指示チェックボックス -->
          <template v-slot:[`item.createDataCheck`]="{ item }">
            <input
              type="checkbox"
              v-model="item.createDataCheck"
              :value="listCheckbox"
              style="transform: scale(2)"
              @change="checkAllocationUnit(item)"
            />
          </template>

          <!-- 計画日 or 入荷予定日 or 出荷予定日 -->
          <template v-slot:[`item.processingDate`]="{ item }">
            <span v-text="item.processingDate"></span>
          </template>

          <!-- 伝票No-->
          <template v-slot:[`item.voucherNo`]="{ item }">
            <span v-text="item.voucherNo"></span>
          </template>

          <!-- 品番/品名 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <div v-if="item.productName.length > 10">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" v-bind="attrs" v-on="on">
                    {{ item.productCnCd }}
                  </div>
                  <div class="text-overflow">
                    {{ item.productName }}
                  </div>
                </template>
                <div>{{ item.productCnCd }}</div>
                <div>{{ item.productName }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <div>{{ item.productCnCd }}</div>
              <div>{{ item.productName }}</div>
            </div>
          </template>

          <!-- 付属品番 -->
          <template v-slot:[`item.accessoryCd`]="{ item }">
            <span v-text="item.accessoryCd"></span>
          </template>

          <!-- ロット -->
          <template v-slot:[`item.lotNo`]="{ item }">
            <!-- ロットを表示 -->
            <div v-if="item.lotNo.length > 15 || item.dueDate.length > 15">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" style="max-width: 350px" v-bind="attrs" v-on="on">
                    {{ item.lotNo }}<br />
                    {{ item.dueDate }}
                  </div>
                </template>
                <span>{{ item.lotNo }}<br />{{ item.dueDate }}</span>
              </v-tooltip>
            </div>
            <!-- serialが15文字以下だった場合ツールチップ無しで表示 -->
            <div v-else>
              {{ item.lotNo }}<br />
              {{ item.dueDate }}
            </div>
          </template>

          <!-- 予定数量 -->
          <template v-slot:[`item.scheduleQuantity`]="{ item }">
            <!-- 予定数量を表示 -->
            <span class="number_data" v-text="item.scheduleQuantity"></span>
          </template>

          <!-- 加工済在庫数 -->
          <template v-slot:[`item.processedStockQty`]="{ item, index }">
            <span class="number_data">
              <!-- 入荷の場合は加工済在庫数の使用ができないためリンクではなく通常のテキスト表示にする-->
              <template v-if="PLAN_DIV.IN == searchParam.searchPlanningSection">
                <span>
                  <!-- 加工済在庫数を表示 -->
                  {{ item.processedStockQty }}
                </span>
              </template>
              <template v-else>
                <a
                  @click="openProcessedInventoryData(item, index)"
                  style="text-decoration: underline"
                >
                  <!-- 加工済在庫数を表示 -->
                  {{ item.processedStockQty }}
                </a>
              </template>
            </span>
          </template>

          <!-- 加工済使用数 -->
          <template v-slot:[`item.processedUsedQty`]="{ item }">
            <span class="number_data">
              <!-- 加工済使用数を表示 -->
              {{ item.processedUsedQty }}
            </span>
          </template>

          <!-- 加工種類区分 -->
          <template v-slot:[`item.processingKindsDivName`]="{ item }">
            <!-- 加工種類区分を表示 -->
            <span v-text="item.processingKindsDivName"></span>
          </template>
          <!-- 工程選択ボタン -->
          <template v-slot:[`item.makeInstructions`]="{ item, index }">
            <template
              v-if="
                !(PLAN_DIV.IN == searchParam.searchPlanningSection || item.processedUsedQty != 0)
              "
            >
              <v-btn small @click="openMakeInstructions(item, index)" text class="pen-icon">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </template>

          <!-- 工程 -->
          <template v-slot:[`item.optHistList`]="{ item }">
            <v-container class="d-flex justify-space-around opt-hist-list-container">
              <template v-for="(optHist, index) in fixedOptHistOrder">
                <template v-if="inputList.some((obj) => obj.optHistList.includes(optHist.procCd))">
                  <!-- 工程を表示 -->
                  <div
                    class="flex-item"
                    v-text="item.optHistList.includes(optHist.procCd) ? optHist.procCdName : ''"
                    :key="index"
                  ></div>
                </template>
              </template>
              <template v-for="(optHist, index) in fixedOptHistOrder">
                <template v-if="!inputList.some((obj) => obj.optHistList.includes(optHist.procCd))">
                  <div class="flex-item" :key="index"></div>
                </template>
              </template>
            </v-container>
          </template>
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="Math.ceil(inputList.length / itemsPerPage)"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :cntapprovalFlg="infoDialog.cntapprovalFlg"
      />
      <!-- 登録ボタン押下時確認ダイアログ -->
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :screenFlag="confirmDialog.screenFlag"
        :changeFlag="confirmDialog.changeFlag"
      />
      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 加工済在庫一覧ポップアップ-->
      <v-dialog
        v-model="dialogProcessedInventoryList"
        :max-width="1500"
        persistent=""
        no-click-animation
        scrollable
      >
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_processedInventoryList") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="processedProductCnCd"
                    :label="$t('label.lbl_productCnCd')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    v-model="processedAccessoryCd"
                    :label="$t('label.lbl_accessoryCd')"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!--ボタン領域-->
              <div class="search-row-exeBtn">
                <!-- 閉じるボタン領域 -->
                <div class="btn-search-area" style="float: left">
                  <v-btn class="other-btn" @click="close">
                    {{ $t("btn.btn_close") }}
                  </v-btn>
                </div>
                <!-- 空白 -->
                <span class="item-spacer">&nbsp;</span>
                <div style="float: right">
                  <!-- 確定ボタン -->
                  <v-btn class="other-btn" @click="confirm()">
                    {{ $t("btn.btn_confirm") }}
                  </v-btn>
                </div>
              </div>
            </v-card-actions>
            <v-form ref="processedList" lazy-validation>
              <v-container fluid>
                <!-- 加工済在庫一覧テーブルデータ -->
                <v-data-table
                  id="processedInventoryData"
                  :key="tableKey"
                  :fixed-header="true"
                  :headers="processedInventoryHeader"
                  :items="processedList"
                  :hide-default-footer="true"
                  disable-filtering
                  disable-sort
                  disable-pagination
                  height="300px"
                  class="overflow-hidden"
                >
                  <!-- ロットNoヘッダー -->
                  <template v-slot:[`header.lotNo`]>
                    {{ $t("label.lbl_lot") + "／" + $t("label.lbl_serial") }}<br />
                    {{
                      $t("label.lbl_dueDate") +
                      "／" +
                      $t("label.lbl_lotSubkey1") +
                      "／" +
                      $t("label.lbl_lotSubkey2")
                    }}<br />
                  </template>

                  <!-- ロット -->
                  <template v-slot:[`item.lotNo`]="{ item }">
                    {{ item.lotNo }}<br />
                    {{ item.dueDate }}
                  </template>
                  <!-- 完了工程 -->
                  <template v-slot:[`item.exitProcName`]="{ item }">
                    <span>
                      <!-- 完了工程を表示 -->
                      {{ item.exitProcName }}
                    </span>
                  </template>
                  <!-- 在庫数量 -->
                  <template v-slot:[`item.stock`]="{ item }">
                    <span>
                      <!-- 在庫数量を表示 -->
                      {{ item.stock }}
                    </span>
                  </template>
                  <!-- 使用数量 -->
                  <template v-slot:[`item.usedQty`]="{ item }">
                    <v-text-field
                      outlined
                      dense
                      v-model="item.usedQty"
                      class="input-number"
                      clear-icon="mdi-close-circle"
                      clearable
                      :disabled="true"
                    />
                  </template>
                  <!-- 使用工程選択チェックボックス -->
                  <template v-slot:[`item.useQtyCheckBox`]="{ item }">
                    <input
                      type="checkbox"
                      :checked="item.useQtyCheckBox"
                      style="transform: scale(2)"
                      @change="checkProcessedInventoryData(item)"
                    />
                  </template>
                </v-data-table>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- 工程選択ダイアログ-->
      <v-dialog
        v-model="dialogWorkInstructionsBreakdown"
        :max-width="800"
        persistent=""
        no-click-animation
      >
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_workInstructionsBreakdownRegist") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <!-- 工程選択テーブルデータ -->
              <!-- <v-row> -->
              <v-data-table
                id="workInstructionsData"
                fixed-header
                :headers="workInstructionsHeader"
                :items="workInstructionsList"
                disable-sort
                :hide-default-footer="true"
                :items-per-page="10"
              >
                <!-- ヘッダーチェックボックス-->
                <template v-slot:[`header.check`]="{ header }">
                  <input
                    type="checkbox"
                    :checked="allWorkInstructionsCheckFlg"
                    :value="header.check"
                    @click="selectAllWorkInstructionsCheck"
                    style="transform: scale(2)"
                  />
                </template>
                <!-- 一覧チェックボックス -->
                <template v-slot:[`item.check`]="{ item, index }">
                  <input
                    type="checkbox"
                    v-model="item.check"
                    :value="listCheckbox"
                    @click="checkAllWorkInstructionsCheck(item, index)"
                    style="transform: scale(2)"
                    :disabled="item.disabledFlg"
                  />
                </template>
                <!-- 指示内容 -->
                <template v-slot:[`item.procCdName`]="{ item }">
                  <span>
                    <!-- 指示内容を表示 -->
                    {{ item.procCdName }}
                  </span>
                </template>
              </v-data-table>
              <!-- </v-row> -->
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!-- 閉じるボタン領域 -->
              <div class="btn-search-area" style="float: left">
                <v-btn class="other-btn" @click="closeDialogWorkInstructions">
                  {{ $t("btn.btn_close") }}
                </v-btn>
              </div>
              <div class="btn-area">
                <!-- 設定ボタン -->
                <v-btn class="other-btn" @click="setWorkInstructions">
                  {{ $t("btn.btn_config") }}
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 指示作成状況ダイアログ-->
      <v-dialog
        v-model="dialogInstructionsCreatedStatus"
        :max-width="800"
        persistent=""
        no-click-animation
        scrollable
      >
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_dialogInstructionsCreatedStatus") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <!-- 指示作成状況テーブルデータ -->

              <v-data-table
                id="instructionsCreatedData"
                fixed-header
                :headers="instructionsCreatedHeader"
                :items="instructionsCreatedList"
                disable-sort
                disable-pagination
                :hide-default-footer="true"
                height="400px"
              >
                <!-- 指示作成状況メッセージ -->
                <template v-slot:[`item.instructionsCreatedMessage`]="{ item }">
                  <span>
                    <!-- 指示作成状況メッセージを表示 -->
                    {{ item.instructionsCreatedMessage }}
                  </span>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <div class="btn-area">
                <!-- ホームボタン -->
                <v-btn class="other-btn" @click="toHome">
                  {{ $t("menu.P-MENU-000") }}
                </v-btn>
                <!-- 加工計画一覧ボタン -->
                <v-btn
                  v-if="instructionsCreatedList.length == 2"
                  class="other-btn"
                  @click="toProcessingPlanList()"
                >
                  {{ $t("btn.btn_distributionProcessingPlanSearchList") }}
                </v-btn>
                <!-- 加工計画一覧ボタン(引当不可のみ) -->
                <v-btn
                  v-if="instructionsCreatedList.length > 2"
                  class="other-btn-nosize"
                  @click="toProcessingPlanListNotAllocatableOnly()"
                >
                  {{ $t("btn.btn_distributionProcessingPlanSearchListNotAllocatableOnly") }}
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="alertDialog" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ message }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="alertCancel"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "../../components/ConfirmDialog";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
export default {
  name: appConfig.SCREEN_ID.P_TMP_906,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // 計画区分
    PLAN_DIV: {
      OUT: "01",
      IN: "02",
      PLAN: "03",
    },

    // ページング
    u_100: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    itemsPerPageList: [100, 250, 500],
    //ページに表示する一覧
    showItemsPerPage: [],

    // ローディング画面表示フラグ
    loadingCounter: 0,
    alertMessageInUnit: "",
    // 取引先プルダウン
    suppliersSelected: "",
    suppliersSelectedName: "",
    // 指示作成単位選択プルダウン
    allocationUnit: "",
    allocationUnitList: [],
    // 引当順選択プルダウン
    allocationOrder: "",
    allocationOrderList: [],

    // 加工済在庫一覧ダイアログの品番/品名
    processedProductCnCd: "",
    // 加工済在庫一覧ダイアログの付属品番
    processedAccessoryCd: "",
    // 加工済在庫一覧ダイアログの予定数量
    scheduleQuantity: "",
    // 加工済在庫一覧ヘッダー
    processedInventoryHeader: [
      { text: i18n.tc("label.lbl_lot"), value: "lotNo", align: "left", width: "30% " },
      {
        text: i18n.tc("label.lbl_exitprocCd"),
        value: "exitProcName",
        align: "left",
        width: "20%",
      },
      { text: i18n.tc("label.lbl_stockQuantity"), value: "stock", align: "right", width: "20%" },
      { text: i18n.tc("label.lbl_usedQuantity"), value: "usedQty", align: "right", width: "20%" },
      {
        text: i18n.tc("label.lbl_choice"),
        value: "useQtyCheckBox",
        align: "center",
        width: "10%",
      },
    ],
    // 加工済在庫一覧データ
    processedList: [],
    tableKey: new Date().getTime(),
    // 加工済在庫一覧ダイアログ
    dialogProcessedInventoryList: false,
    // 加工済在庫数選択インデックス
    processedIndex: 0,
    // 加工済在庫一覧在庫数
    stockQuantity: 0,
    // 作業指示内訳ダイアログの取引先
    instructionsSuppliers: "",
    // 作業指示内訳ダイアログの作業日
    instructionsWorkingDate: "",
    // 作業指示内訳ダイアログの伝票No
    instructionsVoucherNo: "",
    // 作業指示内訳ダイアログの品番/品名
    instructionsProductCnCd: "",
    // 作業指示内訳ダイアログの付属品番
    instructionsAccessoryCd: "",
    // 作業指示内訳ダイアログのロット
    instructionsLot: "",
    // 作業指示内訳ダイアログの数量
    instructionsQty: "",
    // 作業指示内訳ヘッダー
    workInstructionsHeader: [
      { text: "選択", value: "check", align: "left", width: "5%" },
      {
        text: i18n.tc("label.lbl_allSelected"),
        value: "procCdName",
        align: "left",
        width: "90%",
      },
    ],
    // 作業指示内訳データ
    workInstructionsList: [],
    // 工程選択ダイアログ
    dialogWorkInstructionsBreakdown: false,
    // 一覧選択チェックボックス
    listCheckbox: [],
    // 作業指示内訳選択行インデックス
    clickRowIndex: 0,
    // 登録ボタン押下時確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    message: "",
    // 指示作成状況ヘッダー
    instructionsCreatedHeader: [
      {
        text: i18n.tc("label.lbl_message"),
        value: "instructionsCreatedMessage",
        align: "left",
        width: "15 % ",
      },
    ],
    // 指示作成状況データ
    instructionsCreatedList: [],
    resultList: [],
    requestId: "",
    // 指示作成状況自動更新フラグ
    autoUpdateFlag: "off",
    timeId: "",
    // 指示作成状況ダイアログ
    dialogInstructionsCreatedStatus: false,
    // 初期表示時の件数インデックス
    inputListNumberIndex: -1,
    // 残数量
    zanSu: 0,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    sortBy: "",
    // メニュー
    openMenu: null,
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      cntapprovalFlg: false,
    },
    // データテーブルallCheckFlg
    allWorkInstructionsCheckFlg: false,

    // 総件数
    totalCount: 0,
    inputList: [],
    // 工程情報リスト
    optHistList: [],
    // 加工済在庫リスト
    stockList: [],
    // 確定チェックフラグ
    checkFlg: false,
    // 警告ダイアログ
    alertDialog: false,
    headerItems: [
      // 作成対象
      {
        text: i18n.tc("label.lbl_creationTarget"),
        value: "createDataCheck",
        width: "4%",
        align: "center",
        sortable: false,
      },
      //  計画日 or 入荷予定日 or 出荷予定日
      {
        text: i18n.tc("label.lbl_planDay"),
        value: "processingDate",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 伝票No
      {
        text: i18n.tc("label.lbl_voucherNo"),
        value: "voucherNo",
        width: "9%",
        align: "left",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 付属品番
      {
        text: i18n.tc("label.lbl_accessoryCd"),
        value: "accessoryCd",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        value: "lotNo",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // 予定数量
      {
        text: i18n.tc("label.lbl_scheduleQuantity"),
        value: "scheduleQuantity",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 加工済在庫数
      {
        text: i18n.tc("label.lbl_processedStockQty"),
        value: "processedStockQty",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 加工済使用数
      {
        text: i18n.tc("label.lbl_processedUsedQty"),
        value: "processedUsedQty",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 加工種類区分
      {
        text: i18n.tc("label.lbl_processingKindsDiv"),
        value: "processingKindsDivName",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 作成指示内訳ボタン
      {
        value: "makeInstructions",
        width: "1%",
        align: "right",
        sortable: false,
      },
      // 工程
      {
        text: i18n.tc("label.lbl_opeHistRightArrow"),
        value: "optHistList",
        width: "25%",
        align: "left",
        sortable: false,
      },
    ],
    // 前画面より受け取る値
    backpage: "",
    planningSection: "",
    allocationResult: "",
    arrCreatedDateFrom: "",
    inOutListSub: [],
    // 作業日
    processingDate: "",
    processingDateTo: "",
    processingDateLabel: "",
    // 加工計画一覧：検索条件
    searchParam: "",
    // 加工計画一覧：チェックされたリスト
    checkList: [],

    // 工程訳カラム固定値
    fixedOptHistOrder: [],
    // 入力チェック
    rules: {
      // 使用数量①：数値チェック
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),

      // 使用数量②：最大桁数チェック
      limitNumLength: (value) =>
        value == null ||
        String(value).replaceAll(",", "").length < 10 ||
        i18n.tc("check.chk_limitNumLength"),

      // 使用数量③：整数チェック
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      // 使用数量④：0以上の入力チェック
      isRangeCheck: (value) =>
        0 <= Number(commonUtil.zen2han(value).replaceAll(",", "")) ||
        i18n.tc("check.chk_inputMore"),
    },
  }),

  methods: {
    init() {
      // 前画面の検索条件を一時保存(ヘッダー部分)
      // 前画面からパラメータを受け取る
      this.searchParam = this.$route.params.distributionInstructionsCreationList.headerList;
      this.suppliersSelected =
        this.$route.params.distributionInstructionsCreationList.headerList.searchSuppliersSelected;
      this.suppliersSelectedName =
        this.$route.params.distributionInstructionsCreationList.suppliersSelectedName;
      this.backpage = this.$route.params.distributionInstructionsCreationList.screenName;
      this.processingDateLabel =
        this.$route.params.distributionInstructionsCreationList.processingDateLabel;
      this.checkList = this.$route.params.distributionInstructionsCreationList.inOutListSub;

      // コードマスタ取得処理
      this.getMstCode();

      // 加工完成指示作成対象APIを呼び出す
      this.getDistributionInstructions();
    },

    /**
     * 一覧とページングネーションの幅調整処理
     */
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_100 = true;
      }
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.showPage();
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.showPage();
    },

    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action = () => {};
      let checkList;
      switch (button) {
        case "btn_insert":
          // チェックした行
          checkList = this.inputList.filter((data) => data.createDataCheck);

          // １つもチェックされていない場合、画面遷移できない
          if (!checkList.length) {
            // なければ警告ダイアログ表示
            this.alertDialog = true;
            this.message = messsageUtil.getMessage("P-EXT-003_001_E");
            return;
          }

          isValidationRequired = true;
          message = messsageUtil.getMessage("P-DST-001_003_C");
          action = this.registration;
          break;
        case "btn_back":
          isValidationRequired = false;
          message = messsageUtil.getMessage("P-COM-001_004_W");
          action = this.updateOk;
          break;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        // let isValid = this.$refs.editedList.validate();
        // if (!isValid) {
        //   return;
        // }
      }
      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },

    /**
     * 指示作成対象選択チェック処理
     */
    checkAllocationUnit(item) {
      // 指示作成単位が伝票単位の場合は1明細にチェックを入れた場合、同一伝票の明細にもチェックを入れる
      // 明細単位の場合は通常通り1明細だけにチェックを入れるため、特に処理はしない
      if (this.allocationUnit == "01") {
        this.inputList
          .filter((row) => row.listSid == item.listSid)
          .forEach((row) => (row.createDataCheck = item.createDataCheck));
      }
    },

    allAllocationUnitChange() {
      this.inputList
        .filter((row) => row.createDataCheck)
        .forEach((row) => this.checkAllocationUnit(row));
    },

    /**
     * 加工完成指示作成:参照API
     */
    getDistributionInstructions() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // リクエストパラメータを設定

      const body = this.$httpClient.createRequestBodyConfig();
      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STC_003;
      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // 計画区分
      body.reqIdv.processingDiv =
        this.$route.params.distributionInstructionsCreationList.headerList.searchPlanningSection;
      // 伝票SID。
      body.reqIdv.processingOrderList = this.checkList;
      // console.debug("getDistributionInstructions() Body", body);
      console.log(body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(
            appConfig.API_URL.DST_PROCESS_INSTRUCTION_TARGET_LIST,
            body,
            appConfig.APP_CONFIG
          )
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getDistributionInstructions() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 加工完成指示内容用
              const list = [];
              jsonData.resIdv.instructList.forEach((row) => {
                const lot = commonUtil.convertLotNo(row.lotNo);
                list.push({
                  processingDate: row.scheduleDate
                    ? dateTimeHelper.convertUTC2JST(row.scheduleDate).substr(0, 10)
                    : null, // 作業日
                  processingDateCal: row.scheduleDate
                    ? dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(row.scheduleDate))
                    : null, // 作業日
                  listSid: row.listSid, // 伝票SID
                  listSubSid: row.listSubSid, // 明細SID
                  voucherNo: row.listNo, // 伝票No
                  itemStandardSid: row.itemStandardSid, // 商品標準SID
                  productCnCd: row.itemCd, // 品番
                  productName: row.itemName, // 品名
                  accessoryCd: row.itemSubCd, // 付属品番
                  clientSid: row.clientSid, // 取引先
                  // ロット、S/N
                  lotNo: lot.lot,
                  // 期限日、付属キー1、付属キー2
                  dueDate: lot.dueDate,
                  scheduleQuantity: row.scheduleQuantity, // 予定数量
                  processedStockQty: row.stock, // 在庫数量（合計）
                  stockList: row.stockList, // 加工済在庫数リスト
                  processedUsedQty: 0, // 加工済使用数
                  processingKindsDivName: row.processingKindsDivName, // 加工種類区分
                  optHistList: row.defalutProcList, // デフォルト工程情報リスト
                  optHistListDefalut: row.defalutProcList, // デフォルト工程情報リスト
                  exitProcCd: "", // 完了工程コード
                  createDataCheck: true, // チェックフラグ
                  firstProcCd: row.firstProcCd, // 初工程コード
                  parentItemDiv: row.parentItemDiv, // 親品番実態あり、なし区分
                });
              });
              // 計画区分が加工の場合
              if (this.searchParam.searchPlanningSection == "03") {
                // 加工完成指示内容用リストを件数分回す
                list.forEach((row) => {
                  // デフォルト工程コードリストに500(出荷)が存在する場合、加工計画ルートでは最終工程が保管のため300(保管)に変換する
                  let shippingIndex = row.optHistListDefalut.findIndex((proc) => proc == 500);
                  row.optHistList[shippingIndex] = 300;
                  row.optHistListDefalut[shippingIndex] = 300;
                });

                // 工程コード変更用リスト
                const allProcList = [];

                jsonData.resIdv.allProcList.forEach((procData) => {
                  // 工程コードリストの工程コードが500の場合
                  if (procData.procCd == 500) {
                    // 工程コード変更用リストの工程コードに300,工程コード名に保管を追加
                    allProcList.push({
                      procCdName: "保管", // 工程コード名
                      procCd: 300, // 工程コード
                      procCdOrder: procData.procCdOrder, // 工程順
                    });
                  } else {
                    // 工程コード変更用リストに工程コードリストの値を追加
                    allProcList.push({
                      procCdName: procData.procCdName, // 工程コード名
                      procCd: procData.procCd, // 工程コード
                      procCdOrder: procData.procCdOrder, // 工程順
                    });
                  }
                });

                // 工程訳カラム固定値に工程コード変更用リストを入れる
                this.fixedOptHistOrder = allProcList;
              } else {
                // 計画区分が加工以外の場合
                // 工程コードリスト
                this.fixedOptHistOrder = jsonData.resIdv.allProcList;
              }
              // 加工指示作成画面の一覧を取得
              this.inputList = list;
              //総件数
              this.totalCount = this.inputList.length;

              //初期表示時の件数インデックス
              this.inputListNumberIndex += this.inputList.length;
              resolve(list);
              // エラー時
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((resolve) => {
            this.infoDialog.message = resolve;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 加工完成指示作成:登録処理
     */
    registration() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;
      // APIパラメータ
      const body = this.$httpClient.createRequestBodyConfig();
      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STC_003;
      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // 計画区分
      body.reqIdv.planKbn =
        this.$route.params.distributionInstructionsCreationList.headerList.searchPlanningSection;
      // 引当単位
      body.reqIdv.allocationUnit = this.allocationUnit;
      // 引当順
      body.reqIdv.allocationOrder = this.allocationOrder;
      // 修正ﾘｽﾄ抽出
      var bodyList = [];
      // 実績を追加したレコードのみループを回し、数量を設定。
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].createDataCheck) {
          var inputMap = {};
          // 伝票SID
          inputMap.outListSid = this.inputList[i].listSid;
          // 伝票No
          inputMap.outListNo = this.inputList[i].voucherNo;
          // 伝票明細SID
          inputMap.outListSubSid = this.inputList[i].listSubSid;
          // 作業日
          inputMap.scheduleDate = this.inputList[i].processingDate;
          // 商品標準SID
          inputMap.itemStandardSid = this.inputList[i].itemStandardSid;
          // 品番
          inputMap.itemCd = this.inputList[i].productCnCd;
          // 付属品番
          inputMap.itemSubCd = this.inputList[i].accessoryCd;
          // ロット
          inputMap.lotNo = this.inputList[i].lotNo;
          // 完了工程CD
          inputMap.exitProcCd = this.inputList[i].exitProcCd;
          // 予定数量
          inputMap.scheduleQuantity = this.inputList[i].scheduleQuantity;
          // 加工済使用数
          inputMap.processUsedQuantity = this.inputList[i].processedUsedQty;
          // 工程リスト
          inputMap.procList = this.inputList[i].optHistList;

          bodyList.push(inputMap);
        }
      }
      body.reqIdv.allocationList = bodyList;
      // console.debug("registration() Body", body);
      console.log(body);
      // リクエストIDを取得
      this.requestId = body.reqCom.reqComRequestId;
      // console.debug("requestId", this.requestId);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.DST_PROCESS_INSTRUCTION_REGIST, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("registration() Response", response);

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 引当状況モダールを表示
              this.openInstructionsCreatedStatus();
            } else {
              // エラー時
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("registration() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            // this.loadingCounter = 0;
          });
      });
    },

    /**
     * 加工完成指示作成画面：指示作成状況API
     */
    requestResult() {
      this.resultList = [];
      // ローディング画面表示ON;
      this.loadingCounter = 1;
      // APIパラメータ
      const config = this.$httpClient.createGetApiRequestConfig();

      //リクエストID
      config.params.requestId = this.requestId;
      // 計画区分
      config.params.processingDiv =
        this.$route.params.distributionInstructionsCreationList.headerList.searchPlanningSection;

      // console.debug("requestResult() config", config);
      console.log(config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.DST_PROCESS_INSTRUCTION_SURVEILLANCE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("requestResult() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.dstProcessInstructionSurveillanceList.forEach((row) => {
                list.push({
                  completeFlg: row.completeFlg, // ステータス
                  itemCd: row.itemCd, // 品番
                  outListNo: row.listNo, // 伝票No
                  scheduleQuantity: row.stock, // 予定数量
                  listSubSid: row.listSubSid, // 伝票明細
                });
                // 伝票明細リストを取得
                this.resultList.push(row.listSubSid);
              });
              // 引当不可のデータを抽出
              const listNg = list.filter((v) => v.completeFlg != "0");

              // 指示作成状況を取得
              for (var i = 0; i < listNg.length; i++) {
                if (listNg[i].completeFlg == "1") {
                  this.instructionsCreatedList.push({
                    instructionsCreatedMessage:
                      i18n.tc("label.lbl_unavailableReserve") +
                      " " +
                      listNg[i].outListNo +
                      " " +
                      listNg[i].itemCd.substring(0, 50).replace(/\s+$/, "") +
                      " " +
                      listNg[i].itemCd.substring(50, 100).replace(/\s+$/, "") +
                      " " +
                      listNg[i].scheduleQuantity,
                  });
                } else if (listNg[i].completeFlg == "2") {
                  this.instructionsCreatedList.push({
                    instructionsCreatedMessage:
                      i18n.tc("label.lbl_otherDetailsUnavailableReserve") +
                      " " +
                      listNg[i].outListNo +
                      " " +
                      listNg[i].itemCd.substring(0, 50).replace(/\s+$/, "") +
                      " " +
                      listNg[i].itemCd.substring(50, 100).replace(/\s+$/, "") +
                      " " +
                      listNg[i].scheduleQuantity,
                  });
                }
              }
              // 伝票明細リストと画面明細の伝票明細リストを突き合わせる
              // console.debug("伝票SID", this.resultList);
              console.log(this.resultList);
              if (
                this.inputList
                  .filter((data) => data.createDataCheck)
                  .filter((data) => !this.resultList.includes(data.listSubSid)).length == 0
              ) {
                this.autoUpdateFlag = "on";
                this.instructionsCreatedList.push({
                  instructionsCreatedMessage: i18n.tc("label.lbl_messageFinished"),
                });
                clearInterval(this.timeId);
                this.loadingCounter = 0;
              } else {
                this.autoUpdateFlag = "off";
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            if (this.infoDialog.message) {
              this.autoUpdateFlag = "on";
              this.dialogInstructionsCreatedStatus = false;
              clearInterval(this.timeId);
              this.loadingCounter = 0;
            }
            // // 伝票明細リストと画面明細の伝票明細リストを突き合わせる
            // // console.debug("伝票SID", this.resultList);
            // console.log(this.resultList);
            // if (
            //   this.inputList
            //     .filter((data) => data.createDataCheck)
            //     .filter((data) => !this.resultList.includes(data.listSubSid)).length == 0
            // ) {
            //   this.autoUpdateFlag = "on";
            //   this.instructionsCreatedList.push({
            //     instructionsCreatedMessage: i18n.tc("label.lbl_messageFinished"),
            //   });
            //   clearInterval(this.timeId);
            //   this.loadingCounter = 0;
            // } else {
            //   this.autoUpdateFlag = "off";
            // }
          });
      });
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    alertCancel() {
      this.alertDialog = false;
    },

    /**
     * 全選択チェックボックス処理
     */
    selectAllWorkInstructionsCheck() {
      const value = [300, 500];
      // 全選択チェックあり場合
      if (!this.allWorkInstructionsCheckFlg) {
        // 全選択チェックを入れる
        this.allWorkInstructionsCheckFlg = true;
        // 明細チェックを入れる
        for (var i = 0; i < this.workInstructionsList.length; i++) {
          // 出荷データ以外の場合、チェックを入れない
          if (!value.includes(this.workInstructionsList[i].procCd)) {
            this.workInstructionsList[i].check = true;
          }
        }
        // 全選択チェックなし場合
      } else {
        // 明細チェックを外す
        for (var j = 0; j < this.workInstructionsList.length; j++) {
          // 出荷データもしくは加工データの場合、チェックを外さない
          if (!value.includes(this.workInstructionsList[j].procCd)) {
            this.workInstructionsList[j].check = false;
          }
        }
        // 全選択チェックを外す
        this.allWorkInstructionsCheckFlg = false;
      }
    },

    /**
     * 各工程のチェックボックスチェック時の全選択チェックボックスのチェック処理
     */
    checkAllWorkInstructionsCheck(item, index) {
      if (!item.check) {
        // チェックを入れる
        this.workInstructionsList[index].check = true;
        // 明細チェックをカウントする
        let cnt = 0;
        for (var i = 0; i < this.workInstructionsList.length; i++) {
          if (this.workInstructionsList[i].check == true) {
            cnt = cnt + 1;
          }
        }
        // すべての明細チェッを入れる場合、全選択チェックを入れる
        if (cnt == this.workInstructionsList.length) {
          this.allWorkInstructionsCheckFlg = true;
        }
      } else {
        // チェック外す
        this.workInstructionsList[index].check = false;
        // 明細チェックをカウントする
        let cnt = 0;
        for (var j = 0; j < this.workInstructionsList.length; j++) {
          if (this.workInstructionsList[j].check != false) {
            cnt = cnt + 1;
          }
        }
        // すべての明細チェッを入らない場合、全選択チェックを外す
        if (cnt != this.workInstructionsList.length) {
          this.allWorkInstructionsCheckFlg = false;
        }
      }
    },

    /**
     * 戻るボタン押下
     */
    backPage() {
      this.updateDialogMessage = true;
    },

    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;

      // 加工計画一覧に戻る場合
      if (this.backpage == appConfig.SCREEN_ID.P_TMP_903) {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_TMP_903,

          params: {
            headerList: this.searchParam,
            suppliersSelected: this.suppliersSelected,
            processingDate: this.processingDate,
            voucherNo: this.voucherNo,
            backPage: "DistributionInstructionsCreation",
          },
        });
      }
    },

    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 加工済在庫一覧ダイアログを表示
     */
    openProcessedInventoryData(item, index) {
      // 加工済在庫一覧モダールのリストを取得
      item.stockList.forEach((row) => {
        this.processedList.push({
          lotNo: row.lotNo,
          exitProcCd: row.exitProcCd,
          exitProcName: this.fixedOptHistOrder.find((v) => row.exitProcCd == v.procCd).procCdName,
          stock: row.stock,
          usedQty: item.scheduleQuantity,
          useQtyCheckBox: !row.useQtyCheckBox ? false : row.useQtyCheckBox,
        });
      });

      //品番/品名
      this.processedProductCnCd = item.productCnCd + " " + item.productName;
      //付属品番
      this.processedAccessoryCd = item.accessoryCd;
      //予定数量
      this.scheduleQuantity = item.scheduleQuantity;
      //加工済在庫数選択インデックス
      this.processedIndex = index;
      this.checkFlg = false;
      this.dialogProcessedInventoryList = true;
    },

    /**
     * 加工済在庫数、予約数チェック
     */
    checkProcessedInventoryData(item) {
      // checkが入るタイミングのみ処理する
      if (item.useQtyCheckBox) {
        item.useQtyCheckBox = !item.useQtyCheckBox;
      } else if (item.usedQty > item.stock) {
        this.message = messsageUtil.getMessage("P-TMP-903_004_E");
        this.alertDialog = true;
        item.useQtyCheckBox = false;
        this.$forceUpdate();
      } else {
        item.useQtyCheckBox = !item.useQtyCheckBox;
      }
      // チェックボックスを再描画するためにkeyを修正
      this.tableKey = new Date().getTime();
    },

    /**
     * 加工済在庫一覧ダイアログ：閉じる処理
     */
    close() {
      this.dialogProcessedInventoryList = false;
      this.processedList = []; // 操作用の変数内のリセット
      this.processedErrorMassage = null;
      this.processedErrorMassageFlg = false;
    },

    /**
     * 数量フォーマット
     */
    formatQty(qty) {
      if (!qty) {
        return 0;
      }

      var resultQty = commonUtil.zen2han(qty).replaceAll(",", "");
      resultQty = resultQty.replace(/[^0-9]/gi, "");
      if (resultQty.length > 9) {
        resultQty = resultQty.substring(0, 9);
      }
      return Number(resultQty);
    },

    /**
     * 加工済在庫一覧ダイアログ：確定処理
     */
    confirm() {
      // 使用する工程選択チェック
      const checkNum = this.checkSelect();

      // チェックされているcheckboxが1件より上の場合は、エラーだった場合は処理しない
      if (checkNum > 1) {
        return true;
      }

      // チェックされていない場合は加工済使用数が使用されない判定
      if (checkNum != 0) {
        // 使用数量合計
        const usedQuantity = this.processedList.find((order) => {
          return order.useQtyCheckBox;
        }).usedQty;

        // 加工済在庫一覧ダイアログの使用数量を加工済使用数へ設定
        this.inputList[this.processedIndex].processedUsedQty = usedQuantity;

        // 加工済在庫一覧ダイアログの完了工程コードを完了工程コードへ設定
        this.inputList[this.processedIndex].exitProcCd = this.processedList.find((order) => {
          return order.useQtyCheckBox;
        }).exitProcCd;

        // 完了工程以上の工程コードのみ指定させる(完了工程まで完了しているものを引き当てるため)
        this.inputList[this.processedIndex].optHistList = this.inputList[
          this.processedIndex
        ].optHistListDefalut.filter(
          (procCd) => procCd > this.inputList[this.processedIndex].exitProcCd || procCd == 300
        );
      } else {
        // 加工済在庫一覧ダイアログの使用数量を加工済使用数へ設定
        this.inputList[this.processedIndex].processedUsedQty = 0;

        // 加工済在庫一覧ダイアログの完了工程コードを完了工程コードへ設定
        this.inputList[this.processedIndex].exitProcCd = "";

        // 完成品引当のキャンセイルした場合は、工程リストを初期化
        this.inputList[this.processedIndex].optHistList =
          this.inputList[this.processedIndex].optHistListDefalut;
      }

      // 入力内容の保管
      this.inputList[this.processedIndex].stockList = this.processedList;

      // ダイアログ閉じる
      this.dialogProcessedInventoryList = false;

      this.processedList = []; // 操作用の変数内のリセット
    },

    checkSelect() {
      const trueCount = this.processedList.filter((v) => v.useQtyCheckBox).length;
      if (1 < trueCount) {
        this.message = messsageUtil.getMessage("P-TMP-903_003_E");
        this.alertDialog = true;
      }
      return trueCount;
    },

    /**
     *工程選択ダイアログを表示
     */
    openMakeInstructions(item, index) {
      this.workInstructionsList = [];
      // 工程選択リストを取得
      for (var j = 0; j < this.fixedOptHistOrder.length; j++) {
        if (item.optHistListDefalut.includes(this.fixedOptHistOrder[j].procCd)) {
          this.workInstructionsList.push({
            procCdName: this.fixedOptHistOrder[j].procCdName, // 工程コード名
            procCd: this.fixedOptHistOrder[j].procCd, // 工程コード
            procCdOrder: this.fixedOptHistOrder[j].procCdOrder, // 工程順
            check: false, // チェックフラグ
            disabledFlg:
              this.fixedOptHistOrder[j].procCdName == "出荷" || // 表示する工程が出荷の場合はチェック不可
              this.fixedOptHistOrder[j].procCdName == "保管" || // 表示する工程が保管の場合はチェック不可
              (item.parentItemDiv == 1 && item.firstProcCd == this.fixedOptHistOrder[j].procCd), // 親品番実態あり、なし区分があり、初工程コードと工程コードが一致する場合、チェック不可
          });
        }
      }
      // 工程選択ダイアログのデータ
      for (var i = 0; i < this.workInstructionsList.length; i++) {
        // 工程①：デフォルト工程リストを参照し、チェックを設定
        if (item.optHistList.includes(this.workInstructionsList[i].procCd)) {
          this.workInstructionsList[i].check = true;
        } else {
          this.workInstructionsList[i].check = false;
        }
        // 工程②：出荷データの場合、出荷項目にチェックを設定
        if (
          this.$route.params.distributionInstructionsCreationList.headerList
            .searchPlanningSection == "01"
        ) {
          const value = [500];
          if (value.includes(this.workInstructionsList[i].procCd)) {
            this.workInstructionsList[i].check = true;
          }
          // 加工データの場合、保管項目にチェックを設定
        } else if (
          this.$route.params.distributionInstructionsCreationList.headerList
            .searchPlanningSection == "03"
        ) {
          const strageValue = [300];
          if (strageValue.includes(this.workInstructionsList[i].procCd)) {
            this.workInstructionsList[i].check = true;
          }
        }
      }

      //作業内訳指示ボタン選択行インデックス
      this.clickRowIndex = index;
      this.dialogWorkInstructionsBreakdown = true;
    },

    /**
     * 工程選択ダイアログ：閉じる処理
     */
    closeDialogWorkInstructions() {
      this.dialogWorkInstructionsBreakdown = false;
    },

    /**
     * 工程選択ダイアログ：設定処理
     */
    setWorkInstructions() {
      //工程選択ダイアログでチェックが入った行の指示内容を設定
      this.inputList[this.clickRowIndex].optHistList = this.workInstructionsList
        .filter((val) => val.check == true)
        .map((val) => {
          return val.procCd;
        });

      this.dialogWorkInstructionsBreakdown = false;
    },

    /**
     * 指示作成状況ダイアログ表示処理
     */
    openInstructionsCreatedStatus() {
      this.loadingCounter = 1;
      if (this.autoUpdateFlag == "on") {
        this.autoUpdateFlag = "off";
      }

      //指示作成状況ダイアログデータ
      var instructionsCreatedInfoList = [
        {
          instructionsCreatedMessage: i18n.tc("label.lbl_messageStart"),
        },
      ];

      this.instructionsCreatedList = instructionsCreatedInfoList;
      this.dialogInstructionsCreatedStatus = true;

      this.InstructionsCreated().finally(() => {
        // this.loadingCounter = 0;
      });
    },

    // 指示作成時の進捗表示
    async InstructionsCreated() {
      await sleep(100);
      this.timeId = "";

      if (this.autoUpdateFlag == "off") {
        // 途中で確認
        this.autoUpdateFlag = "on";
        this.timeId = setInterval(this.requestResult, 5000);
        console.log("timeId: " + this.timeId);
        this.loadingCounter = 1;
      } else {
        // 自動更新停止
        this.autoUpdateFlag = "off";
        clearInterval(this.timeId);
        console.log("clearInterval");
        this.loadingCounter = 0;
      }
    },

    /**
     * 指示作成状況ダイアログの加工計画一覧ボタン押下時
     */
    toProcessingPlanList() {
      // 加工計画一覧に戻る

      if (this.backpage == appConfig.SCREEN_ID.P_TMP_903) {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_TMP_903,

          params: {
            // ヘッダー条件
            headerList: this.searchParam,
            // 引当結果コード
            allocationResultCd: "02",
            // 遷移元画面
            from: i18n.tc("label.lbl_dialogInstructionsCreatedStatus"),
          },
        });
      }
    },

    /**
     * 指示作成状況ダイアログの加工計画一覧ボタン押下時
     */
    toProcessingPlanListNotAllocatableOnly() {
      // 加工計画一覧に戻る

      if (this.backpage == appConfig.SCREEN_ID.P_TMP_903) {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_TMP_903,

          params: {
            // ヘッダー条件
            headerList: this.searchParam,
            // 引当結果コード
            allocationResultCd: "03",
            // 遷移元画面
            from: i18n.tc("label.lbl_dialogInstructionsCreatedStatus"),
          },
        });
      }
    },

    /**
     * ホームに戻る
     */
    toHome() {
      this.$router.push({
        name: appConfig.SCREEN_ID.M_HOM,
      });
    },

    /**
     * ページング処理
     */
    showPage() {
      return this.inputList;
    },

    /**
     * コードマスタを処理
     */
    getMstCode() {
      // 引当対象区分を取得します。
      const allocationUnitList = getParameter.getCodeMst(appConfig.CODETYPE.ALLOCATION_UNIT);

      // 引当順を取得します。
      const allocationOrderList = getParameter.getCodeMst(appConfig.CODETYPE.ALLOCATION_ORDER);

      // 非同期処理を開始します。
      Promise.all([allocationUnitList, allocationOrderList])
        // 成功時の処理です。
        .then((result) => {
          // 有償無償区分一覧を設定します。
          this.allocationUnitList = result[0];
          this.allocationUnit = result[0][0].value; // 初期値はリストの先頭
          this.allocationOrderList = result[1];
          this.allocationOrder = result[1][0].value; // 初期値はリストの先頭
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        // 成功・失敗に関わらず実行される処理です。
        .finally(() => {});
    },
  },
  computed: {
    // ページング処理
    allocationUnitName() {
      if (this.allocationUnitList) {
        return "";
      }
      return this.allocationUnitList.find((v) => v.value == this.allocationUnit).name;
    },
    allocationOrderName() {
      if (this.allocationOrderList) {
        return "";
      }
      return this.allocationOrderList.find((v) => v.value == this.allocationOrder).name;
    },
  },
  watch: {},
  created() {
    this.init();
  },
};

async function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
</script>

<style>
@import "../../css/style.css";
</style>

<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;

  font-size: large;
}

#processedInventoryData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }

    //テーブル項目部分
    // th::after {
    //   content: "*" !important;
    //   color: red;
    // }
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    //テーブルデータ部分
    td {
      font-size: large;
    }

    div.v-data-table__wrapper {
      overflow-y: hidden;
    }
  }
}

#workInstructionsData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }

    //テーブル項目部分
    // th::after {
    //   content: "*" !important;
    //   color: red;
    // }
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}

#instructionsCreatedData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }

    //テーブル項目部分
    // th::after {
    //   content: "*" !important;
    //   color: red;
    // }
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.from_to {
  font-weight: bold;
}

.toatlNum {
  margin-left: 10px;
}

//検索条件：プルダウン
.search-autocomplete-supplier {
  width: 24rem;
}

.link {
  text-decoration: none;
}

// 数値項目右寄せ
span.number_data {
  display: block;
  text-align: right;
}

.flex-item {
  flex: 1 1 0;
  padding: 5px;
  text-align: center;
  align-items: start;
  max-width: 100%;
  display: flex;
}

.opt-hist-list-container {
  gap: 10px;
}

.tableData {
  border-collapse: collapse;
}
</style>
