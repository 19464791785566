<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_distributionProcessingPlanAdd" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 伝票No-->
            <div class="search-textbox-voucherNo">
              <v-text-field
                outlined
                dense
                v-model="txtVoucherNo"
                maxlength="50"
                :label="$t('label.lbl_voucherNo')"
                class="txt-single"
                :rules="[rules.inputRequired]"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <span class="require asterisk-spacer">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 計画日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="planDay"
                :label="$t('label.lbl_planDay')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="planDayCal" @input="dateMenu = false"></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷先-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shippingSelected"
                :items="shippingList"
                :label="$t('label.lbl_shippingTo')"
                :hint="setSrhArrival()"
                class="txt-single"
                persistent-hint
                @change="changeArrival"
                :search-input.sync="getSuppliersSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <span class="item-spacer">&nbsp;</span>

            <!-- 品質区分-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="arrivalStatusSelected"
                :items="arrivalStatusList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 責任区分-->
            <v-btn-toggle
              v-model="isRes"
              mandatory
              color="blue darken-2"
              class="toggle black--text"
            >
              <v-btn
                mandatory
                class="res-btn"
                v-for="item in blameDivList"
                :key="item.value"
                :value="item.value"
                small
                v-bind:disabled="isDisabledBlame"
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
          </v-row>
          <v-row>
            <!--2段目-->
            <div class="search-row-exeBtn first-search-item">
              <!-- 有償/無償-->
              <div style="float: left">
                <v-btn-toggle
                  mandatory
                  v-model="isPaid"
                  color="blue darken-2"
                  id="btn-toggle-btn-padding"
                >
                  <v-btn
                    class="paid-btn"
                    v-for="item in freeCostDivList"
                    :key="item.value"
                    :value="item.value"
                    small
                  >
                    {{ item.text }}</v-btn
                  >
                </v-btn-toggle>
              </div>

              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 無償理由選択プルダウン-->
              <div class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  class="list-single"
                  v-model="freeReasonSelected"
                  :items="freeReasonList"
                  v-bind:disabled="isDisabledFreeReason"
                  :label="$t('label.lbl_freeReason')"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 備考欄-->
              <div class="textbox-remarks">
                <v-text-field
                  outlined
                  dense
                  v-model="remarks"
                  maxlength="50"
                  :label="$t('label.lbl_remarksTextbox')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                />
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--画面クリアボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 画面クリアボタン -->
                <v-btn class="other-btn" @click="clearConfirm">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <!--ボタン領域-->
              <div class="btn-area">
                <!-- 追加ボタン-->
                <v-btn color="primary" class="api-btn" v-on:click="add" :disabled="isDoAddButton">
                  {{ $t("btn.btn_add") }}
                </v-btn>

                <!-- 登録ボタン-->
                <v-btn id="btn-pack" class="post-btn" value="1" @click="planAddCheck">
                  {{ $t("btn.btn_insert") }}
                </v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="580px"
            class="table-style"
          >
            <!-- 商品コード/品名-->
            <template v-slot:[`item.productCd`]="{ item, index }">
              <div id="table-productCd">
                <v-autocomplete
                  v-model="item.productCd"
                  :items="item.productList"
                  :hint="checkProductCd(item, index)"
                  :search-input.sync="item.search"
                  @change="(event) => changeProductNm(event, item, index)"
                  persistent-hint
                  dense
                  class="cursorProcd"
                  :ref="'cursorProcd' + index"
                  :rules="[$inputRules.isRequiredRow(headerItems, item)]"
                  :error-messages="item.requiredItemCheckMsg"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>
            <!-- ロット-->
            <template v-slot:[`item.lot`]="{ item, index }">
              <v-text-field
                outlined
                dense
                v-model="item.lot"
                maxlength="20"
                class="text-box"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'lotCheck' + index"
                :disabled="item.lotFlg != '1'"
              />
            </template>

            <!-- S/N -->
            <template v-slot:[`item.serial`]="{ item, index }">
              <v-text-field
                outlined
                dense
                maxlength="32"
                v-model="item.serial"
                class="text-box"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'lotCheck' + index"
                :disabled="item.serialFlg != '1'"
              />
            </template>

            <!-- 期限日-->
            <template v-slot:[`item.dueDate`]="{ item, index }">
              <v-text-field
                outlined
                dense
                maxlength="8"
                v-model="item.dueDate"
                class="text-box"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'lotCheck' + index"
                :rules="[rules.isNumber]"
                :disabled="item.dueDateFlg != '1'"
                @change="deletedueDateOtherChar(item)"
              />
            </template>

            <!-- 付属キー1 -->
            <template v-slot:[`item.lotSubkey1`]="{ item, index }">
              <v-text-field
                outlined
                dense
                v-model="item.lotSubkey1"
                maxlength="20"
                class="text-box"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'lotCheck' + index"
                :disabled="item.lotSubkey1Flg != '1'"
              />
            </template>

            <!-- 付属キー2 -->
            <template v-slot:[`item.lotSubkey2`]="{ item, index }">
              <v-text-field
                outlined
                dense
                v-model="item.lotSubkey2"
                maxlength="20"
                class="text-box"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'lotCheck' + index"
                :disabled="item.lotSubkey2Flg != '1'"
              />
            </template>

            <!-- 数量 -->
            <template v-slot:[`item.planQuantity`]="{ item, index }">
              <v-text-field
                outlined
                dense
                v-model="item.planQuantity"
                class="input-number"
                @change="changePlanQuantity(item, index)"
                persistent-hint
                :rules="[
                  rules.isNumber,
                  rules.isInteger,
                  rules.isRangeCheckPlan,
                  rules.limitNumLength,
                  $inputRules.isRequiredRow(headerItems, item),
                  (input) => {
                    let result = checkMaxAmount(input, item.productCd, item.productList);
                    return result;
                  },
                ]"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'planQuantityCheck' + index"
                :error-messages="item.requiredPlanQuantityCheckMsg"
              />
            </template>
            <!-- 収容数-->
            <template v-slot:[`item.capacityQty`]="{ item, index }">
              <v-text-field
                outlined
                dense
                v-model="item.capacityQty"
                class="input-number"
                @change="changeCapacityQty(item)"
                persistent-hint
                :rules="[
                  rules.isNumber,
                  rules.isInteger,
                  rules.isRangeCheckPlan,
                  rules.limitNumLength,
                ]"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'capacityQtyCheck' + index"
              />
            </template>
            <!-- 梱包数-->
            <template v-slot:[`item.numberOfPackages`]="{ item, index }">
              <v-text-field
                outlined
                dense
                v-model="item.numberOfPackages"
                class="input-number"
                @change="changeNumberOfPackages(item, index)"
                persistent-hint
                :rules="[
                  rules.isNumber,
                  rules.isInteger,
                  rules.isRangeCheckPlan,
                  rules.limitNumLength,
                  $inputRules.isRequiredRow(headerItems, item),
                ]"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'numberOfPackagesCheck' + index"
              />
            </template>
            <!-- 資材ボタン -->
            <template v-slot:[`item.materials`]="{ item }">
              <div id="table-MaterialsDialog">
                <v-btn small @click="openSelectMaterialsDialog(item)" text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
            <!-- 削除-->
            <template v-slot:[`item.deleteData`]="{ item }">
              <v-btn small @click="deleteItem(item)" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-form>
      <v-container fluid style="padding-top: 0px; height: 38px">
        <!--ボタン領域-->
        <v-row>
          <div class="search-row-exeBtn">
            <!--合計数量領域-->
            <div style="float: left; display: flex; align-items: center; width: 60%">
              <div
                style="margin-left: 80%; font-weight: bold; font-size: large; border-bottom: double"
              >
                <!-- 合計数量-->
                {{ $t("label.lbl_totalNum") + ":    " + this.totalCount }}
              </div>
            </div>
          </div>
        </v-row>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />

      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :changeFlag="infoDialog.changeFlag"
        :distProcPlanAddFlg="infoDialog.distProcPlanAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :okAction="infoDialog.okAction"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告のダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 資材確認モーダル -->
      <v-dialog
        v-model="selectMaterialsDialogIsShow"
        :max-width="1000"
        persistent=""
        no-click-animation
        scrollable
      >
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_materials") }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="productCdAndName"
                    :label="$t('label.lbl_productCnCd')"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    dense
                    v-model="accessoryNumber"
                    :label="$t('label.lbl_accessoryCd')"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="shippingAddress"
                    :label="$t('label.lbl_destination')"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    dense
                    v-model="quantity"
                    :label="$t('label.lbl_quantity')"
                    class="number-align-right"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    dense
                    v-model="packagingQuantity"
                    :label="$t('label.lbl_numberOfPackages')"
                    class="number-align-right"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <div>
                  <div v-if="message">
                    <!--検索結果表示-->
                    <div
                      class="text-label"
                      style="color: red; float: left; margin-top: 0px; padding-bottom: 0px"
                    >
                      <span>※{{ message }}</span>
                    </div>
                  </div>
                </div>
              </v-row>
            </v-container>

            <v-form ref="processedList" lazy-validation>
              <v-container fluid>
                <v-data-table
                  id="materialsListData"
                  fixed-header
                  disable-filtering
                  disable-sort
                  :headers="headers"
                  :items="materials"
                  :hide-default-footer="true"
                  disable-pagination
                  height="300px"
                  class="overflow-hidden"
                >
                  <!--No-->
                  <template v-slot:[`item.no`]="{ item }">
                    <span>{{ item.no }}</span>
                  </template>

                  <!--資材名-->
                  <template v-slot:[`item.material`]="{ item }">
                    <span>{{ item.material }}</span>
                  </template>

                  <!--資材数量-->
                  <template v-slot:[`item.quantity`]="{ item }">
                    <div v-if="item.colorFlg == true" style="text-align: right; color: red">
                      {{ item.quantity }}
                    </div>
                    <div v-else style="text-align: right">
                      {{ item.quantity }}
                    </div>
                  </template>
                </v-data-table>
              </v-container>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!--ボタン領域-->
              <div class="search-row-exeBtn">
                <!-- 閉じるボタン領域 -->
                <div class="btn-search-area" style="float: right">
                  <v-btn class="other-btn" @click="closeDialog">
                    {{ $t("btn.btn_close") }}
                  </v-btn>
                </div>
              </div>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
export default {
  name: appConfig.SCREEN_ID.P_TMP_901,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // 資材モーダルヘッダ表示用:品番
    productCd: "",
    // 資材モーダルヘッダ表示用:品番・品名
    productCdAndName: "",
    // 資材モーダルヘッダ表示用:出荷先
    shippingAddress: "",
    // 資材モーダルヘッダ表示用:付属品番
    accessoryNumber: "",
    // 資材モーダルヘッダ表示用:数量
    quantity: "",
    // 資材モーダルヘッダ表示用:梱包数
    packagingQuantity: "",
    // 資材モーダルヘッダー表示
    headers: [
      // No
      { text: "No", value: "no", align: "right", width: "5%" },
      // 資材名
      { text: i18n.tc("label.lbl_materials"), value: "material", align: "left", width: "50%" },
      // 数量
      { text: i18n.tc("label.lbl_quantity"), value: "quantity", align: "left", width: "30%" },
    ],
    // 資材モーダル表示用:明細一覧
    materials: [],
    // 資材モーダル：資材数量桁数チェック
    message: "",
    // 指示作成状況ダイアログ
    selectMaterialsDialogIsShow: false,
    // トータル数量
    totalCount: 0,
    loadingCounter: 0,
    // 一覧index
    editedIndex: -1,
    editedList: [],
    // 伝票番号
    txtVoucherNo: "",
    // 有償/無償
    isPaid: "01",
    // 無償理由
    freeReasonSelected: "",
    //備考欄
    remarks: "",
    // 取引先
    suppliersSelected: "",
    // 出荷先
    shippingSelected: "",
    // メニュー
    openMenu: null,
    // 計画日付キャレンダー
    dateMenu: false,
    // 計画日付
    planDayCal: "",
    planDay: "",
    // 品質区分
    arrivalStatusSelected: "01",
    // 責任区分
    blameDivList: [],
    // 責任
    isRes: "01",
    isResBadValue: "02",
    isResBadCount: 0,
    isDisabledBlame: true,
    // 入荷ステータス
    arrivalStatusList: [],
    selectMaterialsDialogInput: {},
    // フォーカス移動フラグ
    moveFocusFlag: false,
    // 追加ボタンdisabledフラグ
    isDoAddButton: false,
    // コンファームダイアログ表示用
    ConfirmDialog: {
      screenFlag: false,
      changeFlag: false,
      message: "",
      redMessage: "",
      title: "",
      isOpen: false,
      okAction: () => {},
    },
    // シンプルダイアログ表示用
    infoDialog: {
      title: "",
      message: "",
      isOpen: false,
      distProcPlanAddFlg: false,
      firstPageFlag: false,
      outsideClickNotCloseFlg: false,
      okAction: () => {},
    },
    // 警告ダイアログメッセージ
    backMessage: "",
    // 警告ダイアログフラグ
    updateDialogMessage: false,
    // 必須項目未入力エラーメッセージ
    requiredItemCheckMsg: "",
    // 流通加工計画一覧ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        class: "listHeader",
        value: "No",
        width: "50px",
        sortable: false,
        align: "right",
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "300px",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_lot"),
        value: "lot",
        width: "220px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_serial"),
        value: "serial",
        width: "250px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_dueDate"),
        value: "dueDate",
        width: "220px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_scheduleQuantity"),
        value: "planQuantity",
        width: "220px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_capacityQty"),
        value: "capacityQty",
        width: "220px",
        align: "center",
        class: "asta",
      },
      {
        text: i18n.tc("label.lbl_numberOfPackages"),
        value: "numberOfPackages",
        width: "220px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_materials"),
        value: "materials",
        align: "center",
        width: "50px",
      },
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "50px", align: "center" },
      {
        text: i18n.tc("label.lbl_lotSubkey1"),
        value: "lotSubkey1",
        width: "220px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_lotSubkey2"),
        value: "lotSubkey2",
        width: "220px",
        align: "center",
      },
    ],
    inputList: [],
    // 取引先プルダウン
    supplierList: [],
    // 出荷先プルダウン
    shippingList: [],
    productHintArrival: "",
    getSuppliersSelected: "",
    defaultItem: {
      productNm: "",
      productCd: "",
      productHint: "",
      accessoryCd: "",
      planQuantity: "",
      capacityQty: "",
      numberOfPackages: "",
      lot: "",
      serial: "",
      dueDate: "",
      lotSubkey1: "",
      lotSubkey2: "",
      productList: [],
      isNew: false,
      search: "",
      isAdd: true,
    },
    // 無償理由プルダウン
    freeReasonList: [],
    freeCostDivList: [],
    focusIdx: 0,
    isDisabledFreeReason: true,
    // 初期データ
    defaultData: [],
    rules: {
      // 日付フォーマットチェック
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      // 必須チェック
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      // 文字数の制約
      limitNumLength: (value) =>
        value == null ||
        String(value).replaceAll(",", "").length < 10 ||
        i18n.tc("check.chk_limitNumLength"),
      // 数値チェック
      isNumber: (value) =>
        value == null ||
        value == "" ||
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      // 整数チェック
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      // 1以上を許容
      isRangeCheckPlan: (value) =>
        value == null ||
        value == "" ||
        1 <= Number(commonUtil.zen2han(value).replaceAll(",", "")) ||
        i18n.tc("check.chk_inputMore"),
    },
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      if (this.suppliersSelected) {
        // 連続登録の場合
        this.shippingSelected = ""; // 出荷先
        // this.continueAddFlg = true; // 連続登録フラグ
        this.freeReasonSelected = ""; // 無償理由
        this.arrivalStatusSelected = "01"; // 品質区分
        this.txtVoucherNo = ""; // 伝票No
        this.remarks = ""; // 備考
        this.totalCount = 0; // トータル数量
        this.inputList = []; // 明細一覧初期化
        console.log(dateTimeHelper.convertJST());
        this.planDay = dateTimeHelper.convertJST().substr(0, 10); // 作業日
        this.planDayCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        for (var i = 0; i < 10; i++) {
          this.defaultItem.No = i + 1;
          this.defaultItem.search = "";
          this.inputList.push(Object.assign({}, this.defaultItem));
        } // 明細一覧空行を追加
        // 一覧のバリデーションチェックをリセット
        this.$refs.editedList.resetValidation();
        // マスタ取得
        this.getMstCode();
      } else {
        // 初期画面
        this.freeReasonSelected = ""; // 無償理由
        this.arrivalStatusSelected = "01"; // 品質区分
        this.txtVoucherNo = ""; // 伝票No
        this.remarks = ""; // 備考
        this.totalCount = 0; // トータル数量
        this.planDayCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST()); // 作業日
        this.planDay = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        for (var j = 0; j < 10; j++) {
          this.defaultItem.No = j + 1;
          this.inputList.push(Object.assign({}, this.defaultItem));
        } // 明細一覧空行を追加
        // マスタ取得
        this.getMstCode();
      }
      // 項目初期化時に発生するバリデーションチェックをキャンセルする
      this.$refs.form.resetValidation();
    },

    /**
     * 取引先情報を取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          // 取引先が1件の場合、取引先を自動選択する
          this.supplierList.length == 1
            ? (this.suppliersSelected = this.supplierList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * コードマスタ情報を取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);
      // 処理区分
      const processList = getParameter.getCodeMst(appConfig.CODETYPE.IN_PROCESS_DIV);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 責任区分
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);
      Promise.all([qualityList, reasonList, processList, freeCostList, blameList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.arrivalStatusList = result[0];
          this.freeReasonList = result[1];
          this.freeCostDivList = result[3];
          // 責任区分の最初のなしを削除
          result[4].splice(0, 1);
          this.blameDivList = result[4];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 流通加工計画登録画面：メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 流通加工計画登録画面：計画日付を－１日
     */
    prevDate() {
      let date = new Date(this.planDayCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.planDayCal == null) {
        return;
      }
      if (this.planDayCal != toDate) {
        this.planDayCal = toDate;
      } else {
        this.planDayCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 流通加工計画登録画面：計画日付を＋１日
     */
    nextDate() {
      let date = new Date(this.planDayCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.planDayCal == null) {
        return;
      }
      if (this.planDayCal != toDate) {
        this.planDayCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.planDayCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * 流通加工計画登録画面：与えられた日付から年月日を文字列で返却処理
     */
    dateCalc(date) {
      const dt = new Date(date);
      let year = dt.toISOString().substr(0, 4);
      let month = dt.toISOString().substr(5, 2);
      let day = dt.toISOString().substr(8, 2);
      // ISO時間
      const todayISOTime = dt.toISOString().substr(11, 2);
      if (todayISOTime >= 15) {
        // 月末
        if (
          (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10) &&
          day == 31
        ) {
          // 31日まである月
          month = Number(month) + 1;
          day = "1";
        } else if ((month == 4 || month == 6 || month == 9 || month == 11) && day == 30) {
          // 30日まである月
          month = Number(month) + 1;
          day = "1";
        } else if (month == 2) {
          if (year % 4 == 0 && day == 29) {
            // 2月29日まである年
            month = Number(month) + 1;
            day = "1";
          } else if (year % 4 != 0 && day == 28) {
            // 2月28日まである年
            month = Number(month) + 1;
            day = "1";
          }
        } else if (month == 12 && day == 31) {
          // 12月
          year = Number(year) + 1;
          month = "1";
          day = "1";
        } else {
          day = Number(day) + 1;
        }
        if (month < 10 && month.length != 2) {
          month = "0" + month;
        }
        if (day < 10 && day.length != 2) {
          day = "0" + day;
        }
      }
      return year + "-" + month + "-" + day;
      // return dt.toISOString().substr(0, 10);
    },

    // クリアボタン押下時
    clearConfirm() {
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.clear;
    },

    // クリア処理
    clear() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 連続登録の場合
      // if (this.continueAddFlg) {
      //   this.suppliersSelected = "";
      //   // 連続登録フラグ
      //   this.continueAddFlg = false;
      // }
      // 変更検知用の設定
      this.planDayCal = "";
      // 初期化
      this.init();

      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    /**
     * 警告ダイヤログ：OK
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 予定数量チェック
     */
    checkMaxAmount(scheduleQuantity, itemCd, itemList) {
      if (scheduleQuantity && itemCd) {
        var qty = Number(commonUtil.zen2han(scheduleQuantity).replaceAll(",", ""));
        var maxQty = itemList.find((data) => data.value === itemCd).maxStockValue;
        if (!isNaN(Number(qty))) {
          let isValid = maxQty <= qty;

          if (isValid) {
            return (
              commonUtil.formatToCurrency(Number(maxQty)) + i18n.tc("check.chk_underScheduleStock")
            );
          }
        }
      }
      return true;
    },

    /**
     * 加工計画登録前にバリエーションチェック
     */
    planAddCheck() {
      let cnt = 0;
      let countCheckFlg = true;
      this.alertMessage = "";
      if (this.$refs.form.validate() && this.$refs.editedList.validate()) {
        // 明細部未入力チェック準備
        let checkList = []; // 明細部チェックリスト
        for (let i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productCd) {
            // 明細部の品番品名が入力されている場合はリストに追加
            checkList.push(this.inputList[i].productCd);
          } else {
            // 上記以外の場合は処理なし。
          }
        }

        // 明細部の未入力チェック
        if (checkList.length <= 0) {
          // 上記でのチェックリストが0件の場合は、警告メッセージ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-RCV-001_006_E");
          return;
        } else {
          // 処理なし
        }

        // 明細部、入力チェック実施
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productCd) {
            var checkPlanQtyNum = Number(
              commonUtil.zen2han(this.inputList[i].planQuantity).replaceAll(",", "")
            );
            var checkCapacityQtyNum = Number(
              commonUtil.zen2han(this.inputList[i].capacityQty).replaceAll(",", "")
            );
            var checkPackNum = Number(
              commonUtil.zen2han(this.inputList[i].numberOfPackages).replaceAll(",", "")
            );

            if (checkPlanQtyNum == "" || checkPlanQtyNum == null) {
              // 計画予定数が未入力時
              cnt = i + 1;
            } else {
              // 処理なし
            }

            // 数値チェック
            if (isNaN(checkPlanQtyNum) || isNaN(checkCapacityQtyNum) || isNaN(checkPackNum)) {
              // 予定数、収容数、梱包数が数値でない時
              cnt = i + 1;
            } else {
              // 処理なし
            }

            // 整数チェック
            if (
              Number.isInteger(checkPlanQtyNum) &&
              Number.isInteger(checkCapacityQtyNum) &&
              Number.isInteger(checkPackNum)
            ) {
              // 処理なし
            } else {
              // 予定数、収容数、梱包数がが整数でない時
              cnt = i + 1;
            }

            // 範囲チェック（0未満）
            if (0 < checkPlanQtyNum && 0 < checkCapacityQtyNum && 0 < checkPackNum) {
              // 処理なし
            } else {
              // 予定数、収容数、梱包数が0未満の場合
              cnt = i + 1;
            }
          }
        }
        // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
        if (cnt == 0 && countCheckFlg) {
          if (this.isPaid == "02") {
            this.ConfirmDialog.screenFlag = false;
            this.ConfirmDialog.changeFlag = true;
            this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_004_C");
            this.ConfirmDialog.redMessage = "無償";
            this.ConfirmDialog.title = "警告";
            this.ConfirmDialog.isOpen = true;
            this.ConfirmDialog.okAction = this.planRegistion;
          } else {
            this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
            this.ConfirmDialog.isOpen = true;
            this.ConfirmDialog.screenFlag = true;
            this.ConfirmDialog.changeFlag = false;
            this.ConfirmDialog.okAction = this.planRegistion;
          }
        } else {
          // 明細部、入力エラー時（カウント1）
          if (this.$refs.editedList.validate()) {
            // 処理なし
          } else {
            for (var j = 0; j < this.inputList.length; j++) {
              let checkPosition = String(j);
              if (!this.inputList[j].productCd) {
                // 関係ないレコードの入力チェックエラーを解除
                eval("this.$refs.planQuantityCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.capacityQtyCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.numberOfPackagesCheck" + checkPosition + ".resetValidation()");
              } else {
                // 処理なし
              }
            }
          }
        }
      } else {
        // 伝票部、入力チェックエラーの場合、処理なし
      }
    },

    /**
     *  加工計画登録処理（POST)
     */
    planRegistion() {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();
        // 共通IF項目 画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TMP_901;

        // 計画明細List
        let bodyList = [];

        for (let i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productCd != "" && this.inputList[i].productCd != null) {
            var inputMap = {};
            // 出荷先：toSID
            inputMap.toSid = this.shippingSelected;
            // 商品標準SID
            inputMap.itemStandardSid = this.inputList[i].productCd;
            // 商品コード
            for (let m = 0; m < this.inputList[i].productList.length; m++) {
              if (this.inputList[i].productList[m].value == this.inputList[i].productCd) {
                inputMap.itemCd = this.inputList[i].productList[m].code;
                break;
              }
            }
            // 付属品番
            if (this.inputList[i].accessoryCd) {
              inputMap.itemSubCd = this.inputList[i].accessoryCd;
            } else {
              inputMap.itemSubCd = "";
            }
            // ロット
            inputMap.lotNo = this.createLot(this.inputList[i]);

            // 予定数量
            inputMap.shipScheduleQuantity = this.inputList[i].planQuantity.replaceAll(",", "");
            // 収容数
            inputMap.shipUnitQuantity = this.inputList[i].capacityQty.replaceAll(",", "");
            // 梱包数
            inputMap.shipPackingQuantity = this.inputList[i].numberOfPackages.replaceAll(",", "");

            bodyList.push(inputMap);
          }
        }
        // 取引先SID
        body.reqIdv.clientSid = this.suppliersSelected;
        // 計画No
        body.reqIdv.outListNo = this.txtVoucherNo;
        // 計画日
        body.reqIdv.outScheduleDate = dateTimeHelper.convertUTC(this.planDay);
        // 有償無償区分
        body.reqIdv.freeCostDiv = this.isPaid;
        // 責任区分
        body.reqIdv.blameDiv = this.isRes;
        // 無償理由
        body.reqIdv.reasonCd = this.freeReasonSelected;
        // 備考
        body.reqIdv.remarks = this.remarks;
        // 出荷先：toSID
        body.reqIdv.toSid = this.shippingSelected;
        // FromSid
        body.reqIdv.fromSid = sessionStorage.getItem("sales_office_sid");
        // 品質区分
        body.reqIdv.qualityDiv = this.arrivalStatusSelected;
        // 加工明細リスト
        body.reqIdv.processingScheduleList = bodyList;
        // console.debug("planRegistion() Body", body);

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.DST_PROCESS_PLAN_ADD, body, appConfig.APP_CONFIG)
            .then((response) => {
              // console.debug("planRegistion() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = false;
                this.infoDialog.outsideClickNotCloseFlg = true;
                // 画面遷移ボタン
                this.infoDialog.distProcPlanAddFlg = true;
                // 連続登録する場合：画面を初期化
                this.infoDialog.okAction = this.distProcPlanAdd;
                resolve();
              } else {
                // エラーメッセージをpopupのmessageに格納
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                this.infoDialog.distProcPlanAddFlg = false;
                this.infoDialog.outsideClickNotCloseFlg = false;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex.message;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.distProcPlanAddFlg = false;
              this.infoDialog.outsideClickNotCloseFlg = false;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 分割されているlotNoの構築
     */
    createLot(item) {
      return commonUtil.createLotNo(
        item.lot,
        item.serial,
        item.dueDate,
        item.lotSubkey1,
        item.lotSubkey2
      );
    },

    /**
     * 登録後に当画面に遷移:画面初期化
     */
    distProcPlanAdd() {
      this.init();
    },

    /**
     * 流通加工計画登録画面：yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 流通加工計画登録画面：行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1);

      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }
      // console.debug("行削除");
      var proQty = commonUtil.zen2han(item.planQuantity).replaceAll(",", "");
      var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");

      // 予定数合計から削除したレコードの数を引く
      this.totalCount = Number(totalNum) - Number(proQty);
      // カンマ編集
      this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));

      this.$refs["planQuantityCheck" + this.editedIndex].resetValidation();
      this.$refs["capacityQtyCheck" + this.editedIndex].resetValidation();
      this.$refs["numberOfPackagesCheck" + this.editedIndex].resetValidation();
      this.$refs["lotCheck" + this.editedIndex].resetValidation();
    },

    /**
     * 加工計画登録画面：入荷ステータスリストボックス取得処理
     */
    setStsArrival() {
      for (var i = 0; i < this.arrivalStatusList.length; i++) {
        if (this.arrivalStatusList[i].value == this.arrivalStatusSelected) {
          return this.arrivalStatusList[i].name;
        }
      }
    },

    /**
     * 入力項目バリデーション
     */
    validationRow(item) {
      var editedIndex = this.inputList.indexOf(item);

      var result =
        this.$refs["cursorProcd" + editedIndex].validate() &&
        this.$refs["planQuantityCheck" + editedIndex].validate() &&
        this.$refs["numberOfPackagesCheck" + editedIndex].validate();

      return result;
    },

    /**
     * 資材確認モーダルを表示
     */
    openSelectMaterialsDialog(item) {
      // モーダル初期化
      this.productCd = ""; // 品番
      this.itemStandardSid = ""; // 商品標準SID
      this.productCdAndName = ""; // 品名
      this.accessoryNumber = ""; // 付属品番
      this.quantity = ""; // 数量
      this.packagingQuantity = ""; // 梱包数
      this.materials = []; // 資材一覧を初期化
      this.message = ""; // エラー
      // 予定数、収容数、梱包数入力チェック
      if (!item.productCd || !item.planQuantity || !item.numberOfPackages) {
        return;
      }
      if (!this.validationRow(item)) {
        return;
      } else {
        // 資材確認ダイアログ用のデータを作成する
        this.productCd = item.productList.find((data) => data.value === item.productCd).code; // 品番
        this.itemStandardSid = item.productCd; // 商品標準SID
        this.productCdAndName =
          this.productCd.substring(0, 50).trim() +
          " " +
          this.productCd.slice(-50).trim() +
          " (" +
          item.productNm +
          ")"; // 品名
        this.accessoryNumber = item.accessoryCd; // 付属品番
        this.quantity = item.planQuantity; // 数量
        this.packagingQuantity = item.numberOfPackages; // 梱包数

        // 資材取得APIを呼び出す
        this.getMaterials();
        // モーダル表示フラグ
        this.selectMaterialsDialogIsShow = true;
      }
    },

    /**
     * 資材モダール：資材取得API(GET)
     */
    getMaterials() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // 商品標準SID
      config.params.itemStandardSid = this.itemStandardSid;

      // console.debug("getMaterials() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.DST_MATERIAL_LIST, config)
          .then((response) => {
            // this.dispList.splice(0);
            // console.debug("getMaterials() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            const apiData = jsonData.resIdv.processingMaterial;
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              // レスポンスで取得した明細行データをリストに詰める
              for (var i = 0; i < apiData.length; i++) {
                list.push({
                  // NO
                  no: i + 1,
                  // 資材品名
                  material: apiData[i].itemName,
                  // 数量
                  quantity: this.matchMaterialQuantity(apiData[i].composeQuantity),
                  // 空フラグ
                  colorFlg: false,
                });
              }
              // テーブルデータリストに明細行データを設定
              this.materials = list;
              for (var j = 0; j < this.materials.length; j++) {
                if (this.materials[j].quantity.replaceAll(",", "").length > 9) {
                  this.materials[j].colorFlg = true;
                }
              }
              if (this.materials.filter((data) => data.colorFlg == true).length > 0) {
                this.message = messsageUtil.getMessage("P-DST-001_005_E");
              }
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 資材確認モダール：数量を精算
     */
    matchMaterialQuantity(val) {
      let materialQuantity = Number(commonUtil.zen2han(val).replaceAll(",", ""));
      let itemQuantity = Number(commonUtil.zen2han(this.quantity).replaceAll(",", ""));

      materialQuantity = materialQuantity * itemQuantity;

      return commonUtil.formatToCurrency(Number(materialQuantity));
    },

    /**
     * 資材確認モーダルを閉じる
     */
    closeDialog() {
      this.selectMaterialsDialogIsShow = false;
    },

    /**
     * 流通加工計画登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 加工計画登録画面：出荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      for (var i = 0; i < this.shippingList.length; i++) {
        if (this.shippingList[i].value == this.shippingSelected) {
          this.shippingAddress = this.shippingList[i].name; // 資材モダール用：出荷先名
          return this.shippingList[i].name;
        }
      }
    },

    /**
     * プルダウンチェンジイベント
     */
    changeArrival(value) {
      // 既存品番/品名を選択時
      let val = this.shippingList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintArrival = val.text;
        this.getSuppliersSelected = val.text;
      }
      this.shippingList = this.shippingList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintArrival = "";
      } else {
        // 処理なし
      }
    },

    /**
     * 流通加工計画登録画面：商品コードリストボックス取得処理
     */
    checkProductCd(value, index) {
      return this.inputList[index].productHint;
    },

    changeProductNm(value, item) {
      // 既存品番/品名を選択時
      let val = item.productList.find((v) => v.value == value);
      if (val ?? false) {
        item.productNm = val.name;
        item.productHint = val.text;
        item.productList = item.productList.filter((v) => v.value == value);
        item.accessoryCd = val.code.slice(-50).trim();
        item.capacityQty = commonUtil.formatToCurrency(
          val.outboundUnitQuantity ? val.outboundUnitQuantity : 0
        );
        item.serialFlg = val.serialFlg;
        item.lotFlg = val.lotFlg;
        item.dueDateFlg = val.dueDateFlg;
        item.lotSubkey1Flg = val.lotSubkey1Flg;
        item.lotSubkey2Flg = val.lotSubkey2Flg;
      }
    },

    /**
     * 流通加工計画登録画面：行追加処理
     */
    add() {
      this.isDoAddButton = true;

      if (this.inputList.length != 0) {
        this.focusIdx = this.inputList[this.inputList.length - 1].No;
      } else {
        this.focusIdx = 0;
      }

      for (var i = 0; i < 10; i++) {
        this.defaultItem.No = this.focusIdx + i + 1;
        this.inputList.push(Object.assign({}, this.defaultItem));
      }
      // フォーカス移動フラグをONに
      this.moveFocusFlag = true;
      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
      // console.debug("行追加");
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDoAddButton = false;
    },

    /**
     * 数値のみ入力
     */
    deletedueDateOtherChar(item) {
      item.dueDate = item.dueDate.replace(/\D/g, "");
    },

    /**
     * 流通加工計画登録画面：入力カンマ処理 計画数
     */
    changePlanQuantity(val, index) {
      var planQuantity = commonUtil.zen2han(val.planQuantity).replaceAll(",", "");
      planQuantity = planQuantity.replace(/[^0-9]/gi, "");
      if (planQuantity == "-0") {
        planQuantity = "0";
      }
      if (planQuantity.length > 9) {
        planQuantity = planQuantity.substring(0, 9);
      }

      // 選択した商品の出庫単位数を取得する
      let capacityQty = this.inputList[index].capacityQty;
      capacityQty = commonUtil.formatToCurrency(Number(capacityQty));

      if (!isNaN(Number(planQuantity))) {
        this.inputList[index].planQuantity = commonUtil.formatToCurrency(Number(planQuantity));
        if (0 < capacityQty) {
          // 計画予定数が入力されている場合
          this.inputList[index].numberOfPackages = commonUtil.formatToCurrency(
            Math.ceil(planQuantity / capacityQty)
          );
          // カンマ編集して数量にセット
        } else {
          this.inputList[index].capacityQty = "0";
          this.inputList[index].numberOfPackages = "0";
        }
      }
      var totalNum = 0;
      this.totalCount = 0;
      if (!isNaN(Number(planQuantity)) || Number(totalNum)) {
        for (var j = 0; j < this.inputList.length; j++) {
          var proNum = commonUtil.zen2han(this.inputList[j].planQuantity).replaceAll(",", "");

          // 各行の予定数の合計を計算
          totalNum = Number(totalNum) + Number(proNum);
        }
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));
      }
    },

    /**
     * 流通加工計画登録画面：入力カンマ処理 収容数
     */
    changeCapacityQty(val) {
      let capacityQty = commonUtil.zen2han(val.capacityQty).replaceAll(",", "");
      capacityQty = capacityQty.replace(/[^0-9]/gi, "");
      if (capacityQty == "-0") {
        capacityQty = "0";
      }
      if (capacityQty.length > 9) {
        capacityQty = capacityQty.substring(0, 9);
      }
      if (!isNaN(Number(capacityQty))) {
        val.capacityQty = commonUtil.formatToCurrency(Number(capacityQty));
      }
      // 選択した商品の予定数を取得する
      let productQty = commonUtil.zen2han(val.planQuantity).replaceAll(",", "");

      if (!isNaN(Number(capacityQty))) {
        val.capacityQty = commonUtil.formatToCurrency(Number(capacityQty));
        if (0 < capacityQty) {
          // 計画予定数が入力されている場合
          val.numberOfPackages = commonUtil.formatToCurrency(Math.ceil(productQty / capacityQty));
          // カンマ編集して数量にセット
        } else {
          val.capacityQty = "0";
          val.numberOfPackages = "0";
        }
      }
    },

    /**
     * 流通加工計画登録画面：入力カンマ処理 梱包数
     */
    changeNumberOfPackages(val, index) {
      var numberOfPackages = commonUtil.zen2han(val.numberOfPackages).replaceAll(",", "");
      numberOfPackages = numberOfPackages.replace(/[^0-9]/gi, "");
      if (numberOfPackages == "-0") {
        numberOfPackages = "0";
      }
      if (numberOfPackages.length > 9) {
        numberOfPackages = numberOfPackages.substring(0, 9);
      }
      if (!isNaN(Number(numberOfPackages))) {
        this.inputList[index].numberOfPackages = commonUtil.formatToCurrency(
          Number(numberOfPackages)
        );
      }
    },

    /**
     * 流通加工計画登録画面：日付入力処理
     */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.planDayCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.planDayCal = null;
      }
    },

    /**
     * 流通加工計画登録画面：カーソル位置処理
     */
    setCursor() {
      let listLength = this.inputList.length;
      let cursolPosition = String(listLength - 10);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },
  },

  computed: {},
  watch: {
    planDayCal: {
      handler(val) {
        this.planDay = this.formatDate(val);
      },
      deep: true,
    },

    /**
     * 計画日変更
     */
    planDay: {
      handler() {
        if (dateTimeHelper.isDisplayDateFormat(this.planDay)) {
          // 加工日がyyyy/MM/dd形式の場合
          // 取引先を取得する処理
          this.getCustomInfo(this.planDay);
        } else {
          // 上記以外の場合、処理なし
        }
      },
      deep: true,
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },

    /**
     * 取引先変更
     */
    suppliersSelected() {
      // 取引先を変更する場合はクリアする
      if (this.inputList.length != 0) {
        this.inputList.splice(0, this.inputList.length);
        for (var j = 0; j < 10; j++) {
          this.defaultItem.No = j + 1;
          this.inputList.push(Object.assign({}, this.defaultItem));
        }
      }
      this.totalCount = 0;
    },

    /**
     * 責任区分変更
     */
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
      }
    },

    /**
     * 出荷先取得
     */
    getSuppliersSelected(val) {
      if (val ?? false) {
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          this.shippingList = [];
          this.shippingSelected = "";
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintArrival) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得
          let officeCd = this.supplierList.find(
            (element) => element.value === this.suppliersSelected
          ).clientSid;
          // To（出荷先）取得
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(),
            undefined,
            officeCd,
            val
          );
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.shippingList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.shippingList.length = 0;
          this.shippingList = [];
        }
      }
    },

    /**
     * 品質区分変更
     */
    arrivalStatusSelected: function (newVal) {
      if (newVal == "02") {
        this.isDisabledBlame = false;
        this.isRes = this.isResBadValue;
        this.isResBadCount++;
      } else {
        if (this.isResBadCount > 0) {
          this.isResBadValue = this.isRes;
          this.isRes = "01";
          this.isDisabledBlame = true;
        }
      }
    },

    /**
     * 明細一覧の商品取得
     */

    inputList: function (inputL) {
      inputL.forEach((value) => {
        if (value.isWatchAdded === void 0) {
          this.$watch(
            () => value.search,
            (val) => {
              if (val ?? false) {
                // 取引先が選択されてない場合、処理しない
                if (this.suppliersSelected == null || this.suppliersSelected == "") {
                  return;
                }
                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val == value.productHint) {
                  return;
                }

                // クリア処理
                value.productCd = "";
                value.productHint = "";
                value.productNm = "";
                value.productHint = "";
                value.isNew = false;
                value.accessoryCd = null;
                value.lot = null;
                value.planQuantity = null;
                value.capacityQty = null;
                value.numberOfPackages = null;

                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  const itemsBiz = getParameter.getItemsBizAddName2(
                    this.suppliersSelected,
                    dateTimeHelper.convertUTC(this.planDay),
                    val
                  );

                  Promise.all([itemsBiz])
                    .then((result) => {
                      // 画面の初期値を設定します。
                      value.productList = result[0];
                      // console.debug("inputList: function (inputL) Result[0]", result[0]);
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.productList.length = 0;
                }
              }
            }
          );
          this.$watch((val) => {
            if (val ?? false) {
              // 取引先が選択されてない場合、処理しない
              if (this.suppliersSelected == null || this.suppliersSelected == "") {
                return;
              }
            }
          });
        }
        value.isWatchAdded = true;
      });
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
}

.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}

// 数値項目右寄せ
::v-deep .number-align-right input {
  text-align: right;
}

::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

#lbl-user-name {
  // font-size: $menu-fontsize !important;
  font-weight: bold;

  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}
// 計画登録：明細
#listSumDataSpecial {
  ::-webkit-scrollbar-thumb {
    background: red;
  }

  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 0rem;
    }

    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
      height: 0px;
    }

    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #ffffff;
    }

    // テーブルデータ部分
    td {
      font-size: large;

      &:nth-child(1) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(2) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(3) {
        text-align: right;
        width: 11%;
      }

      &:nth-child(4) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(5) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(6) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(7) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(8) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(9) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(10) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(11) {
        text-align: right;
        width: 5%;
      }
    }
  }

  white-space: nowrap;
  pointer-events: none;
  font-weight: 700;
}

// 資材モダール：テーブルスタイル
#materialsListData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2rem !important;
      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}

.tableData {
  border-collapse: collapse;
}

.table-style {
  overflow-x: auto;
}
</style>
