<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <!-- 出荷予定データ取込 -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_shipdataDifferenceImport" />
      <v-form ref="searchForm" lazy-validation>
        <v-container fluid>
          <!-- 検索項目 -->
          <!-- １行目 -->
          <v-row class="search-row">
            <!--  取引先 -->
            <div class="search-autocomplete first-search-item" style="width: 450px">
              <v-autocomplete
                dense
                v-model="searchForm.suppliersSelected"
                :items="searchForm.supplierList"
                :label="$t('label.lbl_supplier')"
                persistent-hint
                class="txt-single"
                :hint="setSrhSupplier()"
                :rules="[rules.inputRequired]"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷予定日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="searchForm.outScheduleDate"
                :label="$t('label.lbl_shippingScheduleDate')"
                @change="changeDate('outScheduleDateCal', $event)"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[rules.yyyymmdd]"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="searchForm.dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="searchForm.outScheduleDateCal"
                  @input="searchForm.dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-out-schedule-date-yesterday"
                class="day-ctrl-btn"
                @click="prevDate('outScheduleDateCal')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-out-schedule-date-tomorow"
                class="day-ctrl-btn"
                @click="nextDate('outScheduleDateCal')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 納品日 -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="searchForm.deliveryDate"
                :label="$t('label.lbl_delDate')"
                @change="changeDate('deliveryDateCal', $event)"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[
                  rules.yyyymmdd,
                  rules.isFutureDate(
                    searchForm.deliveryDate,
                    searchForm.outScheduleDate,
                    $t('label.lbl_shippingScheduleDate')
                  ),
                ]"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="searchForm.dateMenu2"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="searchForm.deliveryDateCal"
                  @input="searchForm.dateMenu2 = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-delivery-date-yesterday"
                class="day-ctrl-btn"
                @click="prevDate('deliveryDateCal')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-delivery-date-tomorow"
                class="day-ctrl-btn"
                @click="nextDate('deliveryDateCal')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <div class="file-input">
              <v-file-input
                prepend-icon=""
                :label="$t('label.lbl_importFile')"
                outlined
                dense
                v-model="importfile"
                counter
                :rules="this.compareBtnFileFlg ? [] : [rules.fileRequired]"
                append-outer-icon="mdi-paperclip"
                @click:append-outer="handleClickAppendOuter"
                ref="vFileInput"
                :error-messages="alertMessage"
              >
              </v-file-input>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 差分取込ボタン -->
            <v-btn class="post-btn" @click="search()">
              {{ $t("btn.btn_compareImport") }}
            </v-btn>
          </v-row>
          <!-- 2行目 -->
          <v-row>
            <div class="search-row-exeBtn first-search-item">
              <div class="btn-area" style="float: left">
                <!-- 画面クリアボタン -->
                <v-btn class="other-btn" @click="compareClear()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>

              <div class="btn-area">
                <!-- 処置確認ボタン -->
                <v-btn class="post-btn" @click="compareConfirm()">
                  {{ $t("btn.btn_diffComparison") }}</v-btn
                >
              </div>

              <!--数量合計領域-->
              <div
                style="
                  float: left;
                  display: flex;
                  align-items: center;
                  width: 70%;
                  flex-direction: row-reverse;
                "
              >
                <div
                  style="font-weight: bold; font-size: large; border-bottom: double; width: 200px"
                >
                  <!-- 数量合計-->
                  {{ $t("label.lbl_totalQty") + " " + sumCount.toLocaleString() }}
                </div>
              </div>
            </div>
          </v-row>
          <v-row>
            <span class="item-spacer">&nbsp;</span>
            <!--検索結果件数表示-->
            <div class="text-label" style="float: left">
              <span class="toatlNum">{{
                $t("label.lbl_total") + "：" + totalCount + $t("label.lbl_number")
              }}</span>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :server-items-length="searchForm.itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="610px"
          :options.sync="sortOptions"
        >
          <!-- ヘッダーロット -->
          <template v-slot:[`header.lot`]="{ header }">
            <span>
              {{ header.text }}<br />
              {{
                $t("label.lbl_dueDate") +
                "／" +
                $t("label.lbl_lotSubkey1") +
                "／" +
                $t("label.lbl_lotSubkey2")
              }}
            </span>
          </template>

          <template v-slot:[`item.No`]="{ item }">
            <!-- No -->
            <div class="listNo-style">{{ item.no }}</div>
          </template>
          <template v-slot:[`item.outListNo`]="{ item }">
            <!-- 出荷No -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 140px" v-bind="attrs" v-on="on">
                  {{ item.outListNo }}
                </div>
              </template>
              <span class="text-overflow">{{ item.outListNo }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.outScheduleDate`]="{ item }">
            <!-- 出荷予定日 -->
            <div v-bind:class="{ isD: item.isShipInsDateFlg }">{{ item.outScheduleDate }}</div>
          </template>
          <template v-slot:[`item.itemCd`]="{ item }">
            <!-- 品番/付帯品番 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 140px" v-bind="attrs" v-on="on">
                  {{ item.itemCd }}<br />
                  {{ item.accessoryProduct }}
                </div>
              </template>
              <span class="text-overflow">
                {{ item.itemCd }}<br />
                {{ item.accessoryProduct }}</span
              >
            </v-tooltip>
          </template>
          <template v-slot:[`item.itemName`]="{ item }">
            <!-- 品名 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 140px" v-bind="attrs" v-on="on">
                  {{ item.itemName }}
                </div>
              </template>
              <span class="text-overflow">{{ item.itemName }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.lot`]="{ item }">
            <!-- ロット/S/N -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 280px" v-bind="attrs" v-on="on">
                  {{ item.lot }}<br />
                  {{ item.dueDate }}
                </div>
              </template>
              <span class="text-overflow">
                {{ item.lot }}<br />
                {{ item.dueDate }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.dbQuantity`]="{ item }">
            <!-- DB数量 -->
            {{ item.dbQuantity }}
          </template>
          <template v-slot:[`item.csvQuantity`]="{ item }">
            <!-- CSV数量 -->
            {{ item.csvQuantity }}
          </template>
          <template v-slot:[`item.instruction`]="{ item }">
            <!-- 指示 -->
            <a
              v-show="isNotEmpty(item.instructionOfReservation)"
              style="font-size: 70%; text-decoration: underline; margin-left: 4px; display: block"
              @click="openUpdateDelete('P-STK-002')"
              >{{ item.instructionOfReservation }}</a
            >
            <a
              v-show="isNotEmpty(item.instructionOfShippingCancel)"
              style="
                display: block;
                font-size: 70%;
                text-decoration: underline;
                text-decoration: none;
                color: black;
                pointer-events: none;
              "
            >
              {{ item.instructionOfShippingCancel }}
            </a>
            <a
              v-show="isNotEmpty(item.instructionOfShipCancel)"
              style="
                font-size: 70%;
                text-decoration: none;
                color: black;
                pointer-events: none;
                display: block;
              "
            >
              {{ item.instructionOfShipCancel }}
            </a>
            <a
              v-show="isNotEmpty(item.instructionOfShipOrder)"
              style="font-size: 70%; text-decoration: underline; margin-left: 4px; display: block"
              @click="openUpdateDelete('P-SHP-003')"
              >{{ item.instructionOfShipOrder }}</a
            >
          </template>
          <!-- 出荷取消チェックボックス -->
          <template v-slot:[`item.chk_shippingCancel`]="{ item }">
            <input
              type="checkbox"
              v-model="item.yobi01"
              style="transform: scale(2)"
              :disabled="item.procCd != 500 && item.procCd != 999"
            />
          </template>
          <!-- 出庫取消チェックボックス -->
          <template v-slot:[`item.chk_wareoutCancel`]="{ item }">
            <input
              type="checkbox"
              v-model="item.yobi02"
              style="transform: scale(2)"
              :disabled="item.procCd != 400 && item.procCd != 500 && item.procCd != 999"
            />
          </template>
          <!-- 引当取消チェックボックス -->
          <template v-slot:[`item.chk_reserveCancel`]="{ item }">
            <input
              type="checkbox"
              v-model="item.yobi03"
              style="transform: scale(2)"
              :disabled="item.procCd != 310"
            />
          </template>
          <!-- 予定変更チェックボックス -->
          <template v-slot:[`item.chk_scheduleChange`]="{ item }">
            <input type="checkbox" v-model="item.yobi04" style="transform: scale(2)" />
          </template>
        </v-data-table>
        <!-- 詳細画面ポップアップ -->
        <v-dialog v-model="dialogDetails" :max-width="2000" persistent no-click-animation>
          <ShipDataImportCsvError
            :isOpenDetailDialog.sync="dialogDetails"
            :entity="detailDialogData"
            class="overflow"
            v-on:closePopup="closePopup"
          />
        </v-dialog>
        <!-- ピッキングリストダイアログ -->
        <v-dialog v-model="dialogPickingList" :max-width="1200" persistent>
          <PickingList
            :isOpenDetailDialog.sync="dialogPickingList"
            v-on:setSinglePicking="saveSinglePicking"
            :pickingInfo="pickingInfo"
          />
        </v-dialog>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="infoDialog.screenFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :shpSchListFlg="infoDialog.shpSchListFlg"
        :shpSchAddFlg="infoDialog.shpSchAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
      />

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="csvConfirm"
      />
      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="compareDialog.isOpen"
        :message="compareDialog.message"
        :screenFlag="compareDialog.screenFlag"
        :okAction="() => postApiCompareList(true)"
      />
      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="importDialog.isOpen"
        :message="importDialog.message"
        :screenFlag="importDialog.screenFlag"
        :okAction="() => postApiCompareList(false)"
      />
      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="clearDialog.isOpen"
        :message="clearDialog.message"
        :screenFlag="clearDialog.screenFlag"
        :okAction="compareClearOK"
      />
      <v-dialog v-model="isOpenTruck" :max-width="800">
        <Truck
          :isOpenDetailDialog.sync="isOpenTruck"
          @click="saveTruck"
          :truckInfo="truckInfo"
          ref="truck"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import Truck from "../../views/common/Truck";
import ShipDataImportCsvError from "../ship/ShipDataImportCsvError.vue";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import commonMixinExcel from "@/mixins/CommonMixinExcel";

export default {
  name: appConfig.SCREEN_ID.P_SHP_012,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
    Truck,
    ShipDataImportCsvError,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules, commonMixinExcel],
  data: () => ({
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    importfile: {},
    // メッセージダイアログ
    compareDialog: {
      isOpen: false,
      title: "",
      message: "",
      screenFlag: false,
      outsideClickNotCloseFlg: false,
    },
    // メッセージダイアログ
    clearDialog: {
      isOpen: false,
      title: "",
      message: "",
      screenFlag: false,
      outsideClickNotCloseFlg: false,
    },
    // メッセージダイアログ
    importDialog: {
      isOpen: false,
      title: "",
      message: "",
      screenFlag: false,
      outsideClickNotCloseFlg: false,
    },
    // 指示遷移表示用
    instructionFlag: "",
    inputList: [],
    searchForm: {
      // 取引先
      suppliersSelected: "",
      supplierList: [],
      // データページスクロール処理
      itemsPerPage: 1,
      // 出荷予定日
      outScheduleDate: "",
      outScheduleDateCal: "",
      dateMenu: false,
      // 納品日
      deliveryDate: "",
      deliveryDateCal: "",
      dateMenu2: false,
      // 出荷予定日
      referenceDispFlg: false,
      referenceDate: "",
      referenceDateCal: "",
      dateMenu3: false,
    },
    suppliersNm: "",
    // ファイル名
    fileName: {},
    // S3ファイル名
    s3FileName: {},
    // 責任
    isRes: "01",
    isResBadValue: "01",
    // 有償/無償
    isPaid: "01",
    // 輸送会社名（画面表示用）
    truckNm: "",
    // 輸送会社区分（登録用）
    truckKbn: "",
    // 輸送会社名（登録用）
    truck: "",
    // 総件数
    totalCount: 0,
    // 数量件数
    sumCount: "",
    // 品質区分
    arrivalStatusSelected: "01",
    isDisabledFreeReason: true,
    isDisabledBlame: true,
    // エラーメッセージ
    alertMessage: "",
    // 取引先CD
    clientCd: "",
    // 品質区分
    qualityDiv: "",
    // 責任区分
    blameDiv: "",
    // 変更理由区分
    changeReasonDiv: "",
    // 有償無償区分
    freeCostDiv: "",
    // 有償無償理由区分
    freecostReasonDiv: "",
    // 運送会社区分
    carrierCompKbn: "",
    // 運送会社SID
    carrierCompSid: "",
    // 処理区分
    processDiv: "",
    // 無償理由
    freeReasonSelected: "",
    // 処理区分
    processDivName: "01",
    // 入荷予定配列
    inListCsvData: [],
    // 取込確定フラグ
    confirmFlg: false,
    //取引先クリア変更
    isNotClear: false,
    // 処置確認確定フラグ
    compareFlg: false,
    // 処置確認ボタン押下時ファイルチェック抜き用
    compareBtnFileFlg: false,
    // メニュー
    openMenu: null,
    // 表示の切り替え
    activate: false,
    // 配送先情報画面ダイアログ
    isOpenTruck: false,
    // ピッキングリストダイアログ
    dialogPickingList: false,
    checkFlg: false,
    truckInfo: { truck: "" },
    // エラー画面遷移用取引先cd,name
    supplierCdName: "",
    freeCostDivList: [],
    blameDivList: [],
    arrivalStatusList: [],
    freeReasonList: [],
    processDivList: [],
    searchParam: {
      searchShippingScheduleDateFrom: "",
      searchShippingScheduleDateFromCal: "",
      searchShippingScheduleDateTo: "",
      searchShippingScheduleDateToCal: "",
      searchdeliveryDate: "",
      searchdeliveryDateToCal: "",
      searchIssueTermsSelected: "",
      searchTagTermsSelected: "",
      searchCreateDateFrom: "",
      searchCreateDateFromCal: "",
      searchCreateDateTo: "",
      searchCreateDateToCal: "",
      searchTruckNm: "",
      searchTruckKbn: "",
      searchTruckInfo: {},
      searchTruck: "",
    },
    // 詳細ポップアップ
    dialogDetails: false,
    errorDetailDialogData: [],
    // 詳細ERROR画面へ渡す
    detailDialogData: {
      suppliersSelectedName: "",
      list: [],
    },
    // 処置状況
    confirmTreatmentOldList: [],
    confirmTreatmentCurrentList: [],
    // ピッキング情報
    pickingInfo: "",
    // シート数
    sheetNum: 0,
    // 取込データ
    output: [],
    sheetInfo: [],
    // ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        value: "no",
        width: "67px",
        sortable: true,
        align: "center",
      },
      // 出荷No
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "outListNo",
        width: "170px",
        align: "left",
        sortable: true,
      },
      // 品番/付帯品番
      {
        text: i18n.tc("label.lbl_productCode") + "/" + i18n.tc("label.lbl_accessoryProduct"),
        value: "itemCd",
        width: "192px",
        align: "left",
        sortable: true,
      },
      // 品名
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "itemName",
        width: "192px",
        align: "left",
        sortable: false,
      },
      // ロット/ S/N
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "320",
        align: "left",
        sortable: true,
      },
      // 登録済数量
      {
        text: i18n.tc("label.lbl_dbQuantity"),
        value: "dbQuantity",
        width: "132px",
        align: "right",
        sortable: true,
      },
      // 取込数量
      {
        text: i18n.tc("label.lbl_csvQuantity"),
        value: "csvQuantity",
        width: "117px",
        align: "right",
        sortable: true,
      },
      // 状況
      {
        text: i18n.tc("label.lbl_Situatuion"),
        value: "situation",
        width: "110px",
        align: "left",
        sortable: false,
      },
      // 指示
      {
        text: i18n.tc("label.lbl_instruction"),
        value: "instruction",
        width: "308px",
        align: "left",
        sortable: false,
      },
      // 出荷取消チェックボックス
      {
        text: i18n.tc("menu.txt_shipping") + "\r\n" + i18n.tc("label.lbl_cancel"),
        value: "chk_shippingCancel",
        width: "67px",
        align: "center",
        sortable: false,
      },
      // 出庫取消チェックボックス
      {
        text: i18n.tc("label.lbl_leaveWarehouseQty") + "\r\n" + i18n.tc("label.lbl_cancel"),
        value: "chk_wareoutCancel",
        width: "67px",
        align: "center",
        sortable: false,
      },
      // 引当取消チェックボックス
      {
        text: i18n.tc("btn.btn_reservation") + "\r\n" + i18n.tc("label.lbl_cancel"),
        value: "chk_reserveCancel",
        width: "67px",
        align: "center",
        sortable: false,
      },
      // 予定変更チェックボックス
      {
        text: i18n.tc("btn.btn_scheduled") + "\r\n" + i18n.tc("btn.btn_change"),
        value: "chk_scheduleChange",
        width: "67px",
        align: "center",
        sortable: false,
      },
    ],

    rules: {
      freeReasonInputRequired: (value) =>
        (!!value && this.isDisabledFreeReason == false) || i18n.tc("check.chk_select"),
    },
  }),
  methods: {
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },
    append() {
      this.truckKbn = "";
    },
    /**
     * 初期表示
     */
    init() {
      this.searchForm.outScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.popupFlg = this.$route.params.popupFlg;
      if (this.popupFlg != null && this.popupFlg) {
        this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
        this.infoDialog.title = "結果";
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
        this.clearFlg = true;
      }

      this.checkFlg = this.$route.params.checkFlg;
      if (this.checkFlg == true) {
        this.searchForm = this.$route.params.searchForm;
        this.inputList = this.$route.params.inputList;
        this.compareFlg = this.$route.params.compareFlg;
        if (this.popupFlg != null && this.popupFlg) {
          this.inputList.splice(this.$route.params.index, 1);
        }
        // 総件数
        this.totalCount = this.inputList.length;
        // ナンバリング修正＋数量合計
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].no = Number(i + 1);
          this.sumCount = Number(this.sumCount) + Number(this.inputList[i].csvQuantity);
        }
        this.isNotClear = true;
      }
      if (this.$route.params.importfile != null && this.$route.params.importfile != {}) {
        this.importfile = this.$route.params.importfile;
      }
      // 取引先取得
      this.getCustomInfo();
      // マスタ取得
      this.getMstCode();
    },
    /**
     * 出荷予定日初期化
     */
    initOutScheduleDate() {
      this.searchForm.referenceDispFlg = false;
      this.searchForm.outScheduleDate = "";
      this.searchForm.outScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
    },
    /**
     * 出荷予定データ取込画面：出荷予定修正・削除画面を開きます。
     */
    openUpdateDelete(screenId) {
      // 修正画面へ渡す値を設定します。
      this.$router.push({
        name: screenId,
      });
    },

    /**
     * 出荷予定データ取込画面： 処置確認ボタン押下処理
     */
    postApiCompareList(isCompare) {
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      // let check = 0;
      // メッセージ初期化
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      this.createalertMessage = "";
      // 取引先存在チェック
      if (this.suppliersSelected == "" || this.suppliersSelected == null) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        // check++;
      }
      // if (check == 0) {
      /**
       * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
       * エラーがでるので回避するためこのようなコードとなっている。
       */
      inputCheckResult = true;
      // if (this.$route.params.searchSuppliersSelected && this.checkFlg) {
      //   // 登録画面からの遷移の場合、入力チェックは実施しない。
      //   inputCheckResult = true;
      // } else {
      //   // 上記外の場合はチェックを行う。
      //   inputCheckResult = this.$refs.form.validate();
      // }
      // 画面遷移フラグをfalseに戻す
      this.checkFlg = false;
      // 数量合計を初期化
      this.sumCount = "";
      if (inputCheckResult) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();
        // // 共通IF項目 画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_002;
        // 営業所SID
        body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
        // 取引先SID
        body.reqIdv.clientSid = this.searchForm.suppliersSelected;
        // 出荷予定日
        body.reqIdv.referenceDate = this.searchForm.outScheduleDate;
        // // ファイル名
        // body.reqIdv.fileName = this.importfile.name;
        // // 出荷予定配列
        // body.reqIdv.listCsvData = this.inListCsvData;
        // // タイムゾーン
        // body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
        // 入出荷フラグ(0004)
        body.reqIdv.inOutDiv = "0004";

        // // 取引先CD
        body.reqIdv.clientCd = this.clientCd;
        // // 品質区分
        body.reqIdv.qualityDiv = this.arrivalStatusSelected;
        // // 責任区分
        body.reqIdv.blameDiv = this.isRes;
        // // 変更理由区分
        // body.reqIdv.changeReasonDiv = this.changeReasonDiv;
        // // 有償無償区分
        body.reqIdv.freeCostDiv = this.isPaid;
        // // 有償無償理由区分
        // body.reqIdv.freecostReasonDiv = this.freeReasonSelected;
        // 出荷予定日
        body.reqIdv.outScheduleDate = this.searchForm.outScheduleDate;
        // // 納品日
        body.reqIdv.deliveryDate = this.searchForm.deliveryDate;

        // // 路線業者SID（輸送会社名）
        // if (this.truckKbn != "") {
        //   body.reqIdv.carrierCompKbn = this.truckKbn;
        //   if (this.truck != "") {
        //     body.reqIdv.carrierCompSid = this.truck;
        //   }
        // }
        // if (this.truck === "") {
        //   body.reqIdv.carrierCompSid = "";
        // }
        // // 運送会社区分
        // // body.reqIdv.carrierCompKbn = this.truckKbn;
        // // 運送会社SID
        // // body.reqIdv.carrierCompSid = this.truck;
        // // 処理区分
        body.reqIdv.processDiv = this.processDivName;

        // 処置状況が変更されているデータのみAPIでレコード更新
        const confirmTreatmentList = [];
        const confirmTreatmentChangeDataList = this.getConfirmTreatmentChangeData();
        confirmTreatmentChangeDataList.forEach((element) => {
          confirmTreatmentList.push({
            autoNo2: element.autoNo2,
            yobi01: element.yobi01 === true ? "1" : "0",
            yobi02: element.yobi02 === true ? "1" : "0",
            yobi03: element.yobi03 === true ? "1" : "0",
            yobi04: element.yobi04 === true ? "1" : "0",
            inOutDiv: element.inOutDiv,
            updateDatetime: element.updateDatetime,
          });
        });
        body.reqIdv.compareConfirmFlg = isCompare;
        body.reqIdv.confirmTreatmentList = confirmTreatmentList;
        if (this.sortItem != "") {
          body.reqCom.reqComSortItem = this.sortItem;
        }
        // console.debug("postApiCompareList() body", body);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_COMPARECHECK, body, appConfig.APP_CONFIG)
            .then((response) => {
              // console.debug("postApiCompareList() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 成功
              if (jsonData.resCom.resComCode == "000") {
                let index = 0;
                const list = [];
                const confirmTreatmentList = [];
                jsonData.resIdv.inOutDiff.forEach((row) => {
                  let lotObj = commonUtil.convertLotNo(row.lotNo);
                  list.push({
                    // キー
                    id: index,
                    // 自動連番
                    autoNo2: row.autoNo2,
                    //状態区分
                    status: row.status,
                    // 作成日
                    createDate: row.createDate,
                    // 作成時間
                    createTime: row.createTime,
                    // 会社CD
                    compCd: row.compCd,
                    // 拠点CD
                    baseCd: row.baseCd,
                    // 取引先CD
                    clientCd: row.clientCd,
                    // 入荷予定日ymd
                    inScheduleDateYmd: row.inScheduleDateYmd,
                    // 納入予定日ymd
                    deliveryScheduleDateYmd: row.deliveryScheduleDateYmd,
                    // 便No
                    binNo: row.binNo,
                    // 伝票No
                    slipNo: row.slipNo,
                    // 伝票明細No
                    slipDtlNo: row.slipDtlNo,
                    // 伝票明細No
                    inListNo: row.inListNo,
                    // 入荷No
                    fromCd: row.fromCd,
                    // 取引先sid
                    clientSid: row.itemClientSid,
                    //-----------------------------------
                    //No
                    no: "",
                    // 出荷No
                    outListNo: row.shipNo,
                    // 出荷予定日
                    outScheduleDate: this.convertDate(row.shipScheduleDate),
                    // 品番
                    itemCd: row.productCd.trim(),
                    // 付帯品番
                    accessoryProduct: row.accessoryProduct.trim(),
                    // 品名
                    itemName: row.productNm.trim(),
                    // ロット、S/N
                    lot: lotObj.lot,
                    // 期限日、付属キー１、付属キー２
                    dueDate: lotObj.dueDate,
                    // 登録済数量
                    dbQuantity: row.registeredQty,
                    // 取込数量
                    csvQuantity: row.importQty,
                    // 状況
                    situation: row.procCd,
                    // 指示
                    instruction: row.instructionDiv,
                    // 出荷SID
                    shipSid: row.shipSid,
                    // 商品SID
                    itemStandardSid: row.itemStandardSid,
                    //-------------------------------------
                    // 出荷先CD
                    toCd: row.toCd,
                    // 品質区分
                    qualityDiv: row.qualityDiv,
                    // 有償無償区分
                    freeCostDiv: row.freeCostDiv,
                    // 処理区分
                    processDiv: row.processDiv,
                    // 郵便番号
                    postCd: row.postCd,
                    // 都道府県
                    prefectures: row.prefectures,
                    // 市区町村
                    municipalities: row.municipalities,
                    // 町域名
                    characterName: row.characterName,
                    // 住所詳細IF
                    addressDetailIf: row.addressDetailIf,
                    // 電話番号
                    telephoneNumber: row.telephoneNumber,
                    // 電話番号
                    repName: row.repName,
                    // 備考
                    remarks: row.remarks,
                    // 出荷予定数
                    shipScheduleQuantity: row.shipScheduleQuantity,
                    // 出荷予定数
                    inScheduleQuantity: row.inScheduleQuantity,
                    // 実重量
                    actualWeight: row.actualWeight,
                    // 請求重量
                    pieceCapacityWeight: row.pieceCapacityWeight,
                    // 実立米
                    actualQuantity: row.actualQuantity,
                    // 請求立米
                    pieceCapacityQuantity: row.pieceCapacityQuantity,
                    // 輸送会社CD
                    carrierCompCd7: row.carrierCompCd7,
                    // JANCD
                    janCd: row.janCd,
                    // ITFCD
                    itfCd: row.itfCd,
                    // 品番代替CD
                    itemSurrogateCd: row.itemSurrogateCd,
                    // 取引先データ作成日
                    clientCreateDate: row.clientCreateDate,
                    // 取引先データ作成時間
                    clientCreateTime: row.clientCreateTime,
                    // 予備
                    yobi01: row.yobi01 == "1",
                    yobi02: row.yobi02 == "1",
                    yobi03: row.yobi03 == "1",
                    yobi04: row.yobi04 == "1",
                    yobi05: row.yobi05,
                    yobi06: row.yobi06,
                    yobi07: row.yobi07,
                    yobi08: row.yobi08,
                    yobi09: row.yobi09,
                    yobi10: row.yobi10,
                    yobi11: row.yobi11,
                    yobi12: row.yobi12,
                    yobi13: row.yobi13,
                    yobi14: row.yobi14,
                    yobi15: row.yobi15,
                    yobi16: row.yobi16,
                    yobi17: row.yobi17,
                    yobi18: row.yobi18,
                    yobi19: row.yobi19,
                    yobi20: row.yobi20,
                    yobi21: row.yobi21,
                    yobi22: row.yobi22,
                    yobi23: row.yobi23,
                    yobi24: row.yobi24,
                    // ファイル名
                    fileName: row.fileName,
                    // 送受信ステータス
                    dataImportStatus: row.dataImportStatus,
                    // 削除フラグ
                    deleteFlg: row.deleteFlg,
                    // 作成者
                    createUserCd: row.createUserCd,
                    // 作成日時
                    createDatetime: row.createDatetime,
                    // 更新者
                    updateUser_cd: row.updateUser_cd,
                    // 更新日時
                    updateDatetime: row.updateDatetime,
                    // 予備
                    yobi25: row.yobi25,
                    yobi26: row.yobi26,
                    yobi27: row.yobi27,
                    yobi28: row.yobi28,
                    yobi29: row.yobi29,
                    yobi30: row.yobi30,
                    yobi31: row.yobi31,
                    yobi32: row.yobi32,
                    yobi33: row.yobi33,
                    yobi34: row.yobi34,
                    yobi35: row.yobi35,
                    yobi36: row.yobi36,
                    yobi37: row.yobi37,
                    yobi38: row.yobi38,
                    yobi39: row.yobi39,
                    yobi40: row.yobi40,
                    yobi41: row.yobi41,
                    yobi42: row.yobi42,
                    yobi43: row.yobi43,
                    yobi44: row.yobi44,
                    yobi45: row.yobi45,
                    yobi46: row.yobi46,
                    yobi47: row.yobi47,
                    yobi48: row.yobi48,
                    yobi49: row.yobi49,
                    yobi50: row.yobi50,
                    yobi51: row.yobi51,
                    yobi52: row.yobi52,
                    yobi53: row.yobi53,
                    yobi54: row.yobi54,
                    yobi55: row.yobi55,
                    yobi56: row.yobi56,
                    yobi57: row.yobi57,
                    yobi58: row.yobi58,
                    yobi59: row.yobi59,
                    yobi60: row.yobi60,
                    yobi61: row.yobi61,
                    yobi62: row.yobi62,
                    yobi63: row.yobi63,
                    yobi64: row.yobi64,
                    yobi65: row.yobi65,
                    yobi66: row.yobi66,
                    yobi67: row.yobi67,
                    yobi68: row.yobi68,
                    yobi69: row.yobi69,
                    yobi70: row.yobi70,
                    yobi71: row.yobi71,
                    yobi72: row.yobi72,
                    yobi73: row.yobi73,
                    yobi74: row.yobi74,
                    yobi75: row.yobi75,
                    yobi76: row.yobi76,
                    yobi77: row.yobi77,
                    yobi78: row.yobi78,
                    yobi79: row.yobi79,
                    yobi80: row.yobi80,
                    yobi81: row.yobi81,
                    yobi82: row.yobi82,
                    yobi83: row.yobi83,
                    yobi84: row.yobi84,
                    yobi85: row.yobi85,
                    yobi86: row.yobi86,
                    yobi87: row.yobi87,
                    yobi88: row.yobi88,
                    yobi89: row.yobi89,
                    yobi90: row.yobi90,
                    yobi91: row.yobi91,
                    yobi92: row.yobi92,
                    yobi93: row.yobi93,
                    yobi94: row.yobi94,
                    yobi95: row.yobi95,
                    yobi96: row.yobi96,
                    yobi97: row.yobi97,
                    yobi98: row.yobi98,
                    yobi99: row.yobi99,
                    inOutDiv: row.inOutDiv,
                    procCd: row.procCd,
                    // // 出荷日（API登録

                    // // 出荷日（API登録用=UTC）
                    // shippingScheduleDate: row.outScheduleDate,
                    // // 出荷日（画面表示用=JST:秒数なし）
                    // shippingScheduleDateJ: dateTimeHelper
                    //   .convertUTC2JST(row.outScheduleDate)
                    //   .substr(0, 10),
                    // // 出荷SID
                    // shipSid: row.outListSid,
                    // // 出荷No
                    // shipNo: row.outListNo,
                    // //出荷先コード
                    // toBaseCd: row.toBaseCd,
                    // // 有償/無償
                    // isPaid: row.freeCostDivName,
                    // // 有償無償名
                    // freeCostDivName: row.freeCostDivName,
                    // // 状態（送り状）
                    // involce: involceIn,
                    // // 状態（荷札）
                    // taglabel: taglabelIn,
                    // // 状態（荷札）
                    // deliveryDate: row.deliveryDate,
                    // // 処理区分名
                    // processingdivision: row.processDivName,
                    // // 出荷先
                    // shippingNm: row.toName,
                    // // 出荷先SID
                    // toSid: row.toSid,
                    // // 輸送会社名
                    // // truck: row.carrierCompCdName,
                    // // 輸送会社区分
                    // carrierCompCd: row.carrierCompCd,
                    // // 輸送会社種別名
                    // carrierCompName: row.carrierCompName,
                    // // 号車
                    // transportVehicleCd: row.transportVehicleCd,
                    // // 備考
                    // remarks: row.remarks,
                    // // 出庫済
                    // exted: exited,
                    // // 路線業者SID
                    // traderSid: row.deliveryTraderSid,
                    // // 路線業者名
                    // deliveryTraderName: row.deliveryTraderName,
                    // // 品質区分
                    // qualityDiv: row.qualityDiv,
                    // // 責任区分
                    // blameDiv: row.blameDiv,
                    // // チェックボックス
                    // check: false,
                    // // 出荷予定明細
                    // dtlList: row.outScheduleDtl,
                    // // 完了フラグ
                    // completeFlg: row.completeFlg,
                    // // 更新日時
                    // updateDatetime: row.updateDatetime,
                    // // 出荷指示一覧フラグ
                    // isOutOrder: row.isOutOrder,
                    // // 登録方法
                    // howToRegister: row.entryDivName,
                    // // 登録コード
                    // entryDiv: row.entryDiv,
                    // // データ取込時間
                    // retrieveTime: dateTimeHelper.convertUTC2JST(row.createDatetime).substr(0, 19),
                    // // 取引先SID
                    // clientSid: row.clientSid,
                  });
                  confirmTreatmentList.push({
                    id: index,
                    yobi01: row.yobi01 === "1",
                    yobi02: row.yobi02 === "1",
                    yobi03: row.yobi03 === "1",
                    yobi04: row.yobi04 === "1",
                  });
                  ++index;
                });
                this.inputList = list;
                this.confirmTreatmentCurrentList = confirmTreatmentList;
                this.confirmTreatmentOldList = JSON.parse(JSON.stringify(confirmTreatmentList));

                // 総件数
                this.totalCount = this.inputList.length;
                // 数量合計
                for (let i = 0; i < list.length; i++) {
                  this.sumCount = Number(this.sumCount) + Number(list[i].csvQuantity);
                }
                // 連番
                for (var n = 0; n < this.inputList.length; n++) {
                  this.inputList[n].no = n + 1;
                }
                // 指示
                for (var o = 0; o < this.inputList.length; o++) {
                  let k = this.inputList[o].instruction;
                  if (k == "04") {
                    this.inputList[o].instruction = messsageUtil.getMessage("P-SHP-010_001_04");
                  } else if (k == "05") {
                    this.inputList[o].instruction = messsageUtil.getMessage("P-SHP-010_001_05");
                  } else if (k == "06" || k == "07") {
                    this.inputList[o].instruction = messsageUtil.getMessage("P-SHP-010_001_06");
                  } else if (k == "08") {
                    this.inputList[o].instruction = messsageUtil.getMessage("P-SHP-010_001_08");
                  } else if (k == "09") {
                    this.inputList[o].instruction = messsageUtil.getMessage("P-SHP-010_001_09");
                  } else {
                    this.inputList[o].instruction = messsageUtil.getMessage("P-SHP-010_001_99");
                  }
                }
                // 状況
                for (var m = 0; m < this.inputList.length; m++) {
                  let k = this.inputList[m].situation;
                  if (k == "310") {
                    this.inputList[m].situation = messsageUtil.getMessage("P-SHP-010_001_310");
                    // 引当取消メッセージ
                    this.inputList[m].instructionOfReservation = messsageUtil.getMessage(
                      "P-SHP-010_001_01-01",
                      [1]
                    );
                    // 出荷予定修正メッセージ
                    this.inputList[m].instructionOfShipOrder = messsageUtil.getMessage(
                      "P-SHP-010_001_01-02",
                      [2]
                    );
                  } else if (k == "400") {
                    this.inputList[m].situation = messsageUtil.getMessage("P-SHP-010_001_400");
                    // 出庫取消メッセージ
                    this.inputList[m].instructionOfShipCancel = messsageUtil.getMessage(
                      "P-SHP-010_001_01-03",
                      [1]
                    );
                    // 出荷予定修正メッセージ
                    this.inputList[m].instructionOfShipOrder = messsageUtil.getMessage(
                      "P-SHP-010_001_01-02",
                      [2]
                    );
                  } else if (k == "500" || k == "999") {
                    this.inputList[m].situation = messsageUtil.getMessage("P-SHP-010_001_999");
                    // 出荷取消メッセージ
                    this.inputList[m].instructionOfShippingCancel = messsageUtil.getMessage(
                      "P-SHP-010_001_01-04",
                      [1]
                    );
                    // 出庫取消メッセージ
                    this.inputList[m].instructionOfShipCancel = messsageUtil.getMessage(
                      "P-SHP-010_001_01-03",
                      [2]
                    );
                    // 出荷予定修正メッセージ
                    this.inputList[m].instructionOfShipOrder = messsageUtil.getMessage(
                      "P-SHP-010_001_01-02",
                      [3]
                    );
                  }
                }
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
              // 処置確認ボタン押下時ファイルチェック抜き用
            });
        });
      }
      // }
    },
    /**
     * ピッキングリスト、閉じる
     */
    saveSinglePicking() {
      this.dialogPickingList = false;
    },
    /**
     * ピッキングリスト子画面、値受け渡し
     */
    openPicking(item) {
      if (
        new Date(this.shippingScheduleDateFrom).getTime() > new Date(this.shippingScheduleDateTo)
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else {
        // 選択した行のアイテム
        this.editedIndex = this.resConfirmList.indexOf(item);

        // ピッキングリスト名
        this.pickingInfo.pickingNm = this.resConfirmList[this.editedIndex].pickingNm;
        // ピッキングSID（ピッキングNo）
        this.pickingInfo.pickingNo = this.resConfirmList[this.editedIndex].pickingNo;
        // 取引先SID
        this.pickingInfo.suppliersSelected = this.clientSid;
        // 出荷予定日
        this.pickingInfo.shipInsDate = this.resConfirmList[this.editedIndex].shipInsDate;
        // 出荷先SID
        this.pickingInfo.toSid = this.resConfirmList[this.editedIndex].toSid;

        this.dialogPickingList = true;
      }
    },
    /**
     * 日付を－１日
     */
    prevDate(modelName) {
      if (this.searchForm[modelName] == null || this.searchForm[modelName] == "") {
        return;
      }
      let date = new Date(this.searchForm[modelName]);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.searchForm[modelName] != toDate) {
        this.searchForm[modelName] = toDate;
      } else {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    /**
     * 日付を＋１日
     */
    nextDate(modelName) {
      if (this.searchForm[modelName] == null || this.searchForm[modelName] == "") {
        return;
      }
      let date = new Date(this.searchForm[modelName]);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.searchForm[modelName] != toDate) {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
    /**
     * 日付入力
     */
    changeDate(modelName, val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.searchForm[modelName] = null;
      }
    },
    /**
     * 日付入力(有効開始日時)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.validFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.validFromCal = strDt;
      }
    },
    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";
      // config.params.reqComComponentId = appConfig.SCREEN_ID.P_SHP_012;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getCustomInfo() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.compSid, // 取引先Sid
                  officeCd: row.clientCd, // 取引先Cd
                });
              });
              this.searchForm.supplierList = list;
              // 取引先が1件の場合、取引先を自動選択する
              this.searchForm.supplierList.length == 1
                ? (this.searchForm.suppliersSelected = this.searchForm.supplierList[0].value)
                : "";
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 取込確定ボタン押下
     */
    confirm() {
      // ボタン識別フラグ（trueだったら、取込確定）
      this.confirmFlg = true;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_importConfirm"),
      ]);
    },
    /**
     * 処置確認ボタン押下
     */
    compareConfirm() {
      // 取込前のバリデーションチェック
      this.compareBtnFileFlg = true;
      this.$nextTick(() => {
        if (this.$refs.searchForm.validate()) {
          // ボタン識別フラグ（trueだったら、取込確定）
          this.compareFlg = true;
          this.compareDialog.isOpen = true;
          this.compareDialog.screenFlag = true;
          this.compareDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
            i18n.tc("btn.btn_diffComparison"),
          ]);
        }
      });
    },
    // 処理区分
    setStsArrival() {
      for (var i = 0; i < this.processDivList.length; i++) {
        if (this.processDivList[i].value == this.processDivName) {
          return this.processDivList[i].name;
        }
      }
    },
    /**
     * 差分確認画面クリア押下
     */
    compareClear() {
      this.clearDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmFlg = false;
      this.clearDialog.isOpen = true;
      this.clearDialog.screenFlag = true;
    },
    /**
     * 差分確認画面クリアOK押下
     */
    compareClearOK() {
      // 画面クリア処理
      this.inputList = [];
      this.importfile = null;
      this.totalCount = 0;
      this.sumCount = "";
      this.arrivalStatusSelected = "01";
      this.activate = false;
      this.freeReasonSelected = "";
      this.processDivName = "01";
      this.isPaid = "01";
      this.truckNm = "";
      this.truckInfo = { truck: "" };
      this.truck = "";
      this.truckKbn = "";
      // 取引先
      this.searchForm.suppliersSelected = "";
      // 出荷予定日
      this.searchForm.outScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      // 納品日
      this.searchForm.deliveryDate = "";
      this.searchForm.deliveryDateCal = "";

      // バリデーションチェックをリセット
      this.$refs.searchForm.resetValidation();
      this.searchForm.referenceDispFlg = false;
    },
    /**
     * 取引先変更後画面クリア
     */
    afterSuppliersSelectedClear() {
      if (!this.isNotClear) {
        // 画面クリア処理
        this.inputList = [];
        this.importfile = null;
        this.totalCount = 0;
        this.sumCount = "";
        this.arrivalStatusSelected = "01";
        this.activate = false;
        this.freeReasonSelected = "";
        this.processDivName = "01";
        this.isPaid = "01";
        this.truckNm = "";
        this.truckInfo = { truck: "" };
        this.truck = "";
        this.truckKbn = "";
        // 出荷予定日
        this.searchForm.outScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        // 納品日
        this.searchForm.deliveryDateCal = "";

        // バリデーションチェックをリセット
        this.$refs.searchForm.resetValidation();
      }
    },
    /**
     * アップロード後画面クリア
     */
    afterUploadClear() {
      // 画面クリア処理
      this.inputList = [];
      this.importfile = null;
      this.totalCount = 0;
      this.sumCount = "";
      this.arrivalStatusSelected = "01";
      this.activate = false;
      this.freeReasonSelected = "";
      this.processDivName = "01";
      this.isPaid = "01";
      this.truckNm = "";
      this.truckInfo = { truck: "" };
      this.truck = "";
      this.truckKbn = "";
      // 取引先
      this.searchForm.suppliersSelected = "";
      // 出荷予定日
      this.searchForm.outScheduleDate = "";
      this.searchForm.outScheduleDateCal = "";
      // 納品日
      this.searchForm.deliveryDate = "";
      this.searchForm.deliveryDateCal = "";

      // バリデーションチェックをリセット
      this.$refs.searchForm.resetValidation();
    },
    /**
     * 取込ボタン押下処理
     */
    search() {
      // 処置確認ボタン押下時ファイルチェック抜き用
      this.compareBtnFileFlg = false;
      this.$nextTick(() => {
        // 取込前のバリデーションチェック
        if (this.$refs.searchForm.validate()) {
          // 取込確定処理
          // 選択したファイルを読み込む
          const selectFile = this.importfile;
          // 拡張子チェック
          let fileType = selectFile.type;
          // 取り込んだファイルはCSVの場合
          if (fileType == "text/csv") {
            this.importCSV(selectFile, true)
              .then((result) => {
                this.inListCsvData = result;
              })
              .then(() => {
                this.postCompareCsv();
              });
          } else if (
            fileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            fileType == "application/vnd.ms-excel"
          ) {
            // 取り込んだファイルはエクセルの場合
            this.importExcel(selectFile)
              .then((result) => {
                this.inListCsvData = result;
              })
              .then(() => {
                this.postCompareCsv();
              });
          } else {
            // 取り込んだファイルはCSVまたはExcel以外場合エラーメッセージを表示する
            this.infoDialog.message = i18n.tc("check.chk_limitSelectFileType");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
      });
    },
    /**
     * マスタコード取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 責任区分
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);
      // 処理区分
      const processList = getParameter.getCodeMst(appConfig.CODETYPE.IN_PROCESS_DIV);

      Promise.all([qualityList, reasonList, freeCostList, blameList, processList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.arrivalStatusList = result[0];
          this.freeReasonList = result[1];
          this.freeCostDivList = result[2];
          // 責任区分の最初のなしを削除
          result[3].splice(0, 1);
          this.blameDivList = result[3];
          this.processDivList = result[4];
          this.processDivName = this.processDivList[0].value;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * POSTAPI(本登録POST)
     */
    csvConfirm() {
      if (this.confirmFlg) {
        if (this.$refs.searchForm.validate()) {
          // ローディング画面表示ON
          this.loadingCounter = 1;
          const body = this.$httpClient.createRequestBodyConfig();

          //  画面ID TODO:修正必要
          body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_006;

          // 取引先SID
          body.reqIdv.clientSid = this.searchForm.suppliersSelected;
          // ファイル名
          body.reqIdv.fileName = this.importfile.name;
          // 入荷予定配列
          body.reqIdv.listCsvData = this.inListCsvData;
          // タイムゾーン
          body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
          // 入出荷フラグ(0004)
          body.reqIdv.inOutDiv = "0004";

          // 取引先CD
          body.reqIdv.clientCd = this.clientCd;
          // 品質区分
          body.reqIdv.qualityDiv = this.arrivalStatusSelected;
          // 責任区分
          body.reqIdv.blameDiv = this.isRes;
          // 変更理由区分
          body.reqIdv.changeReasonDiv = this.changeReasonDiv;
          // 有償無償区分
          body.reqIdv.freeCostDiv = this.isPaid;
          // 有償無償理由区分
          body.reqIdv.freecostReasonDiv = this.freeReasonSelected;
          // 出荷予定日
          body.reqIdv.outScheduleDate = this.searchForm.outScheduleDate;
          // 納品日
          body.reqIdv.deliveryDate = this.searchForm.deliveryDate;

          // 路線業者SID（輸送会社名）
          if (this.truckKbn != "") {
            body.reqIdv.carrierCompKbn = this.truckKbn;
            if (this.truck != "") {
              body.reqIdv.carrierCompSid = this.truck;
            }
          }
          if (this.truck === "") {
            body.reqIdv.carrierCompSid = "";
          }
          // 処理区分（画面上にないから追加）
          body.reqIdv.processDiv = this.processDivName;

          // 品質区分
          body.reqIdv.qualityDiv = this.arrivalStatusSelected;
          // 処理区分
          body.reqIdv.processDiv = this.processDivName;

          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(appConfig.API_URL.BIZ_INSCHDULESFILEMAIN, body, appConfig.APP_CONFIG)
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // 正常時
                if (jsonData.resCom.resComCode == "000") {
                  this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  // 画面遷移ボタン TODO：修正する
                  this.infoDialog.homePageFlag = true;
                  this.infoDialog.shpSchListFlg = true;
                  this.infoDialog.shpSchAddFlg = true;
                  this.infoDialog.firstPageFlag = false;
                  this.clearFlg = true;
                  resolve(response);
                } else {
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  // エラー時
                  // const errorList = [];
                  // エラー情報
                  // jsonData.resIdv.errors.forEach((row) => {
                  //   errorList.push({
                  //     // 行数
                  //     row: row.row,
                  //     // 項目数
                  //     columnName: row.columnName,
                  //   });
                  // });
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;

                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
                this.afterUploadClear();
              });
          });
        }
      } else {
        // 画面クリア処理
        this.inputList = [];
        this.importfile = null;
        this.totalCount = 0;
        this.sumCount = "";
        this.arrivalStatusSelected = "01";
        this.activate = false;
        this.freeReasonSelected = "";
        this.processDivName = "01";
        this.isPaid = "01";
        this.truckNm = "";
        this.truckInfo = { truck: "" };
        this.truck = "";
        this.truckKbn = "";
        // 取引先
        this.searchForm.suppliersSelected = "";
        // 出荷予定日
        this.searchForm.outScheduleDate = "";
        this.searchForm.outScheduleDateCal = "";
        // 納品日
        this.searchForm.deliveryDate = "";
        this.searchForm.deliveryDateCal = "";

        // バリデーションチェックをリセット
        this.$refs.searchForm.resetValidation();
      }
    },
    isErr() {
      let countErr = 0;
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].isInListNoFlg) {
          countErr += 1;
        }
      }
      if (countErr == 0) {
        this.activate = true;
      }
    },
    /**
     * postCompareCsv(仮登録POST)
     */
    postCompareCsv() {
      // 数量合計を空にする
      this.sumCount = "";

      // 検索条件を保存
      console.log(this.searchParam);
      this.searchParam.searchShippingScheduleDateFrom = this.shippingScheduleDateFrom;
      this.searchParam.searchShippingScheduleDateFromCal = this.shippingScheduleDateFromCal;
      this.searchParam.searchShippingScheduleDateTo = this.shippingScheduleDateTo;
      this.searchParam.searchShippingScheduleDateToCal = this.shippingScheduleDateToCal;
      this.searchParam.searchIssueTermsSelected = this.issueTermsSelected;
      this.searchParam.searchTagTermsSelected = this.tagTermsSelected;
      this.searchParam.searchdeliveryDate = this.deliveryDate;
      this.searchParam.searchdeliveryDateToCal = this.deliveryDateToCal;
      this.searchParam.searchCreateDateFrom = this.createDateFrom;
      this.searchParam.searchCreateDateFromCal = this.createDateFromCal;
      this.searchParam.searchCreateDateTo = this.createDateTo;
      this.searchParam.searchCreateDateToCal = this.createDateToCal;
      this.searchParam.searchTruckNm = this.truckNm;
      this.searchParam.searchTruckKbn = this.truckKbn;
      this.searchParam.searchTruckInfo = this.truckInfo;
      this.searchParam.searchTruck = this.truck;

      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_006;

      // 取引先SID
      body.reqIdv.clientSid = this.searchForm.suppliersSelected;
      // ファイル名
      body.reqIdv.fileName = this.importfile.name;
      // 入荷予定配列
      body.reqIdv.listCsvData = this.inListCsvData;
      // タイムゾーン
      body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
      // 入出荷フラグ(0004)
      body.reqIdv.inOutDiv = "0004";

      // 取引先CD
      body.reqIdv.clientCd = this.clientCd;
      // 品質区分
      body.reqIdv.qualityDiv = this.arrivalStatusSelected;
      // 責任区分
      body.reqIdv.blameDiv = this.isRes;
      // 変更理由区分
      body.reqIdv.changeReasonDiv = this.changeReasonDiv;
      // 有償無償区分
      body.reqIdv.freeCostDiv = this.isPaid;
      // 有償無償理由区分
      body.reqIdv.freecostReasonDiv = this.freeReasonSelected;
      // 出荷予定日
      body.reqIdv.outScheduleDate = this.searchForm.outScheduleDate;
      // 納品日
      body.reqIdv.deliveryDate = this.searchForm.deliveryDate;

      // 路線業者SID（輸送会社名）
      if (this.truckKbn != "") {
        body.reqIdv.carrierCompKbn = this.truckKbn;
        if (this.truck != "") {
          body.reqIdv.carrierCompSid = this.truck;
        }
      }
      if (this.truck === "") {
        body.reqIdv.carrierCompSid = "";
      }
      // 運送会社区分
      // body.reqIdv.carrierCompKbn = this.truckKbn;
      // 運送会社SID
      // body.reqIdv.carrierCompSid = this.truck;
      // 処理区分
      body.reqIdv.processDiv = this.processDivName;

      if (this.sortItem != "") {
        body.reqCom.reqComSortItem = this.sortItem;
      }

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_COMPAREIMPORT, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              if (jsonData.resIdv.inOutDiff.csvErrorCheckResultList.length != 0) {
                const errorList = [];
                this.errorDetailDialogData = [];
                jsonData.resIdv.inOutDiff.csvErrorCheckResultList.forEach((row) => {
                  errorList.push({
                    listNo: row.listNo,
                    row: row.row,
                    column: row.column,
                    message: row.message,
                    value: row.value,
                  });
                  this.errorDetailDialogData = errorList;
                });
                // エラー一覧タブが開く
                // 詳細画面へ渡す値を設定します。
                this.detailDialogData.list = this.errorDetailDialogData;
                // 選択された列名
                this.detailDialogData.suppliersSelectedName = this.setSrhSupplier();
                this.detailDialogData.supplierCdName = this.supplierCdName;

                this.dialogDetails = true;
              } else {
                this.isErr();
                this.importDialog.message = messsageUtil.getMessage("A-999-999_001_I", [
                  i18n.tc("btn.btn_compareImport"),
                ]);
                this.importDialog.message += messsageUtil.getMessage("P-SHP-012_001_C");
                this.importDialog.title = appConfig.DIALOG.title;
                this.importDialog.isOpen = true;
                this.importDialog.screenFlag = true;
                this.importDialog.firstPageFlag = true;
                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.searchForm.supplierList.length; i++) {
        if (this.searchForm.supplierList[i].value == this.searchForm.suppliersSelected) {
          return this.searchForm.supplierList[i].name;
        }
      }
    },
    /**
     * 取引先コード取得
     */
    getSupplierCd() {
      for (var i = 0; i < this.searchForm.supplierList.length; i++) {
        if (this.searchForm.supplierList[i].value == this.searchForm.suppliersSelected) {
          return this.searchForm.supplierList[i].officeCd;
        }
      }
    },
    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    /**
     * 配送先選択画面：配送先選択ボタン処理
     */
    openTruck() {
      if (this.searchForm.suppliersSelected) {
        //取引先SID
        this.truckInfo.suppliersSelected = this.searchForm.suppliersSelected;
        //路線業者SID
        this.truckInfo.deliveryTraderSid = this.truck;
        //輸送業者コード
        this.truckInfo.carrierCompCd = this.truckKbn;
        this.isOpenTruck = true;
        if (this.$refs.truck != undefined) {
          //表示時処理
          this.$refs.truck.init();
        }
      } else {
        this.searchForm.suppliersSelected = null;
      }
    },
    /**
     * 配送先選択画面：登録後処理
     */
    saveTruck(value) {
      this.truckInfo = value;
      // 画面表示用
      this.truckNm = this.truckInfo.carrierCompName;
      // API検索用
      this.truckKbn = this.truckInfo.carrierCompCd;
      this.truck = this.truckInfo.deliveryTraderSid;
      this.isOpenTruck = false;
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * アイコンを押下しても、エクスプローラーが開く処理
     */
    handleClickAppendOuter() {
      this.$refs.vFileInput.$el.querySelector("input").click();
    },
    /**
     * 日付フォーマット変換
     */
    convertDate(val) {
      if (val) {
        if (val.length == 8) {
          return val.substr(0, 4) + "/" + val.substr(4, 2) + "/" + val.substr(6, 2);
        } else if (val.length == 6) {
          return val.substr(0, 2) + "/" + val.substr(2, 2) + "/" + val.substr(4, 2);
        } else {
          return val.substr(0, 4) + "/" + val.substr(5, 2) + "/" + val.substr(8, 2);
        }
      } else {
        // 値がない場合は、空を返却
        return "";
      }
    },
    /**
     * チェックボックスの変更情報対を保持する
     */
    check(item, index) {
      let element = this.confirmTreatmentCurrentList[index];
      element.yobi01 = item.yobi01;
      element.yobi02 = item.yobi02;
      element.yobi03 = item.yobi03;
      element.yobi04 = item.yobi04;
    },
    /**
     * 処置状況に変更のあったデータを抽出する
     */
    getConfirmTreatmentChangeData() {
      let changeDataList = this.inputList.filter((valA) =>
        this.confirmTreatmentOldList.some(
          (valB) =>
            valA.id === valB.id &&
            (valA.yobi01 !== valB.yobi01 ||
              valA.yobi02 !== valB.yobi02 ||
              valA.yobi03 !== valB.yobi03 ||
              valA.yobi04 !== valB.yobi04)
        )
      );
      return this.inputList.filter((valA) => changeDataList.some((valB) => valA.id === valB.id));
    },
    /**
     * 文字入力判定
     */
    isNotEmpty(val) {
      let strVal = new String(val);
      return val != null && val !== void 0 && strVal.length !== 0;
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    "searchForm.outScheduleDateCal": function (newVal) {
      this.searchForm.outScheduleDate = this.formatDate(newVal);
    },
    "searchForm.deliveryDateCal": function (newVal) {
      this.searchForm.deliveryDate = this.formatDate(newVal);
    },
    "searchForm.referenceDateCal": function (newVal) {
      this.searchForm.referenceDate = this.formatDate(newVal);
    },
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
        this.processDivName = "01";
      }
    },
    arrivalStatusSelected: function (newVal) {
      if (newVal == "02") {
        this.isDisabledBlame = false;
        this.isRes = this.isResBadValue;
      } else {
        this.isDisabledBlame = true;
        this.isResBadValue = this.isRes;
        this.isRes = "01";
      }
    },
    "searchForm.suppliersSelected": function () {
      // 既存一か所だけの取引先を複数が取れるように修正
      for (let i = 0; i < this.searchForm.supplierList.length; i++) {
        if (this.searchForm.suppliersSelected == this.searchForm.supplierList[i].value) {
          this.supplierCdName = this.searchForm.supplierList[i].text;
        }
      }
      this.clientCd = this.supplierCdName.substr(0, 7);
      // 取引先を変更する時既存の表示はクリア
      this.afterSuppliersSelectedClear();
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          this.sortItem = sortBy + " " + ascdesc;
          if (sortBy == "no" || sortBy == "dbQuantity" || sortBy == "csvQuantity") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a[sortBy] - b[sortBy];
              } else {
                return b[sortBy] - a[sortBy];
              }
            });
          } else if (sortBy == "outListNo" || sortBy == "outScheduleDate" || sortBy == "lot") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a[sortBy] < b[sortBy]) {
                  return -1;
                } else if (a[sortBy] > b[sortBy]) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a[sortBy] > b[sortBy]) {
                  return -1;
                } else if (a[sortBy] < b[sortBy]) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "itemCd") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.itemCd < b.itemCd) {
                  return -1;
                } else if (a.itemCd > b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.itemCd > b.itemCd) {
                  return -1;
                } else if (a.itemCd < b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
          return this.inputList;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
/* 画面優先順位 */
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

/* 送り先登録時、色変更 */
.isD {
  color: $color-error !important;
}

/* 備考欄、テキストボックス */
.search-textbox-remark {
  width: 40rem;
}

.flex {
  display: flex;
}

.scrollType::-webkit-scrollbar {
  display: none;
}

.scrollTypeClose {
  overflow-y: scroll;
}

.table-scroll-host {
  grid-area: content;
  overflow: scroll;
}

#listData.v-data-table ::v-deep th {
  padding: 0 12px;
}
</style>
