<template>
  <div style="background: white">
    <!-- ローディング画面 -->
    <Loading :loadingCounter="loadingCounter" />
    <!-- 流通加工計画データ取込 -->
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_distribution_import" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- 検索項目 -->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                :hint="setSrhSupplier()"
                persistent-hint
                :rules="[rules.supplierRequired]"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取込ファイルTYPE -->
            <div style="float: left">
              <v-btn-toggle
                v-model="desiredImportType"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in desiredImportList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取込ファイル -->
            <div class="file-input">
              <v-file-input
                prepend-icon=""
                :label="$t('label.lbl_importFile')"
                outlined
                dense
                v-model="importfile"
                counter
                :rules="[rules.fileRequired]"
                append-outer-icon="mdi-paperclip"
                @click:append-outer="handleClickAppendOuter"
                ref="vFileInput"
                :error-messages="importFileCheckMsg"
              >
              </v-file-input>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取込ボタン -->
            <v-btn class="post-btn" @click="search()">
              {{ $t("btn.btn_import") }}
            </v-btn>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 作業日 -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="planDay"
                :label="$t('label.lbl_planDay')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <!-- 加工日カレンダー-->
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="planDayCal" @input="dateMenu = false"></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <!-- 前日-->
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <!-- 次日-->
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品質区分-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="qualitySelected"
                :items="qualityList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 有償/無償-->
            <div style="float: left">
              <v-btn-toggle v-model="isPaid" color="blue darken-2" id="btn-toggle-btn-padding">
                <v-btn
                  class="paid-btn"
                  v-for="item in freeCostDivList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 無償理由選択プルダウン-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                class="list-single"
                v-model="freeReasonSelected"
                :items="freeReasonList"
                v-bind:disabled="isDisabledFreeReason"
                :label="$t('label.lbl_freeReason')"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
          </v-row>
          <v-row>
            <!--総件数表示-->
            <div class="search-row-exeBtn first-search-item">
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--ボタン領域-->
              <div class="btn-area">
                <!-- アップロードボタン -->
                <v-btn
                  class="post-btn"
                  @click="viewConfirmDialog('btn_insert')"
                  v-bind:disabled="!activate"
                  >{{ $t("btn.btn_upload") }}</v-btn
                >
                <!-- 画面クリアボタン -->
                <v-btn class="other-btn" @click="viewConfirmDialog('btn_clear')">{{
                  $t("btn.btn_clear")
                }}</v-btn>
              </div>
              <!--予定数量合計数表示-->
              <div
                style="
                  float: left;
                  display: flex;
                  align-items: center;
                  width: 66%;
                  flex-direction: row-reverse;
                "
              >
                <div
                  style="
                    margin-left: 85%;
                    font-weight: bold;
                    font-size: large;
                    border-bottom: double;
                  "
                >
                  <span class="toatlPlanNum">予定数量合計：{{ totalPlanCount }}件</span>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :ripple="false"
          :items-per-page="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="580px"
          :page.sync="page"
          :options.sync="sortOptions"
        >
          <template v-slot:[`item.product`]="{ item }">
            <!-- 品番/品名 -->
            <div v-bind:class="{ isD: item.isItemCdFlg }">{{ item.itemCd }}</div>
            <div v-bind:class="{ isD: item.isItemNameFlg }">{{ item.itemName }}</div>
          </template>

          <template v-slot:[`item.accessoryCd`]="{ item }">
            <!-- 付帯品番 -->
            <div v-bind:class="{ isD: item.isAccessoryCdFlg }">{{ item.accessoryCd }}</div>
          </template>

          <template v-slot:[`item.toName`]="{ item }">
            <!-- 出荷元 -->
            <div v-bind:class="{ isD: item.istoNameFlg }">{{ item.toName }}</div>
          </template>

          <template v-slot:[`item.voucherNo`]="{ item }">
            <!-- 伝票No -->
            <div v-bind:class="{ isD: item.isVoucherNoFlg }"></div>
            <div class="listNo-style">{{ item.voucherNo }}</div>
          </template>

          <template v-slot:[`item.lotNo`]="{ item }">
            <!-- ロット -->
            <div v-bind:class="{ isD: item.isLotNoFlg }">{{ item.lotNo }}</div>
          </template>

          <template v-slot:[`item.scheduleQuantity`]="{ item }">
            <!-- 予定数量 -->
            <div v-bind:class="{ isD: item.isSchQtyFlg }">
              {{ item.scheduleQuantity.toLocaleString() }}
            </div>
          </template>

          <template v-slot:[`item.planDay`]="{ item }">
            <!-- 作業日 -->
            <div v-bind:class="{ isD: item.isPlanDayFlg }">{{ item.planDay }}</div>
          </template>
        </v-data-table>
        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="Math.ceil(inputList.length / itemsPerPage)"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="infoDialog.screenFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :roleMasterBatchAddUpdateFlg="infoDialog.roleMasterBatchAddUpdateFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";

export default {
  name: appConfig.SCREEN_ID.P_MST_014,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    file: {},
    loadingCounter: 0,
    importfile: {},
    // ファイル名
    fileName: {},
    distributionfileName: "",
    distributionListCsvData: [],
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    // ファイルTYPE
    desiredImportType: "01",
    desiredImportList: [],
    // 流通加工計画日付カレンダー
    dateMenu: false,
    // 作業日
    planDayCal: "",
    planDay: "",
    // 品質区分
    qualityList: [],
    qualitySelected: "01",
    // 有償/無償区分
    freeCostDivList: [],
    isPaid: "01",
    // 無償理由プルダウン
    freeReasonList: [],
    freeReasonSelected: "",
    isDisabledFreeReason: true,
    // 総件数
    totalCount: 0,
    // 予定数量合計
    totalPlanCount: 0,

    message: "Try Preview!",
    // 取込確定フラグ
    confirmFlg: false,
    // 取込ファイルチェックエラーメッセージ
    importFileCheckMsg: "",
    // 流通加工計画チェックエラーメッセージ
    distributionCheckMsg: "",

    // メニュー
    openMenu: null,
    csvData: [],
    // 表示の切り替え
    activate: false,
    // ファイルチェックフラグ
    checkFlf: false,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      isOpen: false,
      redMessage: "",
      okAction: () => {},
    },
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      screenFlag: false,
      changeFlag: false,
      homePageFlag: false,
      roleMasterBatchAddUpdateFlg: false,
      firstPageFlag: false,
      //ダイアログの外クリック制御
      outsideClickNotCloseFlg: false,
    },
    // 詳細ポップアップ
    dialogDetails: false,
    errorDetailDialogData: [],
    // ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        value: "No",
        width: "4%",
        sortable: false,
        align: "left",
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "product",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // 付帯品番
      {
        text: i18n.tc("label.lbl_accessoryCd"),
        value: "accessoryCd",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // 出荷先
      {
        text: i18n.tc("label.lbl_shippingTo"),
        value: "toName",
        width: "14%",
        align: "left",
        sortable: true,
      },
      // 伝票No
      {
        text: i18n.tc("label.lbl_voucherNo"),
        value: "voucherNo",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot"),
        value: "lotNo",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 予定数量
      {
        text: i18n.tc("label.lbl_scheduleQuantity"),
        value: "scheduleQuantity",
        width: "7%",
        align: "right",
        sortable: true,
      },
      // 作業日
      {
        text: i18n.tc("label.lbl_planDay"),
        value: "planDay",
        width: "7%",
        align: "left",
        sortable: true,
      },
    ],
    // ソート
    sortItem: "",
    sortOptions: {},
    inputList: [],
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      yyyymmdd: function (value) {
        if (!value) {
          return true;
        }
        if (
          !(
            dateTimeHelper.validDate(commonUtil.zen2han(value), "/") && dateTimeHelper.isDate(value)
          )
        ) {
          return i18n.tc("check.chk_inputDate");
        }
        return true;
      },
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      fileRequired: (value) => (!!value && !!value.name) || i18n.tc("check.chk_select"),
    },
    // 詳細ERROR画面へ渡す
    detailDialogData: {
      officeSelected: "",
      list: [],
    },
  }),
  methods: {
    // ダイアログオープン
    openPopup() {
      this.dialogDetails = true;
    },
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },

    /**
     * 初期表示
     */
    init() {
      this.inputList = [];
      this.planDay = "";
      this.planDayCal = "";
      this.qualitySelected = "01";
      this.freeReasonSelected = "";
      this.itemsPerPage = 10;
      // 取引先取得
      this.getCustomInfo();
      this.getMstCode();
      this.desiredImportList = [
        { value: "01", text: "csv" },
        { value: "02", text: "テキスト" },
      ];
    },
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action = () => {};
      // エラー内容初期化
      this.distributionCheckMsg = i18n.tc("");
      this.importFileCheckMsg = i18n.tc("");
      if (this.$refs.form.validate()) {
        switch (button) {
          case "btn_clear":
            isValidationRequired = true;
            message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_clear")]);
            action = this.clear;

            this.ConfirmDialog.message = message;
            this.ConfirmDialog.title = appConfig.DIALOG.title;
            this.ConfirmDialog.isOpen = true;
            this.ConfirmDialog.screenFlag = true;
            this.ConfirmDialog.changeFlag = false;
            this.ConfirmDialog.okAction = action;
            break;
          case "btn_insert":
            isValidationRequired = true;
            this.insertType();
            break;
          default:
            isValidationRequired = true;
            message = "";
        }
      } else {
        //処理なし
        return;
      }
      if (isValidationRequired) {
        // 処理なし
      }
    },

    // 登録
    insertType() {
      let message;
      let action = () => {};
      switch (this.desiredImportType) {
        case "01":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_insert")]);
          //action = this.csvRegister;
          break;
        case "02":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_insert")]);
          //action = this.excelRegister;
          break;
        default:
          message = "";
      }
      this.ConfirmDialog.message = message;
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = action;
    },

    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].text;
        }
      }
    },
    /**
     * 取引先コー取得
     */
    getSupplierCd() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].officeCd;
        }
      }
    },
    /**
     * 画面クリア押下
     */
    clear() {
      this.confirmFlg = false;
      this.inputList = [];
      this.importfile = null;
      this.desiredImportType = "01";
      this.planDay = "";
      this.planDayCal = "";
      this.qualitySelected = "01";
      this.freeReasonSelected = "";
      this.isPaid = "01";
      this.totalCount = 0;
      this.totalPlanCount = 0;
      this.activate = false;
      // ボタン識別フラグ（falseだったら、画面クリア）
      this.confirmFlg = false;
    },

    /**
     * 取込前のバリデーションチェック
     */
    search() {
      // 初期化
      this.inputList = [];
      this.totalCount = 0;
      this.totalPlanCount = 0;

      //エラー内容初期化
      this.distributionCheckMsg = i18n.tc("");
      this.importFileCheckMsg = i18n.tc("");

      this.loadingCounter = 1;

      if (this.desiredImportType == "01") {
        // 取込確定確認POPUP処理
        if (this.$refs.form.validate()) {
          this.csvImport();
        }
      } else {
        // 取込確定確認POPUP処理
        if (this.$refs.form.validate()) {
          this.textImport();
        }
      }

      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * 取込ボタン押下処理*(TEXT)
     * ファイル値チェック
     */
    textImport() {
      //TODO
      this.postTxet();
    },

    /**
     * 取込ボタン押下処理*(CSV)
     * ファイル値チェック
     */
    csvImport() {
      // 取込確定処理
      // 選択したファイルを読み込む
      const csvFile = this.importfile;
      console.log("filename: " + this.importfile.name);

      // 読込完了したら、次の処理に進むよう設定
      new Promise((resolve) => {
        // 読込
        const reader = new FileReader();

        reader.readAsText(csvFile);
        console.log("reader: " + "%j", reader);

        reader.onload = (event) => {
          // 改行毎にデータを分ける
          const lines = reader.result
            // CR,CRLF,LF改行処理
            .split(/\r\n|\n|\r/g)
            .filter((x) => x !== "")
            .filter((x) => x !== "\r");
          let chengeArrangement = {};
          var distributionListCsvDataori = [];

          // CSVから読み取ったデータを配列に編集
          for (var i = 0; i < lines.length; i++) {
            // ダブルクオーテーションをなくし、カンマで区切り、値を取りだす
            chengeArrangement = lines[i].replace(/"/g, "").split(",");
            distributionListCsvDataori.push(chengeArrangement);
          }

          this.distributionListCsvData = distributionListCsvDataori;
          console.log("this.distributionListCsvData: " + this.distributionListCsvData);
          // for (var j = 0; j < this.distributionListCsvData.length; j++) {
          //   var column = this.distributionListCsvData[0].length;
          //   // 1行ずつのカラム数をチェック
          //   if (this.inListCsvData[j].length != column) {
          //     this.infoDialog.message = i18n.tc("check.chk_inputColumn");
          //     this.infoDialog.title = appConfig.DIALOG.title;
          //     this.infoDialog.isOpen = true;
          //     this.infoDialog.screenFlag = true;
          //     return;
          //   }
          // }

          resolve(event.target.result);
        };
        // すでに取込したCSVファイルを変更してそのまま取込した場合
        reader.onerror = (e) => {
          console.log("reader.error: " + reader.error);
          if (e.target.error != null) {
            this.infoDialog.message = i18n.tc("check.chk_reSelectCsv");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            this.importfile = null;
          }
        };
      }).then(() => {
        this.postCsv();
      });
    },

    /**
     * POSTAPI(仮登録POST)
     */
    postTxet() {
      // メッセージ初期化
      this.distributionCheckMsg = i18n.tc("");
      this.importFileCheckMsg = i18n.tc("");

      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      body.reqIdv.planDay = dateTimeHelper.convertUTC(this.planDay);

      // //  画面ID
      // body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_006;

      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // ファイル名
      body.reqIdv.fileName = this.importfile.name;

      // console.debug("postCsv() Body", body);
      console.log("postCsv() Body", body);

      var data = [
        {
          // NO
          No: "1",
          // 品番
          isItemCdFlg: false,
          itemCd: "1001",
          // 品名
          isItemNameFlg: false,
          itemName: "財布1",
          // 付帯品番
          isAccessoryCdFlg: false,
          accessoryCd: "2001",
          // 出荷元
          istoNameFlg: false,
          toName: "出荷先A",
          // 出荷No
          isVoucherNoFlg: false,
          voucherNo: "3001",
          // ロット
          isLotNoFlg: false,
          lotNo: "ロットテスト01",
          // 予定数量
          isSchQtyFlg: false,
          scheduleQuantity: 1,
          // 作業日
          isPlanDayFlg: false,
          planDay: "2023/05/26",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "2",
          // 品番
          itemCd: "1002",
          // 品名
          itemName: "財布2",
          // 付帯品番
          accessoryCd: "2002",
          // 出荷元
          toName: "出荷先A",
          // 伝票No
          voucherNo: "3002",
          // ロット
          lotNo: "ロットテスト02",
          // 予定数量
          scheduleQuantity: 2,
          // 加工日
          planDay: "2023/05/27",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "3",
          // 品番
          itemCd: "1003",
          // 品名
          itemName: "財布3",
          // 付帯品番
          accessoryCd: "2003",
          // 出荷元
          toName: "出荷先B",
          // 伝票No
          voucherNo: "3003",
          // ロット
          lotNo: "ロットテスト03",
          // 予定数量
          scheduleQuantity: 3,
          // 加工日
          planDay: "2023/05/28",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "4",
          // 品番
          itemCd: "1004",
          // 品名
          itemName: "財布4",
          // 付帯品番
          accessoryCd: "2004",
          // 出荷元
          toName: "出荷先B",
          // 伝票No
          voucherNo: "3004",
          // ロット
          lotNo: "ロットテスト04",
          // 予定数量
          scheduleQuantity: 5,
          // 加工日
          planDay: "2023/05/29",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "5",
          // 品番
          itemCd: "1005",
          // 品名
          itemName: "財布5",
          // 付帯品番
          accessoryCd: "2005",
          // 出荷元
          toName: "出荷先C",
          // 伝票No
          voucherNo: "3005",
          // ロット
          lotNo: "ロットテスト05",
          // 予定数量
          scheduleQuantity: 5,
          // 作業日
          planDay: "2023/05/30",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "6",
          // 品番
          itemCd: "1006",
          // 品名
          itemName: "カラーBOX1",
          // 付帯品番
          accessoryCd: "2006",
          // 出荷元
          toName: "出荷先A",
          // 伝票No
          voucherNo: "3006",
          // ロット
          lotNo: "ロットテスト06",
          // 予定数量
          scheduleQuantity: 6,
          // 作業日
          planDay: "2023/06/01",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "7",
          // 品番
          itemCd: "1007",
          // 品名
          itemName: "カラーBOX2",
          // 付帯品番
          accessoryCd: "2007",
          // 出荷元
          toName: "出荷先A",
          // 伝票No
          voucherNo: "3007",
          // ロット
          lotNo: "ロットテスト07",
          // 予定数量
          scheduleQuantity: 7,
          // 作業日
          planDay: "2023/06/02",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "8",
          // 品番
          itemCd: "1008",
          // 品名
          itemName: "カラーBOX3",
          // 付帯品番
          accessoryCd: "2008",
          // 出荷元
          toName: "出荷先B",
          // 伝票No
          voucherNo: "3008",
          // ロット
          lotNo: "ロットテスト08",
          // 予定数量
          scheduleQuantity: 8,
          // 作業日
          planDay: "2023/06/03",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "9",
          // 品番
          itemCd: "1009",
          // 品名
          itemName: "カラーBOX4",
          // 付帯品番
          accessoryCd: "2009",
          // 出荷元
          toName: "出荷先B",
          // 伝票No
          voucherNo: "3009",
          // ロット
          lotNo: "ロットテスト09",
          // 予定数量
          scheduleQuantity: 9,
          // 作業日
          planDay: "2023/06/04",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "10",
          // 品番
          itemCd: "1010",
          // 品名
          itemName: "カラーBOX5",
          // 付帯品番
          accessoryCd: "2010",
          // 出荷元
          toName: "出荷先C",
          // 伝票No
          voucherNo: "3010",
          // ロット
          lotNo: "ロットテスト10",
          // 予定数量
          scheduleQuantity: 10,
          // 作業日
          planDay: "2023/06/05",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "11",
          // 品番
          itemCd: "1011",
          // 品名
          itemName: "カラーBOX6",
          // 付帯品番
          accessoryCd: "2011",
          // 出荷元
          toName: "出荷先C",
          // 伝票No
          voucherNo: "3011",
          // ロット
          lotNo: "ロットテスト11",
          // 予定数量
          scheduleQuantity: 3,
          // 作業日
          planDay: "2023/06/06",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "12",
          // 品番
          itemCd: "1012",
          // 品名
          itemName: "カラーBOX7",
          // 付帯品番
          accessoryCd: "2012",
          // 出荷元
          toName: "出荷先A",
          // 伝票No
          voucherNo: "3012",
          // ロット
          lotNo: "ロットテスト12",
          // 予定数量
          scheduleQuantity: 12,
          // 作業日
          planDay: "2023/06/07",
        },
      ];

      // 画面表示用リストに格納
      this.inputList = data;
      this.isErr();
      // 総件数取得
      this.totalCount = this.inputList.length;
      // 予定数量合計取得
      var cnt = 0;
      this.totalPlanCount = 0;
      for (var i = 0; i < this.inputList.length; i++) {
        cnt = cnt + this.inputList[i].scheduleQuantity;
      }
      this.totalPlanCount = cnt;

      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * POSTAPI(仮登録POST)
     */
    postCsv() {
      // メッセージ初期化
      this.distributionCheckMsg = i18n.tc("");
      this.importFileCheckMsg = i18n.tc("");

      // csvファイルチェック

      // // ファイル名の桁数をチェック
      // if (fileNameLength > 31) {
      //   this.infoDialog.message = i18n.tc("check.chk_limitFileName");
      //   this.infoDialog.title = appConfig.DIALOG.title;
      //   this.infoDialog.isOpen = true;
      //   this.infoDialog.screenFlag = true;
      //   this.infoDialog.firstPageFlag = true;
      //   return;
      // }
      // // ファイルサイズ上限チェック
      // if (fileSize == 3000000 || fileSize > 3000000) {
      //   this.infoDialog.message = i18n.tc("check.chk_limitFileSizeMax");
      //   this.infoDialog.title = appConfig.DIALOG.title;
      //   this.infoDialog.isOpen = true;
      //   this.infoDialog.screenFlag = true;
      //   this.infoDialog.firstPageFlag = true;
      //   return;
      // }
      // // ファイルサイズ下限チェック
      // if (fileSize == 0 || fileSize < 0) {
      //   this.infoDialog.message = i18n.tc("check.chk_limitFileSizeMin");
      //   this.infoDialog.title = appConfig.DIALOG.title;
      //   this.infoDialog.isOpen = true;
      //   this.infoDialog.screenFlag = true;
      //   this.infoDialog.firstPageFlag = true;
      //   return;
      // }
      // // ファイル拡張子をチェック
      // if (fileType != "text/csv") {
      //   this.infoDialog.message = i18n.tc("check.chk_limitFileType");
      //   this.infoDialog.title = appConfig.DIALOG.title;
      //   this.infoDialog.isOpen = true;
      //   this.infoDialog.screenFlag = true;
      //   this.infoDialog.firstPageFlag = true;
      //   return;
      // }

      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      body.reqIdv.planDay = dateTimeHelper.convertUTC(this.planDay);

      // //  画面ID
      // body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_006;

      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // ファイル名
      body.reqIdv.fileName = this.importfile.name;

      // console.debug("postCsv() Body", body);
      console.log("postCsv() Body", body);

      var data = [
        {
          // NO
          No: "1",
          // 品番
          isItemCdFlg: false,
          itemCd: "1001",
          // 品名
          isItemNameFlg: false,
          itemName: "財布1",
          // 付帯品番
          isAccessoryCdFlg: false,
          accessoryCd: "2001",
          // 出荷元
          istoNameFlg: false,
          toName: "出荷先A",
          // 出荷No
          isVoucherNoFlg: false,
          voucherNo: "3001",
          // ロット
          isLotNoFlg: false,
          lotNo: "ロットテスト01",
          // 予定数量
          isSchQtyFlg: false,
          scheduleQuantity: 1,
          // 作業日
          isPlanDayFlg: false,
          planDay: "2023/05/26",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "2",
          // 品番
          itemCd: "1002",
          // 品名
          itemName: "カラーBOX1",
          // 付帯品番
          accessoryCd: "2002",
          // 出荷元
          toName: "出荷先B",
          // 伝票No
          voucherNo: "3002",
          // ロット
          lotNo: "ロットテスト02",
          // 計画数
          scheduleQuantity: 2,
          // 作業日
          planDay: "2023/05/27",
        },
        {
          isItemCdFlg: false,
          isItemNameFlg: false,
          isAccessoryCdFlg: false,
          istoNameFlg: false,
          isVoucherNoFlg: false,
          isLotNoFlg: false,
          isSchQtyFlg: false,
          isPlanDayFlg: false,
          // NO
          No: "3",
          // 品番
          itemCd: "1003",
          // 品名
          itemName: "財布2",
          // 付帯品番
          accessoryCd: "2003",
          // 出荷元
          toName: "出荷先C",
          // 伝票No
          voucherNo: "3003",
          // ロット
          lotNo: "ロットテスト03",
          // 予定数量
          scheduleQuantity: 3,
          // 作業日
          planDay: "2023/05/28",
        },
      ];

      // 画面表示用リストに格納
      this.inputList = data;
      this.isErr();
      // 総件数取得
      this.totalCount = this.inputList.length;
      // 予定数量合計取得
      var cnt = 0;
      this.totalPlanCount = 0;
      for (var i = 0; i < this.inputList.length; i++) {
        cnt = cnt + this.inputList[i].scheduleQuantity;
      }
      this.totalPlanCount = cnt;

      // ローディング画面表示OFF
      this.loadingCounter = 0;

      // return new Promise((resolve, reject) => {
      //   this.$httpClient
      //     // 接続先のAPI_URLを入力
      //     .securePost(appConfig.API_URL.BIZ_INSCHDULESFILE, body, appConfig.APP_CONFIG)
      //     .then((response) => {
      //       // console.debug("postCsv() Response", response);
      //       const jsonData = JSON.parse(JSON.stringify(response.data));

      //       // 正常時
      //       if (jsonData.resCom.resComCode == "000") {
      //         if (jsonData.resIdv.schedulesFileTmp.csvErrorCheckResultList.length != 0) {
      //           const errorList = [];
      //           this.errorDetailDialogData = [];
      //           jsonData.resIdv.schedulesFileTmp.csvErrorCheckResultList.forEach((row) => {
      //             errorList.push({
      //               listNo: row.listNo,
      //               row: row.row,
      //               column: row.column,
      //               message: row.message,
      //               value: row.value,
      //             });
      //             this.errorDetailDialogData = errorList;
      //           });
      //           // エラー一覧タブが開く
      //           // 詳細画面へ渡す値を設定します。
      //           this.detailDialogData.list = this.errorDetailDialogData;
      //           // 選択された列名
      //           this.detailDialogData.suppliersSelectedName = this.setSrhSupplier();
      //           this.dialogDetails = true;
      //         } else {
      //           // this.pageCount = jsonData.resCom.resComPagination.totalPage;
      //           const list = [];
      //           // ファイル名
      //           this.fileName = jsonData.resIdv.schedulesFileTmp.fileName;
      //           // S3登録ファイル名
      //           this.s3FileName = jsonData.resIdv.schedulesFileTmp.s3FileName;
      //           // 画面表示項目
      //           jsonData.resIdv.schedulesFileTmp.scheduleCsvResults.forEach((row) => {
      //             let isErrFlg = false;
      //             let errMsg = "";
      //             let rcvDate = "";
      //             if (row.errMsg) {
      //               isErrFlg = true;
      //               errMsg = row.errMsg;
      //               errMsg = errMsg.replace("\r\n", "<br />");
      //               this.activate = false;
      //             }
      //             if (row.scheduleDate.length == 8) {
      //               rcvDate =
      //                 row.scheduleDate.substr(0, 4) +
      //                 "/" +
      //                 row.scheduleDate.substr(4, 2) +
      //                 "/" +
      //                 row.scheduleDate.substr(6, 2);
      //             } else if (row.scheduleDate.length == 6) {
      //               rcvDate =
      //                 row.scheduleDate.substr(0, 2) +
      //                 "/" +
      //                 row.scheduleDate.substr(2, 2) +
      //                 "/" +
      //                 row.scheduleDate.substr(4, 2);
      //             } else {
      //               rcvDate =
      //                 row.scheduleDate.substr(0, 4) +
      //                 "/" +
      //                 row.scheduleDate.substr(5, 2) +
      //                 "/" +
      //                 row.scheduleDate.substr(8, 2);
      //             }
      //             list.push({
      //               isInListNoFlg: isErrFlg,
      //               isShipInsDateFlg: isErrFlg,
      //               isItemCdFlg: isErrFlg,
      //               isItemNameFlg: isErrFlg,
      //               isInQtyeFlg: isErrFlg,
      //               isFromNameFlg: isErrFlg,
      //               isAddInfoFlg: isErrFlg,
      //               // No
      //               No: row.no,
      //               // 入荷No
      //               inListNo: row.listNo,
      //               // 入荷予定日
      //               // shipInsDate: row.scheduleDate,
      //               shipInsDate: rcvDate,
      //               // rcvInDate: rcvDate,
      //               // 品番
      //               itemCd: row.itemCd,
      //               // 品名
      //               itemName: row.itemName,
      //               // ロット
      //               lotNo: row.lotNo,
      //               // 数量
      //               inScheduleQuantity: row.scheduleQuantity,
      //               // 入荷元
      //               fromName: row.fromName,
      //               // 入荷元SID
      //               fromSid: row.fromSid,
      //               // 出荷先SID
      //               toSid: row.toSid,
      //               // 付帯情報
      //               // addInfo: row.addInfo,
      //               accInfos: errMsg,
      //               csvProcessDiv: row.csvProcessDiv,
      //               // errInList:0か1か入って来てエラーか判断する
      //             });
      //           });
      //           // 画面表示用リストに格納
      //           this.inputList = list;
      //           this.isErr();
      //           // 総件数取得
      //           this.totalCount = this.inputList.length;
      //           // 数量合計取得
      //           var cnt = 0;
      //           this.totalPlanCount = 0;
      //           for (var i = 0; i < this.inputList.length; i++) {
      //             cnt = cnt + this.inputList[i].inScheduleQuantity;
      //           }
      //           this.totalPlanCount = cnt;
      //           resolve(response);
      //         }
      //       } else {
      //         // エラー時
      //         this.infoDialog.message = jsonData.resCom.resComMessage;
      //         this.infoDialog.title = appConfig.DIALOG.title;
      //         this.infoDialog.isOpen = true;
      //         this.infoDialog.firstPageFlag = true;

      //         reject(resolve);
      //       }
      //     })
      //     .catch((ex) => {
      //       this.infoDialog.message = ex;
      //       this.infoDialog.title = appConfig.DIALOG.title;
      //       this.infoDialog.isOpen = true;
      //       this.infoDialog.firstPageFlag = true;

      //       reject();
      //     })
      //     .finally(() => {
      //       // ローディング画面表示OFF
      //       this.loadingCounter = 0;
      //     });
      // });
    },

    /**
     * マスタコード取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);

      Promise.all([qualityList, reasonList, freeCostList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.qualityList = result[0];
          this.freeReasonList = result[1];
          this.freeCostDivList = result[2];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 取引先取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getCustomInfo() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.compSid, // 取引先Sid
                  officeCd: row.clientCd, // 取引先Cd
                });
              });
              this.supplierList = list;
              // 取引先が1件の場合、取引先を自動選択する
              this.supplierList.length == 1
                ? (this.suppliersSelected = this.supplierList[0].value)
                : "";
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 作業日を－１日します。
     */
    prevDate() {
      let today = dateTimeHelper.convertJST();

      if (this.planDayCal == null) {
        return;
      }
      if (this.planDayCal == dateTimeHelper.dateCalc(today)) {
        return;
      }
      let date = new Date(this.planDayCal);
      this.planDayCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /**
     * 作業日を＋１日します。
     */
    nextDate() {
      if (this.planDayCal == null) {
        return;
      }
      let date = new Date(this.planDayCal);
      this.planDayCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.planDayCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.planDayCal = null;
      }
    },

    isErr() {
      let countErr = 0;
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].isErrListFlg) {
          countErr += 1;
        }
      }
      if (countErr == 0) {
        this.activate = true;
      }
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * アイコンを押下しても、エクスプローラーが開く処理
     */
    handleClickAppendOuter() {
      this.$refs.vFileInput.$el.querySelector("input").click();
    },
  },
  computed: {},
  watch: {
    planDayCal(val) {
      this.planDay = this.formatDate(val);
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    page: function (newValue) {
      this.page = newValue;
      this.search(false);
    },
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
      }
    },
  },

  sortOptions: {
    handler(items) {
      if (items.sortBy.length < 1) {
        this.sortItem = "";
      } else {
        var sortBy = items.sortBy[0];
        var ascdesc = items.sortDesc[0] ? "desc" : "asc";
        this.sortItem = sortBy + " " + ascdesc;
        if (sortBy == "No") {
          this.inputList.sort((a, b) => {
            if (ascdesc == "asc") {
              return a.No - b.No;
            } else {
              return b.No - a.No;
            }
          });
        } else if (sortBy == "voucherNo") {
          this.inputList.sort((a, b) => {
            if (ascdesc == "asc") {
              if (a.voucherNo < b.voucherNo) {
                return -1;
              } else if (a.voucherNo > b.voucherNo) {
                return 1;
              } else {
                return 0;
              }
            } else {
              if (a.voucherNo > b.voucherNo) {
                return -1;
              } else if (a.voucherNo < b.voucherNo) {
                return 1;
              } else {
                return 0;
              }
            }
          });
        } else if (sortBy == "planDay") {
          this.inputList.sort((a, b) => {
            if (ascdesc == "asc") {
              if (a.planDay < b.planDay) {
                return -1;
              } else if (a.planDay > b.planDay) {
                return 1;
              } else {
                return 0;
              }
            } else {
              if (a.planDay > b.planDay) {
                return -1;
              } else if (a.planDay < b.planDay) {
                return 1;
              } else {
                return 0;
              }
            }
          });
        } else if (sortBy == "scheduleQuantity") {
          this.inputList.sort((a, b) => {
            if (ascdesc == "asc") {
              return a.scheduleQuantity - b.scheduleQuantity;
            } else {
              return b.scheduleQuantity - a.scheduleQuantity;
            }
          });
        } else if (sortBy == "toName") {
          this.inputList.sort((a, b) => {
            if (ascdesc == "asc") {
              if (a.toName < b.toName) {
                return -1;
              } else if (a.toName > b.toName) {
                return 1;
              } else {
                return 0;
              }
            } else {
              if (a.toName > b.toName) {
                return -1;
              } else if (a.toName < b.toName) {
                return 1;
              } else {
                return 0;
              }
            }
          });
        } else if (sortBy == "product") {
          this.inputList.sort((a, b) => {
            if (ascdesc == "asc") {
              if (a.itemCd < b.itemCd) {
                return -1;
              } else if (a.itemCd > b.itemCd) {
                return 1;
              } else {
                return 0;
              }
            } else {
              if (a.itemCd > b.itemCd) {
                return -1;
              } else if (a.itemCd < b.itemCd) {
                return 1;
              } else {
                return 0;
              }
            }
          });
        }
        return this.inputList;
      }
    },
    deep: true,
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.custom-radio-group {
  margin-top: 0px;
}
</style>
