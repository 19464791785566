<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_distributionMaterialsOrderingPointAdd" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="searchParam.suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="getSupplierName"
                :rules="[rules.supplierRequired]"
                :error-messages="alertSupplierMessage"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- カレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="searchParam.referenceDate"
                class="txt-single date-style"
                :label="$t('label.lbl_refernceDate')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="searchParam.referenceDateCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfAchieveTo()"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDateOfAchieveTo()"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 間隔-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="interval"
                :items="intervalList"
                :label="$t('label.lbl_interval')"
                class="txt-single"
                :hint="getInterval"
                persistent-hint
                :rules="[rules.inputRequired]"
                @change="changeInterval"
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 商品コード/品名-->
            <div class="search-autocomplete">
              <v-autocomplete
                v-model="searchParam.materialCd"
                item-value="code"
                item-text="text"
                :items="productList"
                :hint="productName"
                persistent-hint
                maxlength="100"
                :search-input.sync="itemCdInputText"
                dense
                :disabled="
                  searchParam.suppliersSelected == null || searchParam.suppliersSelected == ''
                "
                :label="$t('label.lbl_productCnCd')"
              >
                <option disabled value="itemStandardSid"></option>
                <!-- アイテム一覧の表示  -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>

            <!-- 発注点-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="orderPointDiv"
                :items="orderPointDivList"
                :label="$t('label.lbl_orderingPoint')"
                class="txt-single"
                :hint="getOrderPointDiv"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn class="get-btn" @click="getList()">{{ $t("btn.btn_search") }}</v-btn>
                <!-- 画面クリアボタン -->
                <v-btn class="other-btn" @click="clearConfirm">{{ $t("btn.btn_clear") }}</v-btn>
              </div>

              <div class="btn-area">
                <!-- 計算ボタン -->
                <v-btn color="primary" id="btn-search" class="other-btn" @click="calculation">
                  {{ $t("btn.btn_calculation") }}</v-btn
                >

                <!-- 登録ボタン -->
                <v-btn color="primary" id="btn-search" class="other-btn" @click="regist">
                  {{ $t("btn.btn_insert") }}</v-btn
                >
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :hide-default-footer="true"
            disable-filtering
            disable-sort
            height="620px"
            class="table-style"
          >
            <!-- 品番/品名 -->
            <template v-slot:[`item.productCnCd`]="{ item }">
              <div class="table-data-align-left">
                {{ item.productCnCd }}
              </div>
              <div class="table-data-align-left">
                {{ item.productName }}
              </div>
            </template>

            <!-- 発注点-->
            <template v-slot:[`item.orderingPoint`]="{ item, index }">
              <v-text-field
                outlined
                dense
                v-model="item.orderingPoint"
                class="input-number"
                @change="changeOrderingPoint(item, index)"
                clear-icon="mdi-close-circle"
                maxLength="10"
                clearable
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheckPlan]"
                :ref="'orderingPointCheck' + index"
              />
            </template>

            <!-- 調達期間 -->
            <template v-slot:[`item.leadTime`]="{ item, index }">
              <v-text-field
                outlined
                dense
                v-model="item.leadTime"
                @change="changeLeadTime(item, index)"
                class="input-number"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'leadTimeCheck' + index"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheckPlan]"
                :error-messages="item.requiredItemCheckMsg"
              />
            </template>

            <!-- 間隔1 -->
            <template v-slot:[`item.intervalFirst`]="{ item }">
              <div class="table-data-align-right">
                {{ item.intervalFirst }}
              </div>
            </template>

            <!-- 間隔2 -->
            <template v-slot:[`item.intervalSecond`]="{ item }">
              <div class="table-data-align-right">
                {{ item.intervalSecond }}
              </div>
            </template>

            <!-- 間隔3 -->
            <template v-slot:[`item.intervalThird`]="{ item }">
              <div class="table-data-align-right">
                {{ item.intervalThird }}
              </div>
            </template>

            <!-- 間隔4 -->
            <template v-slot:[`item.intervalFourth`]="{ item }">
              <div class="table-data-align-right">
                {{ item.intervalFourth }}
              </div>
            </template>

            <!-- 間隔5 -->
            <template v-slot:[`item.intervalFifth`]="{ item }">
              <div class="table-data-align-right">
                {{ item.intervalFifth }}
              </div>
            </template>

            <!-- 間隔6 -->
            <template v-slot:[`item.intervalSixth`]="{ item }">
              <div class="table-data-align-right">
                {{ item.intervalSixth }}
              </div>
            </template>

            <!-- 平均出庫数 -->
            <template v-slot:[`item.averageIssue`]="{ item }">
              <div class="table-data-align-right">
                {{ item.averageIssue }}
              </div>
            </template>

            <!-- 安全在庫数 -->
            <template v-slot:[`item.saftyStockQuantity`]="{ item }">
              <div class="table-data-align-right">
                {{ item.saftyStockQuantity }}
              </div>
            </template>

            <!-- 安全係数 -->
            <template v-slot:[`item.safetyFactor`]="{ item }">
              <div class="table-data-align-right">
                {{ item.safetyFactor }}
              </div>
            </template>

            <!-- 標準偏差 -->
            <template v-slot:[`item.standardDviation`]="{ item }">
              <div class="table-data-align-right">
                {{ item.standardDviation }}
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="Math.ceil(inputList.length / itemsPerPage)"
              :total-visible="7"
            ></v-pagination>
          </div>
        </v-container>
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :homePageFlag="infoDialog.homePageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :dstInventoryConfirmFlg="infoDialog.dstInventoryConfirmFlg"
        :dstMaterialsOrderPointFlg="infoDialog.dstMaterialsOrderPointFlg"
        :okAction="init"
      />
      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 戻るボタンのダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";

export default {
  name: appConfig.SCREEN_ID.P_TMP_912,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ヘッダ
    // 検索ヘッダー
    searchParam: {
      referenceDate: "",
      referenceDateCal: "",
      materialCd: "",
      interval: "",
      suppliersSelected: "",
    },
    // カレンダー
    dateMenu: false,

    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 取引先アラート
    alertSupplierMessage: "",

    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",

    // 間隔リスト
    intervalList: [
      {
        value: "01",
        text: "日",
      },
      {
        value: "02",
        text: "週",
      },
      {
        value: "03",
        text: "月",
      },
    ],

    // 数量リスト
    intervalQuantityList: [],

    // 偏差リスト
    deviationList: [],

    // 間隔
    interval: "01",

    // 発注点区分
    orderPointDiv: "",

    // 発注点区分リスト
    orderPointDivList: [
      {
        value: "01",
        text: "未設定",
      },
    ],

    // 月の間隔
    intervalMonth: "",
    // 間隔1
    intervalFirst: "",

    // 間隔2
    intervalSecond: "",

    // 間隔3
    intervalThird: "",

    // 間隔4
    intervalFourth: "",

    // 間隔5
    intervalFifth: "",

    // 間隔6
    intervalSixth: "",

    // 取引先
    supplierList: [],

    // 初期データ
    defaultData: [],

    // 出荷先
    shippingList: [],
    shipperInputText: "",

    // 品番
    itemCdInputText: "",
    productList: [],

    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      isOpen: false,
      redMessage: "",
      okAction: () => {},
    },

    // 一覧表示用
    totalCount: 0,
    inputList: [],
    headerRespose: [],

    // 初期表示フラグ
    initFlg: false,

    // 検索結果保持リスト
    searchResultList: [],
    // 更新対象明細リスト
    updateTargetList: [],

    // ヘッダ
    headerItems: [
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "650px",
        align: "left",
        sortable: false,
      },
      // 発注点
      {
        text: i18n.tc("label.lbl_orderingPoint"),
        value: "orderingPoint",
        align: "center",
        width: "200px",
        sortable: false,
      },
      // 調達期間
      {
        text: i18n.tc("label.lbl_leadTime"),
        value: "leadTime",
        align: "center",
        width: "120px",
        sortable: false,
      },
      // 間隔1
      {
        text: "",
        value: "intervalFirst",
        width: "100px",
        align: "right",
        sortable: false,
      },
      // 間隔2
      {
        text: "",
        value: "intervalSecond",
        width: "100px",
        align: "right",
        sortable: false,
      },
      // 間隔3
      {
        text: "",
        value: "intervalThird",
        width: "100px",
        align: "right",
        sortable: false,
      },
      // 間隔4
      {
        text: "",
        value: "intervalFourth",
        width: "346px",
        align: "right",
        sortable: false,
      },
      // 間隔5
      {
        text: "",
        value: "intervalFifth",
        width: "100px",
        align: "right",
        sortable: false,
      },
      // 間隔6
      {
        text: "",
        value: "intervalSixth",
        width: "100px",
        align: "right",
        sortable: false,
      },
      // 平均出庫数
      {
        text: i18n.tc("label.lbl_averageIssue"),
        value: "averageIssue",
        align: "center",
        width: "140px",
        sortable: false,
      },
      // 安全在庫数
      {
        text: i18n.tc("label.lbl_saftyStockQuantity"),
        value: "saftyStockQuantity",
        width: "140px",
        align: "center",
        sortable: false,
      },
      // 安全係数
      {
        text: i18n.tc("label.lbl_safetyFactor"),
        value: "safetyFactor",
        align: "center",
        width: "140px",
        sortable: false,
      },
      // 標準偏差
      {
        text: i18n.tc("label.lbl_standardDviation") + "\n" + i18n.tc("label.lbl_distributed"),
        value: "standardDviation",
        align: "center",
        width: "110px",
        sortable: false,
      },
    ],

    // バリデーション
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      // 数値チェック
      isNumber: (value) =>
        value == null ||
        value == "" ||
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      // 整数チェック
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      // 1以上を許容
      isRangeCheckPlan: (value) =>
        value == null ||
        value == "" ||
        1 <= Number(commonUtil.zen2han(value).replaceAll(",", "")) ||
        i18n.tc("check.chk_inputMore"),
      // 入力チェック
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
    },

    // その他ダイアログ
    openMenu: false,
    loadingCounter: 0,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      dstInventoryConfirmFlg: false,
      dstMaterialsOrderPointFlg: false,
      homePageFlag: false,
      outsideClickNotCloseFlg: false,
      okAction: () => {},
    },
  }),
  methods: {
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_100 = true;
      }
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.showList();
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.showList();
    },
    /** 初期化処理 */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      if (this.$route.params.searchParam) {
        this.searchParam = this.$route.params.searchParam;
        return;
      }

      this.initFlg = true;
      this.searchParam.referenceDate = dateTimeHelper.toStringNowYYYY_MM_DD1();
      this.searchParam.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.searchParam.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      // this.getList();
      this.getSupplier();
      this.getDateInterval(this.searchParam.referenceDate);
    },

    /**
     * 平均在庫数と標準偏差の取得
     */
    getAveIssueAndStandardDeviation(val) {
      this.deviationList = [];
      this.intervalQuantityList = [];
      // 数量1
      var stockFirst = Number(val.intervalFirst.replaceAll(",", ""));
      // 数量2
      var stockSecond = Number(val.intervalSecond.replaceAll(",", ""));
      // 数量3
      var stockThird = Number(val.intervalThird.replaceAll(",", ""));
      // 数量4
      var stockFourth = Number(val.intervalFourth.replaceAll(",", ""));
      // 数量5
      var stockFifth = Number(val.intervalFifth.replaceAll(",", ""));
      // 数量6
      var stockSixth = Number(val.intervalSixth.replaceAll(",", ""));
      // 取得してきた数量を数量リストに詰める
      this.intervalQuantityList.push(
        ...[
          {
            intervalFirst: stockFirst,
            intervalSecond: stockSecond,
            intervalThird: stockThird,
            intervalFourth: stockFourth,
            intervalFifth: stockFifth,
            intervalSixth: stockSixth,
          },
        ]
      );
      // 平均出庫数の計算
      var aveIssue = this.intervalQuantityList.map((data) => {
        return Math.round(
          Number(
            data.intervalFirst +
              data.intervalSecond +
              data.intervalThird +
              data.intervalFourth +
              data.intervalFifth +
              data.intervalSixth
          ) / 6
        );
      })[0];
      // 平均出庫数を一覧データに追加
      val.averageIssue = commonUtil.formatToCurrency(aveIssue);

      // 偏差の二乗を偏差リストに詰める
      this.deviationList.push(
        ...[
          {
            deviationSquaringFirst: (stockFirst - aveIssue) ** 2,
            deviationSquaringSecond: (stockSecond - aveIssue) ** 2,
            deviationSquaringThird: (stockThird - aveIssue) ** 2,
            deviationSquaringFourth: (stockFourth - aveIssue) ** 2,
            deviationSquaringFifth: (stockFifth - aveIssue) ** 2,
            deviationSquaringSixth: (stockSixth - aveIssue) ** 2,
          },
        ]
      );

      // 分散の計算
      var variance = this.deviationList.map((data) => {
        return (
          Number(
            data.deviationSquaringFirst +
              data.deviationSquaringSecond +
              data.deviationSquaringThird +
              data.deviationSquaringFourth +
              data.deviationSquaringFifth +
              data.deviationSquaringSixth
          ) / 6
        );
      });

      // 一覧データの標準偏差に計算結果を設定
      val.standardDviation = commonUtil.formatToCurrency(Math.round(Math.sqrt(variance)));
    },

    /**
     * 安全在庫数と発注点の取得
     */
    getSaftyStockAndOrderingPoint(val) {
      // 発注点の計算に必要な値を数値化
      // 平均出庫数
      var aveIssue = Number(val.averageIssue.replaceAll(",", ""));
      // 調達期間
      var leadTime = Number(val.leadTime);
      // 標準偏差
      var standardDeviation = Number(val.standardDviation.replaceAll(",", ""));

      // 安全在庫数
      var saftyStock = Math.round(
        val.safetyFactor * standardDeviation * Math.sqrt(Number(val.leadTime))
      );
      // 発注点
      var orderPoint = Math.round(aveIssue * leadTime + saftyStock);
      // 一覧データの安全在庫数に計算結果を設定
      val.saftyStockQuantity = commonUtil.formatToCurrency(saftyStock);
      // 一覧データの発注点に計算結果を設定
      val.orderingPoint = commonUtil.formatToCurrency(orderPoint);
    },

    /**
     * 発注点の取得
     */
    getOrderingPoint(val) {
      // 平均出庫数
      var aveIssue = Number(val.averageIssue.replaceAll(",", ""));
      var safety = Number(val.saftyStockQuantity.replaceAll(",", ""));
      // 発注点
      var orderPoint = Math.round(aveIssue * Number(val.leadTime) + safety);
      // 一覧データの発注点に計算結果を設定
      val.orderingPoint = commonUtil.formatToCurrency(orderPoint);
    },

    /**
     * 発注点・安全在庫数の計算処理
     */
    calculation() {
      for (let i = 0; i < this.inputList.length; i++) {
        this.inputList[i].requiredItemCheckMsg = i18n.tc("");
        // 調達期間が0の場合
        if (Number(this.inputList[i].leadTime) == 0) {
          // ダイアログ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-DST-002_002_E", [
            i18n.tc("label.lbl_alertLeadTime"),
          ]);
          return;
        }
        // 調達期間が0でない、かつ安全在庫数が0の場合
        if (
          Number(this.inputList[i].leadTime) != 0 &&
          Number(this.inputList[i].saftyStockQuantity) == 0
        ) {
          this.inputList[i].requiredItemCheckMsg = i18n.tc("");
          // 安全在庫数と発注点の計算
          this.getSaftyStockAndOrderingPoint(this.inputList[i]);
          // 調達期間と安全在庫数がどちらも0でない場合
        } else if (
          Number(this.inputList[i].leadTime) != 0 &&
          Number(this.inputList[i].saftyStockQuantity) != 0
        ) {
          this.inputList[i].requiredItemCheckMsg = i18n.tc("");
          // 発注点の計算
          this.getOrderingPoint(this.inputList[i]);
        } else {
          // 処理なし
        }
      }
    },

    /**
     * ダイアログ、OKボタン処理
     */
    updateOk() {
      // ダイアログを閉じる
      this.updateDialogMessage = false;
    },

    // ヘッダー関連
    // カレンダー関連
    /** 実績日付をー１日 */
    preDateOfAchieveTo() {
      if (this.searchParam.referenceDateCal == null) {
        return;
      }
      let date = new Date(this.searchParam.referenceDateCal);
      this.searchParam.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /** 実績日付を+１日 */
    nextDateOfAchieveTo() {
      if (this.searchParam.referenceDateCal == null) {
        return;
      }
      let date = new Date(this.searchParam.referenceDateCal);
      this.searchParam.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /** 日付入力 */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(4, 6) + "/" + inputVal.slice(6, 8);
      } else {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(5, 7) + "/" + inputVal.slice(8, 10);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.searchParam.referenceDateCal = dateTimeHelper.dateCalc(strDt);
        this.searchParam.referenceDate = strDt;
      } else {
        this.searchParam.referenceDateCal = null;
      }
    },

    /** カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。*/
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 資材発注登録画面：入力カンマ処理 発注点
     */
    changeOrderingPoint(val, index) {
      var orderingPoint = commonUtil.zen2han(val.orderingPoint).replaceAll(",", "");
      orderingPoint = orderingPoint.replace(/[^0-9]/gi, "");
      if (orderingPoint == "-0") {
        orderingPoint = "0";
      }
      if (orderingPoint.length > 10) {
        orderingPoint = orderingPoint.substring(0, 10);
      }
      if (!isNaN(Number(orderingPoint))) {
        this.inputList[index].orderingPoint = commonUtil.formatToCurrency(Number(orderingPoint));
      }
    },

    /**
     * 資材発注登録画面：入力カンマ処理 調達期間
     */
    changeLeadTime(val, index) {
      var leadTime = commonUtil.zen2han(val.leadTime).replaceAll(",", "");
      leadTime = leadTime.replace(/[^0-9]/gi, "");
      if (leadTime == "-0") {
        leadTime = "0";
      }
      if (leadTime.length > 9) {
        leadTime = leadTime.substring(0, 9);
      }
      if (!isNaN(Number(leadTime))) {
        this.inputList[index].requiredItemCheckMsg = i18n.tc("");
        this.inputList[index].leadTime = commonUtil.formatToCurrency(Number(leadTime));
      }
    },

    /** 間隔変更 */
    changeInterval() {
      // 間隔が日の場合
      if (this.interval == "01") {
        this.getDateInterval(this.searchParam.referenceDate);
        // 間隔が週の場合
      } else if (this.interval == "02") {
        this.getWeekInterval(this.searchParam.referenceDate);
        // 間隔が月の場合
      } else if (this.interval == "03") {
        this.getMonthInterval(this.searchParam.referenceDate);
      } else {
        // それ以外
        this.getDateInterval(this.searchParam.referenceDate);
      }
    },

    /** 週ごとの間隔取得 */
    getWeekInterval(val) {
      // 基準日の月
      var month = Number(val.substr(5, 2));
      // 基準日の日
      var date = Number(val.substr(8, 9));
      // 基準日の年
      var year = Number(val.substr(0, 4));
      // 大月リスト
      var yearList = [1, 3, 5, 7, 8, 10, 12];
      if (month == 2) {
        // 閏年の判定
        if (year % 4 == 0) {
          // 閏年の日にち間隔取得
          this.getLeapYearWeakInterval(month, date);
        } else {
          //それ以外
          this.getFebWeakInterval(month, date);
        }
      } else if (yearList.includes(month)) {
        // 大月の日にち間隔取得
        this.getOtsukiWeakInterval(month, date, year, yearList);
      } else {
        // 小月の日にち間隔取得
        this.getShotsukiWeakInterval(month, date);
      }
    },

    /** 閏年の日にち間隔取得 */
    getLeapYearWeakInterval(month, date) {
      // 基準日の月日を間隔リストに詰める
      var monthDateIntervalList = [month + "/" + date + "～"];
      for (let i = 1; i < 6; i++) {
        // 基準日の日に7ずつ加算していく
        var changeDate = date + i * 7;
        // 加算した値が60より大きい場合
        if (changeDate > 60) {
          var overDate = changeDate - 60;
          var nextMonth = month + 2;
          // 基準日から翌々月の月日を設定
          monthDateIntervalList.push(nextMonth + "/" + overDate + "～");
          // 加算した日が29より大きい場合
        } else if (changeDate > 29) {
          overDate = changeDate - 29;
          nextMonth = month + 1;
          // 基準日の次月の月日をリストに詰める
          monthDateIntervalList.push(nextMonth + "/" + overDate + "～");
        } else {
          // 基準日の月と7ずつ加算した日をリストに詰める
          monthDateIntervalList.push(month + "/" + changeDate + "～");
        }
      }
      this.changeIntervalHeader(monthDateIntervalList);
    },

    /** 2月の日にち間隔取得 */
    getFebWeakInterval(month, date) {
      // 基準日の月日を間隔リストに詰める
      var monthDateIntervalList = [month + "/" + date + "～"];
      for (let i = 1; i < 6; i++) {
        // 基準日の日に7ずつ加算していく
        var changeDate = date + i * 7;
        // 加算した値が59より大きい場合
        if (changeDate > 59) {
          var overDate = changeDate - 59;
          var nextMonth = month + 2;
          // 基準日から翌々月の月日をリストに詰める
          monthDateIntervalList.push(nextMonth + "/" + overDate + "～");
          // 加算した値が28より大きい場合
        } else if (changeDate > 28) {
          overDate = changeDate - 28;
          nextMonth = month + 1;
          // 基準日の次月の月日をリストに詰める
          monthDateIntervalList.push(nextMonth + "/" + overDate + "～");
        } else {
          // 基準日の月と7ずつ加算した日をリストに詰める
          monthDateIntervalList.push(month + "/" + changeDate + "～");
        }
      }
      // ヘッダー情報変更
      this.changeIntervalHeader(monthDateIntervalList);
    },

    /** 大月の日にち間隔取得 */
    getOtsukiWeakInterval(month, date, year, val) {
      // 基準日の月日を間隔リストに詰める
      var monthDateIntervalList = [month + "/" + date + "～"];
      for (let i = 1; i < 6; i++) {
        // 基準日の日に7ずつ加算
        var changeDate = date + i * 7;
        // 加算した値が60より大きい場合
        if (changeDate > 60) {
          // 基準日の月に1加算した値が12より大きい場合
          if (month + 1 > 12) {
            var nextMonth = month + 1 - 12;
          } else {
            nextMonth = month + 1;
          }
          // 基準日の月の翌々月が12より大きい場合
          if (nextMonth + 1 > 12) {
            var overNextMonth = nextMonth + 1 - 12;
          } else {
            overNextMonth = nextMonth + 1;
          }

          // 基準日が大月の場合、翌月に2月を挟む場合があるため、閏年の判定
          if (nextMonth == 2) {
            // 閏年の場合
            if (year % 4 == 0) {
              overDate = changeDate - 60;
            } else {
              // それ以外
              overDate = changeDate - 59;
            }
            // 基準日の翌月が大月の場合
          } else if (val.includes(nextMonth)) {
            overDate = changeDate - 62;
          } else {
            // それ以外
            overDate = changeDate - 61;
          }
          // 翌々月の月日をリストに詰める
          monthDateIntervalList.push(overNextMonth + "/" + overDate + "～");
          // 加算した値が31より大きい場合
        } else if (changeDate > 31) {
          var overDate = changeDate - 31;
          // 基準日の月に1加算した値が12より大きい場合
          if (month + 1 > 12) {
            nextMonth = month + 1 - 12;
          } else {
            nextMonth = month + 1;
          }
          // それぞれ計算した月日をリストに詰める
          monthDateIntervalList.push(nextMonth + "/" + overDate + "～");
        } else {
          // 基準日の月と7ずつ加算した日をリストに詰める
          monthDateIntervalList.push(month + "/" + changeDate + "～");
        }
      }
      // ヘッダー情報変更
      this.changeIntervalHeader(monthDateIntervalList);
    },

    /** 小月の日にち間隔取得 */
    getShotsukiWeakInterval(month, date) {
      // 基準日の月日を間隔リストに詰める
      var monthDateIntervalList = [month + "/" + date + "～"];
      for (let i = 1; i < 6; i++) {
        // 基準日の日に7ずつ加算していく
        var changeDate = date + i * 7;
        // 加算した値が62より大きい場合
        if (changeDate > 62) {
          // 基準日の月に1加算した値が12より大きい場合
          if (month + 1 > 12) {
            var nextMonth = month + 1 - 12;
          } else {
            // それ以外
            nextMonth = month + 1;
          }
          // 基準日の月の翌々月が12より大きい場合
          if (nextMonth + 1 > 12) {
            var overNextMonth = nextMonth + 1 - 12;
          } else {
            overNextMonth = nextMonth + 1;
          }
          // 加算した日から61を引く
          var overDate = changeDate - 61;
          // 基準日の月日から翌々月の月日をリストに詰める
          monthDateIntervalList.push(overNextMonth + "/" + overDate + "～");
          // 加算した値が30より大きい場合
        } else if (changeDate > 30) {
          overDate = changeDate - 30;
          // 基準日の月の翌月が12より大きい場合
          if (month + 1 > 12) {
            nextMonth = month + 1 - 12;
          } else {
            // それ以外は翌月を設定
            nextMonth = month + 1;
          }
          // 基準日の翌月の月日をリストに詰める
          monthDateIntervalList.push(nextMonth + "/" + overDate + "～");
        } else {
          // 基準日の月と7ずつ加算した日をリストに詰める
          monthDateIntervalList.push(month + "/" + changeDate + "～");
        }
      }
      // ヘッダー情報変更
      this.changeIntervalHeader(monthDateIntervalList);
    },
    /** 日ごとの間隔取得 */
    getDateInterval(val) {
      // 基準日の月
      var month = Number(val.substr(5, 2));
      // 基準日の日
      var date = Number(val.substr(8, 9));
      // 基準日の年
      var year = Number(val.substr(0, 4));
      // 大月リスト
      var yearList = [1, 3, 5, 7, 8, 10, 12];
      if (month == 2) {
        // 閏年の判定
        if (year % 4 == 0) {
          // 閏年の日にち間隔取得
          this.getLeapDateInterval(month, date);
        } else {
          //それ以外
          this.getFebDateInterval(month, date);
        }
      } else if (yearList.includes(month)) {
        // 大月の日にち間隔取得
        this.getOtsukiDateInterval(month, date);
      } else {
        // 小月の日にち間隔取得
        this.getShotsukiDateInterval(month, date);
      }
    },

    /** 閏年の日にち間隔取得 */
    getLeapDateInterval(month, date) {
      // 基準日の月日を間隔リストに詰める
      var monthDateIntervalList = [month + "/" + date];
      for (let i = 1; i < 6; i++) {
        // 基準日の日に1ずつ加算していく
        var changeDate = date + i;
        // 加算した値が29より大きい売位
        if (changeDate > 29) {
          // 加算した値から29を引く
          var overDate = changeDate - 29;
          // 基準日の月に1加算した値を設定
          var nextMonth = month + 1;
          // それぞれ計算した値をリストに詰める
          monthDateIntervalList.push(nextMonth + "/" + overDate);
        } else {
          // 基準日の月と加算した日をリストに詰める
          monthDateIntervalList.push(month + "/" + changeDate);
        }
      }
      // ヘッダー情報変更
      this.changeIntervalHeader(monthDateIntervalList);
    },

    /** 2月の日にち間隔取得 */
    getFebDateInterval(month, date) {
      // 基準日の月日を間隔リストに詰める
      var monthDateIntervalList = [month + "/" + date];
      for (let i = 1; i < 6; i++) {
        // 基準日の日に1ずつ加算していく
        var changeDate = date + i;
        // 加算した値が28より大きい場合
        if (changeDate > 28) {
          // 加算した値から28を引く
          var overDate = changeDate - 28;
          // 基準日の月に1加算
          var nextMonth = month + 1;
          // それぞれ計算した値をリストに詰める
          monthDateIntervalList.push(nextMonth + "/" + overDate);
        } else {
          // 基準日の月と日に1を加算した値をリストに詰める
          monthDateIntervalList.push(month + "/" + changeDate);
        }
      }
      // ヘッダー情報変更
      this.changeIntervalHeader(monthDateIntervalList);
    },

    /** 大月の日にち間隔取得 */
    getOtsukiDateInterval(month, date) {
      // 基準日の月日を間隔リストに詰める
      var monthDateIntervalList = [month + "/" + date];
      for (let i = 1; i < 6; i++) {
        // 基準日の日に1ずつ足していく
        var changeDate = date + i;
        // 加算された日が31より大きい場合
        if (changeDate > 31) {
          // 加算された日から31を引く
          var overDate = changeDate - 31;
          // 基準日の月に1足した値が12より大きい場合
          if (month + 1 > 12) {
            // 加算された月から12を引く
            var nextMonth = month + 1 - 12;
          } else {
            // それ以外の場合、加算された月を設定
            nextMonth = month + 1;
          }
          monthDateIntervalList.push(nextMonth + "/" + overDate);
        } else {
          // 加算された日が31以下の場合、基準日の月と加算された日をリストに詰める
          monthDateIntervalList.push(month + "/" + changeDate);
        }
      }
      // 明細ヘッダー変更
      this.changeIntervalHeader(monthDateIntervalList);
    },

    /** 小月の日にち間隔取得 */
    getShotsukiDateInterval(month, date) {
      // 基準日の月日を間隔リストに詰める
      var monthDateIntervalList = [month + "/" + date];
      for (let i = 1; i < 6; i++) {
        // 基準日の日に1ずつ加算
        var changeDate = date + i;
        // 加算した値が30より大きい場合
        if (changeDate > 30) {
          // 加算した値から30を引く
          var overDate = changeDate - 30;
          // 基準日の月に1加算した値が12より大きい場合
          if (month + 1 > 12) {
            // 1加算した月から12を引く
            var nextMonth = month + 1 - 12;
          } else {
            // それ以外の場合、基準日の月に1加算した値を設定
            nextMonth = month + 1;
          }
          // それぞれ計算した月と日をリストに詰める
          monthDateIntervalList.push(nextMonth + "/" + overDate);
        } else {
          // 加算した値が30以下の場合、基準日の月と基準日の日に1を加算した値をリストに詰める
          monthDateIntervalList.push(month + "/" + changeDate);
        }
      }
      this.changeIntervalHeader(monthDateIntervalList);
    },

    /** 月ごとの間隔取得 */
    getMonthInterval(val) {
      // 基準日の月
      var month = Number(val.substr(5, 2));
      // 基準日の日
      // var date = this.searchParam.referenceDate.substr(8, 9);
      // 基準日の月を月の間隔リストに詰める
      var monthIntervalList = [month + "月"];
      // 基準日の月以降の月を取得
      for (let i = 1; i < 6; i++) {
        var changeMonth = month + i;
        // 加算した値が12より大きい場合
        if (changeMonth > 12) {
          // 12を引いた値を月の間隔リストに詰める。
          var overMonth = changeMonth - 12;
          monthIntervalList.push(overMonth + "月");
        } else {
          monthIntervalList.push(changeMonth + "月");
        }
      }
      this.changeIntervalHeader(monthIntervalList);
    },

    /** ヘッダー情報変更 */
    changeIntervalHeader(val) {
      this.headerItems.splice(
        3,
        6,
        {
          text: val[0],
          value: "intervalFirst",
          width: "100px",
          align: "right",
          sortable: false,
        },
        {
          text: val[1],
          value: "intervalSecond",
          width: "100px",
          align: "right",
          sortable: false,
        },
        {
          text: val[2],
          value: "intervalThird",
          width: "100px",
          align: "right",
          sortable: false,
        },
        {
          text: val[3],
          value: "intervalFourth",
          width: "100px",
          align: "right",
          sortable: false,
        },
        {
          text: val[4],
          value: "intervalFifth",
          width: "100px",
          align: "right",
          sortable: false,
        },
        {
          text: val[5],
          value: "intervalSixth",
          width: "100px",
          align: "right",
          sortable: false,
        }
      );
    },

    //クリアボタン押下時
    clearConfirm() {
      // 確認ダイアログを表示
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.okAction = this.clear;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
    },

    /**
     * ページング処理
     */
    showList() {
      return this.inputList;
    },

    // クリア処理
    clear() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 検索条件が存在した場合
      if (this.searchParam) {
        this.searchParam.referenceDate = "";
        this.searchParam.referenceDateCal = "";
        this.searchParam.materialCd = "";
        this.searchParam.suppliersSelected = "";
        this.interval = "01";
        this.orderPointDiv = "";
      }
      // 基準日に当日日付をセット
      this.searchParam.referenceDate = dateTimeHelper.toStringNowYYYY_MM_DD1();
      this.searchParam.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      // 一覧表示リストの初期化
      this.inputList.splice(0);
      // 件数の初期化
      this.totalCount = 0;
      // 明細ヘッダーの日付間隔取得
      // this.getDateInterval(this.searchParam.referenceDate);
      // 商品リストの初期化
      this.itemCdInputText = "";
      this.productList = [];
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    // 登録処理
    regist() {
      // TODO 登録処理
      //エラー内容初期化処理--------------------------------------------------
      this.loadingCounter = 1;
      this.loadingCounter = 0;
      //エラー内容初期化処理--------------------------------------------------
      let cnt = 0;
      let countCheckFlg = true;
      // 更新対象リスト初期化
      this.updateTargetList = [];
      // 伝票部、入力チェック実施
      if (this.$refs.form.validate() && this.$refs.editedList.validate()) {
        // 明細一覧の件数が0件の場合
        if (this.inputList.length == 0) {
          return;
        } else {
          // 処理なし
        }
        // 明細部、入力チェック実施
        for (var i = 0; i < this.inputList.length; i++) {
          var checkOrderingPointNum = Number(
            commonUtil.zen2han(this.inputList[i].orderingPoint).replaceAll(",", "")
          );
          var checkSaftyStockNum = Number(
            commonUtil.zen2han(this.inputList[i].saftyStockQuantity).replaceAll(",", "")
          );
          var checkLeadTimeNum = Number(
            commonUtil.zen2han(this.inputList[i].leadTime).replaceAll(",", "")
          );

          // 発注点、安全在庫、調達期間が0の場合
          if (checkOrderingPointNum == 0 && checkSaftyStockNum == 0 && checkLeadTimeNum == 0) {
            var allMessage =
              i18n.tc("label.lbl_orderingPoint") +
              "、" +
              i18n.tc("label.lbl_saftyStockQuantity") +
              "、" +
              i18n.tc("label.lbl_alertLeadTime");
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-DST-002_002_E", [allMessage]);
            cnt = i + 1;
            countCheckFlg = false;
            return;
          } else {
            // 処理なし
          }

          // 発注点と安全在庫が0の場合
          if (checkOrderingPointNum == 0 && checkSaftyStockNum == 0) {
            var orderingPointAndSaftyStockMessage =
              i18n.tc("label.lbl_orderingPoint") + "、" + i18n.tc("label.lbl_saftyStockQuantity");
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-DST-002_002_E", [
              orderingPointAndSaftyStockMessage,
            ]);
            cnt = i + 1;
            countCheckFlg = false;
            return;
          } else {
            // 処理なし
          }

          // 発注点と調達期間が0の場合
          if (checkOrderingPointNum == 0 && checkLeadTimeNum == 0) {
            var orderingPointAndLeadTimeMessage =
              i18n.tc("label.lbl_orderingPoint") + "、" + i18n.tc("label.lbl_alertLeadTime");
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-DST-002_002_E", [
              orderingPointAndLeadTimeMessage,
            ]);
            cnt = i + 1;
            countCheckFlg = false;
            return;
          } else {
            // 処理なし
          }

          // 安全在庫と調達期間が0の場合
          if (checkSaftyStockNum == 0 && checkLeadTimeNum == 0) {
            var saftyStockLeadTimeMessage =
              i18n.tc("label.lbl_saftyStockQuantity") + "、" + i18n.tc("label.lbl_alertLeadTime");
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-DST-002_002_E", [
              saftyStockLeadTimeMessage,
            ]);
            cnt = i + 1;
            countCheckFlg = false;
            return;
          } else {
            // 処理なし
          }
          if (checkOrderingPointNum == 0) {
            // 発注点が0の場合
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-DST-002_002_E", [
              i18n.tc("label.lbl_orderingPoint"),
            ]);
            cnt = i + 1;
            countCheckFlg = false;
          } else {
            // 処理なし
          }

          if (checkSaftyStockNum == 0) {
            // 安全在庫数が0の場合
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-DST-002_002_E", [
              i18n.tc("label.lbl_saftyStockQuantity"),
            ]);
            cnt = i + 1;
            countCheckFlg = false;
          } else {
            // 処理なし
          }

          if (checkLeadTimeNum == 0) {
            // 調達期間が0の場合
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-DST-002_002_E", [
              i18n.tc("label.lbl_alertLeadTime"),
            ]);
            cnt = i + 1;
            countCheckFlg = false;
          } else {
            // 処理なし
          }

          // 数値チェック
          if (
            isNaN(checkOrderingPointNum) ||
            isNaN(checkSaftyStockNum) ||
            isNaN(checkLeadTimeNum)
          ) {
            // 発注点、安全在庫数、調達期間が数値でない時
            cnt = i + 1;
          } else {
            // 処理なし
          }

          // 整数チェック
          if (
            Number.isInteger(checkOrderingPointNum) &&
            Number.isInteger(checkSaftyStockNum) &&
            Number.isInteger(checkLeadTimeNum)
          ) {
            // 処理なし
          } else {
            // 発注点、安全在庫数、調達期間が整数でない時
            cnt = i + 1;
          }

          // 範囲チェック（0未満）
          if (0 < checkOrderingPointNum && 0 < checkSaftyStockNum && 0 < checkLeadTimeNum) {
            // 処理なし
          } else {
            // 発注点、安全在庫数、調達期間が0未満の場合
            cnt = i + 1;
          }
        }

        // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
        if (cnt == 0 && countCheckFlg) {
          // 更新対象のデータを絞り込む
          for (let i = 0; i < this.searchResultList.length; i++) {
            // 検索時とのデータと突き合わせ、変更があったものだけ更新対象リストに詰める
            this.inputList.forEach((row) => {
              if (
                row.itemStandardSid == this.searchResultList[i].resultItemStandardSid &&
                (row.orderingPoint != this.searchResultList[i].resultOrderPoint ||
                  row.saftyStockQuantity != this.searchResultList[i].resultSaftyStock ||
                  row.leadTime != this.searchResultList[i].resultLeadTime)
              ) {
                this.updateTargetList.push(row);
              }
            });
          }
          // 変更点がない場合
          if (this.updateTargetList.length == 0) {
            // 変更点のないダイアログ表示
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RCV-006_001_E");
          } else {
            // 登録処理の確認ダイアログ表示
            this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
            this.ConfirmDialog.isOpen = true;
            this.ConfirmDialog.okAction = this.orderingPointAdd;
            this.ConfirmDialog.screenFlag = true;
            this.ConfirmDialog.changeFlag = false;
          }
        } else {
          // 明細部、入力エラー時（カウント1）
          if (this.$refs.editedList.validate()) {
            // 処理なし
          } else {
            for (var j = 0; j < this.inputList.length; j++) {
              let checkPosition = String(j);

              // 関係ないレコードの入力チェックエラーを解除
              eval("this.$refs.orderingPointCheck" + checkPosition + ".resetValidation()");
              eval("this.$refs.leadTimeCheck" + checkPosition + ".resetValidation()");
            }
          }
        }
      } else {
        // 伝票部、入力チェックエラーの場合、処理なし
      }
    },

    /**
     * 登録処理
     */
    orderingPointAdd() {
      // バリデーションチェック
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();
        // 共通IF項目 画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TMP_912;

        // 資材発注明細List
        let bodyList = [];

        // 更新対象リストを件数分回す
        for (let i = 0; i < this.updateTargetList.length; i++) {
          var inputMap = {};
          // 商品標準SID
          inputMap.itemStandardSid = this.updateTargetList[i].itemStandardSid;

          // 発注点
          inputMap.orderPoint = this.updateTargetList[i].orderingPoint.replaceAll(",", "");

          // 安全在庫数
          inputMap.safetyStockQuantity = this.updateTargetList[i].saftyStockQuantity.replaceAll(
            ",",
            ""
          );
          // 調達期間
          inputMap.leadTime = this.updateTargetList[i].leadTime.replaceAll(",", "");
          bodyList.push(inputMap);
        }
        // 発注点リスト
        body.reqIdv.orderPointList = bodyList;
        // console.debug("orderingPointAdd() Body", body);

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(
              appConfig.API_URL.DST_MATERIALS_ORDER_POINT_UPDATE,
              body,
              appConfig.APP_CONFIG
            )
            .then((response) => {
              // console.debug("orderingPointAdd() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                // 画面遷移ボタン
                this.infoDialog.homePageFlag = true;
                this.infoDialog.dstInventoryConfirmFlg = true;
                this.infoDialog.dstMaterialsOrderPointFlg = true;
                this.infoDialog.firstPageFlag = false;

                // エラー時
              } else {
                reject(new Error(jsonData.resCom.resComMessage));
              }
            })
            .catch((ex) => {
              reject(ex);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        }).catch((ex) => {
          this.infoDialog.message = ex.message;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
          this.infoDialog.homePageFlag = false;
          this.infoDialog.dstInventoryConfirmFlg = false;
          this.infoDialog.dstMaterialsOrderPointFlg = false;
          this.infoDialog.outsideClickNotCloseFlg = false;
        });
      }
    },

    /** 取引先API接続 */
    getSupplier() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getSupplier() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  compSid: row.compSid,
                });
              });
              this.supplierList = list;
              // 取引先が1件の場合、取引先を自動選択する
              this.supplierList.length == 1
                ? (this.searchParam.suppliersSelected = this.supplierList[0].value)
                : "";
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getSupplier() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /** 表示用データ取得 */
    getList() {
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      // 検索結果保持リスト初期化
      this.searchResultList = [];
      /**
       * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
       * エラーがでるので回避するためこのようなコードとなっている。
       */

      this.alertSupplierMessage = "";
      // 取引先存在チェック
      if (this.isEmpty(this.searchParam.suppliersSelected)) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        return;
      }
      if (this.$route.params.backPage || this.initFlg) {
        // 他画面からの遷移の場合もしくは初期表示時、入力チェックは実施しない。
        inputCheckResult = true;
        this.initFlg = false;
      } else {
        // 上記外の場合はチェックを行う。
        inputCheckResult = this.$refs.form.validate();
      }

      if (inputCheckResult) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        // httpClientで使用する共通IFパラメータ等を作成する。
        const config = this.$httpClient.createGetApiRequestConfig();

        // 基準日
        config.params.referenceDate = this.searchParam.referenceDate.substr(0, 10);

        // 間隔
        config.params.interval = this.interval;

        // 取引先
        config.params.clientSid = this.searchParam.suppliersSelected;

        // 資材品番
        if (this.searchParam.materialCd) {
          config.params.materialitemCd = this.searchParam.materialCd;
        }

        // 登録済フラグ
        if (this.orderPointDiv != "") {
          config.params.registeredFlag = this.orderPointDiv;
        }

        // console.debug("getList() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.DST_MATERIALS_ORDER_POINT_DISP, config)
            .then((response) => {
              this.inputList.splice(0);
              // console.debug("getList() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                const list = [];
                // レスポンスで取得した明細行データをリストに詰める
                jsonData.resIdv.orderPointList.forEach((row) => {
                  list.push({
                    // 商品標準SID
                    itemStandardSid: row.itemStandardSid,
                    // 品番
                    productCnCd: row.itemCd,
                    // 品名
                    productName: row.itemName,
                    // 発注点
                    orderingPoint:
                      Number(row.orderPoint) == 0
                        ? 0
                        : commonUtil.formatToCurrency(Number(row.orderPoint)),
                    // 日付データ1
                    dateData1: row.dateData1,
                    // 間隔1(数量1)
                    intervalFirst:
                      Number(row.quantity1) == 0
                        ? row.quantity1
                        : commonUtil.formatToCurrency(Number(row.quantity1)),
                    // 日付データ2
                    dateData2: row.dateData2,
                    // 間隔2(数量2)
                    intervalSecond:
                      Number(row.quantity2) == 0
                        ? row.quantity2
                        : commonUtil.formatToCurrency(Number(row.quantity2)),
                    // 日付データ3
                    dateData3: row.dateData3,
                    // 間隔3(数量3)
                    intervalThird:
                      Number(row.quantity3) == 0
                        ? row.quantity3
                        : commonUtil.formatToCurrency(Number(row.quantity3)),
                    // 日付データ4
                    dateData4: row.dateData4,
                    // 間隔4(数量4)
                    intervalFourth:
                      Number(row.quantity4) == 0
                        ? row.quantity4
                        : commonUtil.formatToCurrency(Number(row.quantity4)),
                    // 日付データ5
                    dateData5: row.dateData5,
                    // 間隔5(数量5)
                    intervalFifth:
                      Number(row.quantity5) == 0
                        ? row.quantity5
                        : commonUtil.formatToCurrency(Number(row.quantity5)),
                    // 日付データ6
                    dateData6: row.dateData6,
                    // 間隔6(数量6)
                    intervalSixth:
                      Number(row.quantity6) == 0
                        ? row.quantity6
                        : commonUtil.formatToCurrency(Number(row.quantity6)),
                    // 平均出庫数
                    averageIssue: 0,
                    // 安全在庫数
                    saftyStockQuantity:
                      Number(row.safetyStockQuantity) == 0
                        ? row.safetyStockQuantity
                        : commonUtil.formatToCurrency(Number(row.safetyStockQuantity)),
                    // 安全係数
                    safetyFactor: row.safetyFactor,
                    // 標準偏差
                    standardDviation: 0,
                    // 調達期間
                    leadTime: Number(row.leadTime) == 0 ? 0 : row.leadTime.replaceAll(".00", ""),
                    // 登録済フラグ
                    registeredFlag: row.registeredFlag,
                  });
                });

                // 平均在庫数と標準偏差の計算処理
                for (let i = 0; i < list.length; i++) {
                  // 平均在庫数と標準偏差の計算処理
                  this.getAveIssueAndStandardDeviation(list[i]);
                  // 発注点が0かつ調達期間が0より大きい場合
                  if (list[i].orderingPoint == 0 && Number(list[i].leadTime) > 0) {
                    // 安全在庫数と発注点の計算処理
                    this.getSaftyStockAndOrderingPoint(list[i]);
                  }

                  // 更新対象を絞るために、検索結果のデータを保持
                  this.searchResultList.push({
                    resultItemStandardSid: list[i].itemStandardSid,
                    resultOrderPoint: list[i].orderingPoint,
                    resultSaftyStock: list[i].saftyStockQuantity,
                    resultLeadTime: list[i].leadTime,
                  });
                }
                // テーブルデータリストに明細行データを設定
                this.inputList = list;

                // 明細データのバリデーションチェックリセット
                this.$refs.editedList.resetValidation();
                // 総件数
                this.totalCount = this.inputList.length;
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    // 項目が空かチェック
    isEmpty(value) {
      if (value === undefined || value === null || value === "") {
        return true;
      }
      return false;
    },
  },

  watch: {
    "searchParam.referenceDateCal": function (val) {
      this.searchParam.referenceDate = this.formatDate(val);
      // 明細ヘッダーの間隔変更
      this.changeInterval();
    },
    "searchParam.suppliersSelected"(oldValue) {
      if (oldValue != null && oldValue != "") {
        //処理なし
      }
      if (this.searchParam.suppliersSelected) {
        this.alertSupplierMessage = "";
      }
    },

    /**
     * 品番入力
     */
    itemCdInputText(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (
          this.searchParam.suppliersSelected == null ||
          this.searchParam.suppliersSelected == ""
        ) {
          return;
        }
        // 表示内容が選択した内容と一致している場合は、確定した内容なので変更しない
        let product = this.productList.find((v) => v.code == this.searchParam.materialCd);
        if (product != null && val == product.text) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.searchParam.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.productList = list.concat(result[0]);
              this.productList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.productList.length = 0;
        }
      }
    },
  },
  computed: {
    // 取引先名
    getSupplierName() {
      let supplier = this.supplierList.find((v) => v.value === this.searchParam.suppliersSelected);
      if (supplier) {
        return supplier.name;
      }
      return "";
    },
    // 間隔ヒント取得
    getInterval() {
      let intervalHint = this.intervalList.find((v) => this.interval === v.value);
      if (intervalHint) {
        return intervalHint.text;
      } else {
        return "";
      }
    },

    // 発注点ヒント取得
    getOrderPointDiv() {
      let orderPointDivHint = this.orderPointDivList.find((v) => v.value === this.orderPointDiv);
      if (orderPointDivHint) {
        return orderPointDivHint.text;
      } else {
        return "";
      }
    },
    // 品番
    productName() {
      if (this.searchParam.suppliersSelected == null || this.searchParam.suppliersSelected == "") {
        return "取引先が選択されていません";
      }

      let prodct = this.productList.find((v) => v.code === this.searchParam.materialCd);
      if (prodct) {
        return prodct.name;
      }
      return "";
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}

.table-data-align-right {
  text-align: right;
}

.table-style {
  overflow-x: auto;
}

.proc-width {
  width: 100px;
}

.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}
.diff {
  background-color: #ffc0cb;
  // background-color:#d09cfc; 出荷保障の色
}
.diff2 {
  background-color: #d09cfc;
  // background-color:#d09cfc; 出荷保障の色
}
.marginRightNone {
  margin-right: 0px;
}
</style>
